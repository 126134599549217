import React from 'react';
import { Title, Text, Button } from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';
import { useTranslation } from 'react-i18next';
import { isUserAllowedOnPL } from 'shared/hooks/use-is-user-allowed-pl';
import Loading from 'shared/components/loading';
import styles from './guard.page.module.css';

export const GuardPage = () => {
  const { t } = useTranslation('translations');
  const { refetch, isLoading } = isUserAllowedOnPL();

  if (isLoading) {
    return <div className={styles.content}>
      <Loading />
    </div>
  }

  return (
    <div className={styles.content}>
      <div>
        <Title>{t("guardPage_title")}</Title>
        <Text>{t("guardPage_description")}</Text>
      </div>
      <Button variant="secondary" onClick={() => refetch()}>
        {t("guardPage_button")}
      </Button>
    </div>
  );
}
