import { MODULE_STATES } from 'shared/utilities/module-states';
import { WELCOME_MESSAGES } from 'shared/utilities/welcome-message-states';
import { CategoryProps, GameProgressProps, ModuleProps } from '../../../../types';
import getModuleKey from '../get-module-key';
import getStatusOfModule from '../get-status-of-module';

const sortModulesByFinishByDate = (modules: ModuleProps[]) => {
  return modules.sort((moduleA, moduleB) => {
    if (!moduleA.finished_by) return 1;
    if (!moduleB.finished_by) return 0;
    return moduleA.finished_by < moduleB.finished_by ? 0 : 1;
  });
};

const isModuleUnlocked = (currentDate: number, moduleUnlockDate: number) => {
  return currentDate >= moduleUnlockDate;
};

const isModuleOverdue = (currentDate: number, finishByDate: number) => {
  return currentDate > finishByDate;
};

const areModuleLocked = (categories: CategoryProps[]) => {
  const currentDate = Date.now();
  return categories.some((category) => {
    return (category.modules.some((module) => {
      const unlockDate = new Date(module.unlocked).getTime();
      return !isModuleUnlocked(currentDate, unlockDate);
    }));
  });
};

const findOverdueModule = (categories: CategoryProps[]) => {
  const currentDate = Date.now();

  return categories.reduce((acc, { modules, category_name }) => {
    if (!modules) return acc;

    const sortedModulesByFinishDate = sortModulesByFinishByDate(modules);

    const overDueModule = sortedModulesByFinishDate.find((module) => {
      const moduleStatus = getStatusOfModule(module);
      if (!moduleStatus.includes(MODULE_STATES.PASSED) && module.finished_by && module.unlocked) {
        const moduleUnlockDate = new Date(module.unlocked).getTime();
        if (isModuleUnlocked(currentDate, moduleUnlockDate)) {
          const moduleByFinishByDate = new Date(module.finished_by).getTime();
          if (isModuleOverdue(currentDate, moduleByFinishByDate)) {
            return true;
          }
        }
      }
      return false;
    });

    return !!overDueModule ? { categoryName: category_name, module: overDueModule } : acc;
  }, {
    categoryName: '',
    module: {},
  });
};

interface GetMainMessageProps {
  lastSeen?: string,
  progress: GameProgressProps,
  campaign: { certificate: boolean }
  categories: CategoryProps[]
}

/**
 * Returns main message for welcome card *
 */
export const getMainMessage = ({
  lastSeen, progress, campaign, categories,
}: GetMainMessageProps) => {
  const currentlyAvailableModules = (progress?.total) || 0;
  const totalAvailableModules = (progress?.totalAllUnlocked) || 0;
  const completedModules = (progress?.completed) || 0;
  const overdueModule = categories ? findOverdueModule(categories) : null;

  if (overdueModule?.module && overdueModule?.categoryName) {
    return {
      message: WELCOME_MESSAGES.ADHOC_DUE_DATE,
      addData: {
        catKey: overdueModule.categoryName,
        moduleKey: getModuleKey(overdueModule.module),
        module: overdueModule.module,
      },
    };
  }

  if (!lastSeen) return { message: WELCOME_MESSAGES.FIRST_LOGIN };

  if (currentlyAvailableModules === completedModules && categories && areModuleLocked(categories)) {
    return { message: WELCOME_MESSAGES.ALL_MODULES_LOCK };
  }

  if (campaign.certificate) {
    if (totalAvailableModules === completedModules) return { message: WELCOME_MESSAGES.MAIN_DONE };
  } else if (currentlyAvailableModules === completedModules) {
    return { message: WELCOME_MESSAGES.MAIN_DONE };
  }

  if (currentlyAvailableModules - completedModules < 4) return { message: WELCOME_MESSAGES.MAIN_THREE_MORE };

  if (completedModules >= currentlyAvailableModules / 2) return { message: WELCOME_MESSAGES.MAIN_MORE_HALF };

  if (completedModules > 2 && completedModules < currentlyAvailableModules / 2) return { message: WELCOME_MESSAGES.MAIN_LESS_HALF };

  if (completedModules < 3 && completedModules > 0) return { message: WELCOME_MESSAGES.MAIN_MAX_TWO_MOD };

  return { message: 'default' };
};
