import i18n from "lang/i18n";
import { useDispatch } from "react-redux";
import ErrorCollapsible from "shared/components/error-collapsible";
import { UseGlobalSettingsContext } from "shared/modules/global-settings/state/global-settings-context.provider";
import {
  LoginProps,
  queryClient,
  queryKey,
  useSoSafeConnect,
} from "shared/modules/sosafe-connect";
import ActionTypes from "shared/utilities/action-types";
import {
  getMessageByReference,
} from "shared/utilities/reference-handling";
import { UseReferenceHandler } from "shared/utilities/use-reference-handler";
import { URL_AUTH_API } from "shared/helpers";
import { AxiosResponse } from "axios";
import { Button, Text, Title } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { useModalContext } from "modal-context/modal-context";
import { UseShowDecisionAlert } from "flamingo-e-learning-platform/utilities/modal-alert/use-show-decision-alert";
import { ALERT_TYPE } from 'flamingo-e-learning-platform/utilities/modal-alert/shared';
import { addMethodToResponse } from "./utils";

function getFullUrlFromLocation(response: AxiosResponse) {
  const apiUrlWithoutVersion = (window.elearningRuntimeConfig.API_URL as string)?.slice(0, -4);
  return apiUrlWithoutVersion + response.headers.location;
}

/**
 * If the user tries to login with password but is configured for saml the
 * backend will spit a redirect to the saml login route.
 *
 * We need to redirect the user to the saml login route to avoid any login issues.
 */
function isSamlRedirect(response: AxiosResponse) {
  const url = getFullUrlFromLocation(response);

  return (
    response.status === 201 &&
    !!response.headers.location &&
    url.startsWith(`${URL_AUTH_API()}/saml/login`)
  );
}

export const UseLoginRequest = () => {
  const { axiosInstance, setApiKey, endPoints } = useSoSafeConnect();
  const { setLocalToken } = UseGlobalSettingsContext();
  const dispatch = useDispatch();
  const { close } = useModalContext();
  const { show } = UseShowDecisionAlert();
  const checkReference = UseReferenceHandler();

  const login = async (props: LoginProps) => {
    dispatch({ type: ActionTypes.AUTH_LOGIN_REQUEST });

    axiosInstance.interceptors.response.use(
      (response) => {
        if (response.status === 201 && !!response.headers.location) {
          const url = getFullUrlFromLocation(response);

          if (isSamlRedirect(response)) {
            window.location.href = url;
          } else {
            return axiosInstance.get(url);
          }
        }

        return Promise.resolve(response);
      },
      (error) => Promise.reject(error)
    );

    try {
      const response = await endPoints.post.login(props);

      if (isSamlRedirect(response)) {
        return;
      }

      /** ensure to clear memory from authorization before login, to fetch a fresh data from the start */
      queryClient.invalidateQueries([queryKey.AUTH]);

      /* Business logic: When user is login with sso for the first time, sso parameter is sent instead of apikey
             therefore we only set the key when re-login with sso, Settings the apikey for the first time is handle
             by registration (useRegistrationRequest)
             */
      if (response.data.result.apikey) {
        setApiKey(response.data.result.apikey);
      }

      if (response?.data?.result?.customer?.token) {
        setLocalToken(response.data.result.customer.token);
      }

      // For identifying new user
      if (!response.data.result.last_seen) {
        sessionStorage.setItem("isFirstVisit", "true");
      }

      const responseWithMethod = addMethodToResponse(response, props.method);

      setTimeout(() => {
        sessionStorage.removeItem("MSAL_STATE");
        sessionStorage.removeItem("sessionExpired");
        sessionStorage.removeItem("sessionExpiredSso");
        dispatch({
          payload: responseWithMethod,
          type: ActionTypes.AUTH_LOGIN_SUCCESS,
        });
      }, 300);

    } catch (error: any) {
      setTimeout(() => {
        if (sessionStorage.getItem("MSAL_STATE")) {
          sessionStorage.removeItem("MSAL_STATE");
          const message = error.response?.data
            ? getMessageByReference(error.response.data.reference)
            : null;
          const finalMessage = i18n.t(
            message ?? "messageTranslations:An error has occurred"
          );
          const errorMsg = i18n.t("Error");
          const closeMsg = i18n.t("Close")

          show({
            title: <Title size="h2">{errorMsg}</Title>,
            content: <>
              <Text>{finalMessage}</Text>
              <ErrorCollapsible error={error} response={error?.response} />
            </>,
            footer: <Button variant='primary' onClick={close}>{closeMsg}</Button>,
            type: ALERT_TYPE.ERROR,
          })

          const payload = { ...(error?.response?.data || {}) };
          delete payload.reference;
          dispatch({ payload, type: ActionTypes.AUTH_LOGIN_FAILED });
          return;
        }

        if (error.response) {
          dispatch({
            payload: checkReference(error.response.data, dispatch),
            type: ActionTypes.AUTH_LOGIN_FAILED,
          });
        } else {
          dispatch({
            payload: checkReference(
              {
                title: error.name,
                status: error.statusCode,
                reference: "40700745-2789-4F88-8412-1885DDC724E4",
              },
              dispatch
            ),
            type: ActionTypes.AUTH_LOGIN_FAILED,
          });
        }
      }, 300);
    }
  };

  return {
    login,
  };
};
