export interface ModuleData {
  isCustom: boolean,
  active?: boolean,
  advanced: boolean,
  category_description: string,
  category_description_short: string,
  category_group: number,
  category_id: number,
  category_image: string,
  category_key: string,
  category_master: boolean,
  category_name: string,
  changelog?: any,
  content?: any,
  created: string,
  customer_id?: string | number
  description: string
  duration: number,
  enabled: boolean,
  finished_by?: string,
  group: number,
  header?: string,
  id: number,
  image: string,
  language: string,
  lesson_status?: string,
  license: string,
  login_id?: number,
  mandatory: boolean,
  mandatory_question?: string,
  master: boolean,
  module_id: number,
  module_name: string,
  name: string,
  pages: number,
  playlist?: any,
  point?: number,
  preview: boolean,
  progress: number,
  revision: number,
  score?: number,
  scorm_entry_point: string,
  self_service: boolean,
  slide: number
  sort: number,
  stage: number,
  status?: number,
  statusOfModule: Array<string>,
  suspend_data?: string,
  topic_group?: number,
  topic_id?: number,
  topic_name?: string
  unlocked: string,
  uuid: string,
  version: number,
  video: boolean,
  xp: number,
}

export enum ScormType {
  ACTIVE_PRESENTER = 'ActivePresenter',
  DEFAULT_MODULE = 'DefaultModule',
  CUSTOM_MODULE = 'CustomModule',
}
