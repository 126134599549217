import React from "react";

export default function BackendHint() {
  if (window.elearningRuntimeConfig.DEPLOY_ENVIRONMENT === "development") {
    return (
      <div className="backendBanner devColor">
        <span className="u-textMedium text-center d-block color-white">
          <strong>Development</strong>
        </span>
      </div>
    );
  }
  if (window.elearningRuntimeConfig.DEPLOY_ENVIRONMENT === "staging") {
    return (
      <div className="backendBanner stageColor">
        <span className="u-textMedium text-center d-block color-white">
          <strong>Stage</strong>
        </span>
      </div>
    );
  }
  if (window.elearningRuntimeConfig.DEPLOY_ENVIRONMENT === "local") {
    return (
      <div className="backendBanner prodColor">
        <span className="u-textMedium text-center d-block color-white">
          <strong>Local</strong>
        </span>
      </div>
    );
  }

  return null;
}
