enum ActionTypes {
  'ACCOUNT_UPDATE_FAILED' = 'ACCOUNT_UPDATE_FAILED',
  'ACCOUNT_UPDATE_REQUEST' = 'ACCOUNT_UPDATE_REQUEST',
  'ACCOUNT_UPDATE_SUCCESS' = 'ACCOUNT_UPDATE_SUCCESS',
  'AUTH_ACTIVATE_FAILED' = 'AUTH_ACTIVATE_FAILED',
  'AUTH_ACTIVATE_REQUEST' = 'AUTH_ACTIVATE_REQUEST',
  'AUTH_ACTIVATE_SUCCESS' = 'AUTH_ACTIVATE_SUCCESS',
  'AUTH_EXPIREDPASSWORD_FAILED' = 'AUTH_EXPIREDPASSWORD_FAILED',
  'AUTH_EXPIREDPASSWORD_REQUEST' = 'AUTH_EXPIREDPASSWORD_REQUEST',
  'AUTH_EXPIREDPASSWORD_SUCCESS' = 'AUTH_EXPIREDPASSWORD_SUCCESS',
  'AUTH_ITWASNTME_FAILED' = 'AUTH_ITWASNTME_FAILED',
  'AUTH_ITWASNTME_REQUEST' = 'AUTH_ITWASNTME_REQUEST',
  'AUTH_ITWASNTME_SUCCESS' = 'AUTH_ITWASNTME_SUCCESS',
  'AUTH_LOGIN_FAILED' = 'AUTH_LOGIN_FAILED',
  'AUTH_LOGIN_REQUEST' = 'AUTH_LOGIN_REQUEST',
  'AUTH_LOGIN_SUCCESS' = 'AUTH_LOGIN_SUCCESS',
  'AUTH_LOGOUT_FAILED' = 'AUTH_LOGOUT_FAILED',
  'AUTH_LOGOUT_REQUEST' = 'AUTH_LOGOUT_REQUEST',
  'AUTH_LOGOUT_SUCCESS' = 'AUTH_LOGOUT_SUCCESS',
  'AUTH_REGISTER_DATA_FAILED' = 'AUTH_REGISTER_DATA_FAILED',
  'AUTH_REGISTER_DATA_REQUEST' = 'AUTH_REGISTER_DATA_REQUEST',
  'AUTH_REGISTER_DATA_SUCCESS' = 'AUTH_REGISTER_DATA_SUCCESS',
  'AUTH_REGISTER_FAILED' = 'AUTH_REGISTER_FAILED',
  'AUTH_REGISTER_REQUEST' = 'AUTH_REGISTER_REQUEST',
  'AUTH_REGISTER_SUCCESS' = 'AUTH_REGISTER_SUCCESS',
  'AUTH_ROLE_ACCESS' = 'AUTH_ROLE_ACCESS',
  'AUTH_SEND_ACTIVATE' = 'AUTH_SEND_ACTIVATE',
  'AUTH_SEND_ACTIVATE_FAILED' = 'AUTH_SEND_ACTIVATE_FAILED',
  'AUTH_SEND_ACTIVATE_SUCCESS' = 'AUTH_SEND_ACTIVATE_SUCCESS',
  'AUTH_TEMPORARY_INVALID' = 'AUTH_TEMPORARY_INVALID',
  'AUTH_VALIDATE_FAILED' = 'AUTH_VALIDATE_FAILED',
  'AUTH_VALIDATE_REQUEST' = 'AUTH_VALIDATE_REQUEST',
  'AUTH_VALIDATE_SUCCESS' = 'AUTH_VALIDATE_SUCCESS',
  'AUTH_REMOVE_REG_DATA' = 'AUTH_REMOVE_REG_DATA',
  'AUTH_CUSTOMER_ACCESS' = 'AUTH_CUSTOMER_ACCESS',
  'AUTH_TOKEN_MISSING' = 'AUTH_TOKEN_MISSING',
  'AUTH_ELEARNING_NOT_ACTIVE' = 'AUTH_ELEARNING_NOT_ACTIVE',
  'AUTH_TOKEN_INVALID' = 'AUTH_TOKEN_INVALID',
  'PASSWORD_UPDATE_FAILED' = 'PASSWORD_UPDATE_FAILED',
  'PASSWORD_UPDATE_REQUEST' = 'PASSWORD_UPDATE_REQUEST',
  'PASSWORD_UPDATE_SUCCESS' = 'PASSWORD_UPDATE_SUCCESS',
  'CATEGORIES_FETCH_SUCCESS' = 'CATEGORIES_FETCH_SUCCESS',
  'CATEGORIES_FETCH_REQUEST' = 'CATEGORIES_FETCH_REQUEST',
  'CATEGORIES_FETCH_FAILED' = 'CATEGORIES_FETCH_FAILED',
  'CLEAR_AUTH_REF' = 'CLEAR_AUTH_REF',
  'MODULE_UPDATE_PROGRESS' = 'MODULE_UPDATE_PROGRESS',
  'SEND_MODULE_FEEDBACK_REQUEST' = 'SEND_MODULE_FEEDBACK_REQUEST',
  'SEND_MODULE_FEEDBACK_SUCCESS' = 'SEND_MODULE_FEEDBACK_SUCCESS',
  'SEND_MODULE_FEEDBACK_FAILED' = 'SEND_MODULE_FEEDBACK_FAILED',
  'SEND_WIZARD_ANSWERS_REQUEST' = 'SEND_WIZARD_ANSWERS_REQUEST',
  'SEND_WIZARD_ANSWERS_SUCCESS' = 'SEND_WIZARD_ANSWERS_SUCCESS',
  'SEND_POLICIES_ANSWERS_REQUEST' = 'SEND_POLICIES_ANSWERS_REQUEST',
  'SEND_POLICIES_ANSWERS_SUCCESS' = 'SEND_POLICIES_ANSWERS_SUCCESS',
  'DIDYOUKNOWHINT_REQUEST' = 'DIDYOUKNOWHINT_REQUEST',
  'DIDYOUKNOWHINT_SUCCESS' = 'DIDYOUKNOWHINT_SUCCESS',
  'DIDYOUKNOWHINT_FAILED' = 'DIDYOUKNOWHINT_FAILED',
  'SET_PLAYER_DATA' = 'SET_PLAYER_DATA',
  'DELETE_PLAYER_DATA' = 'DELETE_PLAYER_DATA',
  'LEAVE_PLAYER_PAGE' = 'LEAVE_PLAYER_PAGE',
  'SURVEY_FETCH_REQUEST' = 'SURVEY_FETCH_REQUEST',
  'SURVEY_FETCH_SUCCESS' = 'SURVEY_FETCH_SUCCESS',
  'SURVEY_FETCH_FAILED' = 'SURVEY_FETCH_FAILED',
  'SURVEY_SEND_REQUEST' = 'SURVEY_SEND_REQUEST',
  'SURVEY_SEND_SUCCESS' = 'SURVEY_SEND_SUCCESS',
  'SURVEY_SEND_FAILED' = 'SURVEY_SEND_FAILED',
  'SURVEY_SEND_DISPATCH' = 'SURVEY_SEND_DISPATCH',
  'SHOW_TOUR_DISPATCH' = 'SHOW_TOUR_DISPATCH',
  'OPTIN_SEND_SUCCESS' = 'OPTIN_SEND_SUCCESS',
  'NOTIFICATION_GET_FAILED' = 'NOTIFICATION_GET_FAILED',
  'NOTIFICATION_GET_REQUEST' = 'NOTIFICATION_GET_REQUEST',
  'NOTIFICATION_GET_SUCCESS' = 'NOTIFICATION_GET_SUCCESS',
  'NOTIFICATION_PUT_FAILED' = 'NOTIFICATION_PUT_FAILED',
  'NOTIFICATION_PUT_REQUEST' = 'NOTIFICATION_PUT_REQUEST',
  'NOTIFICATION_PUT_SUCCESS' = 'NOTIFICATION_PUT_SUCCESS',
  'REMOVE_REDIRECT_REGISTER' = 'REMOVE_REDIRECT_REGISTER',
  'SET_SETTING' = 'SET_SETTING',
  'SSO_SET_PROFILE' = 'SSO_SET_PROFILE',
  'SSO_CHANGE_DISABLED' = 'SSO_CHANGE_DISABLED',
  'SSO_SET_METHOD' = 'SSO_SET_METHOD',
  'SSO_SET_INFO' = 'SSO_SET_INFO',
  'GET_BADGES_REQUEST' = 'GET_BADGES_REQUEST',
  'GET_BADGES_SUCCESS' = 'GET_BADGES_SUCCESS',
  'GET_BADGES_FAILED' = 'GET_BADGES_FAILED',
  'GET_ACTIVITIES_REQUEST' = 'GET_ACTIVITIES_REQUEST',
  'GET_ACTIVITIES_SUCCESS' = 'GET_ACTIVITIES_SUCCESS',
  'GET_ACTIVITIES_FAILED' = 'GET_ACTIVITIES_FAILED',
  'SET_BRANDING' = 'SET_BRANDING',
  'SETTINGS_CHANGED' = 'SETTINGS_CHANGED',
  'WELCOME_CARD_DATA_UPDATE' = 'WELCOME_CARD_DATA_UPDATE',
  'REWARD_SUCCESS' = 'REWARD_SUCCESS',
  'TOUR_CHANGED' = 'TOUR_CHANGED',
  'CLEAR_REFERENCE' = 'CLEAR_REFERENCE',
  'OPTIN_CHANGED' = 'OPTIN_CHANGED',
  'AUTH_LOGIN_SAML_SUCCESS' = 'AUTH_LOGIN_SAML_SUCCESS',
  'SURVEY_CHANGED' = 'SURVEY_CHANGED',
  'WELCOME_CHANGED' = 'WELCOME_CHANGED',
  'SET_FIRST_LOGIN' = 'SET_FIRST_LOGIN',
  'SET_PREFERENCES' = 'SET_PREFERENCES',
  'REMOVE_PREFERENCES' = 'REMOVE_PREFERENCES',
  'ACTIVATE_DEBUG' = 'ACTIVATE_DEBUG',
  'DEACTIVATE_DEBUG' = 'DEACTIVATE_DEBUG',
  'CLEAR_STORE' = 'CLEAR_STORE',
  'CLEAN_UP_ACTIVATION_CODE' = 'CLEAN_UP_ACTIVATION_CODE',
}

export default ActionTypes;
