import { CSSProperties } from 'react';

const arrowSize = { x: 18, y: 18 };

export const defaultStyle: CSSProperties = { display: 'flex', position: 'absolute', zIndex: 999 };
export const arrowStyle: CSSProperties = {
  zIndex: 999,
  position: 'absolute',
  width: arrowSize.x,
  height: arrowSize.y,
  borderTop: '0px solid #EE7C31',
  borderLeft: '10px solid transparent',
  borderRight: '10px solid transparent',
  borderBottom: '10px solid white',
  pointerEvents: 'none',
  scale: 2,
  backgroundColor: 'transparent',
  filter: 'drop-shadow(0px -2px 2px rgba(0,0,0,0.1))',
};

type Dimension = { width: number, height: number };

export const defaultDimension = { content: { x: 0, y: 0 }, arrow: { x: 0, y: 0 } };
export const calculateTogglePanelPosition = (childrenDimension: Dimension, contentDimension: Dimension) => {
  const x = -(contentDimension.width / 2) + childrenDimension.width / 2;
  const y = childrenDimension.height + arrowSize.y / 2;
  return {
    content: { x, y },
    arrow: { x: (contentDimension.width / 2 - arrowSize.x / 2), y: -arrowSize.y + 2 },
  };
};
