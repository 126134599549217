import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSoSafeAnalytics } from "shared/hooks/use-sosafe-analytics";
import usePlatformSettings from "shared/hooks/use-platform-settings";

import useUser from "shared/hooks/use-user";
import { useDidYouKnowHintRequest } from "elearning/hooks/use-did-you-know-hint-request";
import {
  ANALYTICS_TYPE,
  queryClient,
  queryKey,
} from "shared/modules/sosafe-connect";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  IconButton,
} from "@sosafe-platform-engineering/fe-lib-ui-react";
import { Card, Title } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import style from "./facts.module.css";

export const FactsCard = ({ facts }) => {
  const { t } = useTranslation(["translations", "flamingo"]);
  const { sendSoSafeAnalytics } = useSoSafeAnalytics();

  const { user } = useUser({});
  const { settings: platformSettings } = usePlatformSettings();
  const { badges } = platformSettings;
  const [factIndex, setFactIndex] = useState(0);

  const userVisited = !badges || !user?.game || !!user?.game?.hint;

  const isLastFact = facts
    ? facts[facts.length - 1] === facts[factIndex]
    : false;

  const uncoverHints = () => {
    queryClient.setQueryData([queryKey.AUTH], ({ data: authResponse }) => {
      const newResponse = {
        ...authResponse,
        result: {
          ...authResponse.result,
          game: {
            ...authResponse.result?.game,
            hint: true,
          },
        },
      };
      return { data: { ...newResponse }, status: 200 };
    });
  };

  const onSuccess = () => queryClient.invalidateQueries([queryKey.AUTH]);

  const Next = () => {
    if (!userVisited) {
      uncoverHints();
      // This analytics logic is actually updates user object with last badges
      sendSoSafeAnalytics({
        type: ANALYTICS_TYPE.HINT_VIEWED,
        onSuccess,
      });
    } else {
      sendSoSafeAnalytics({ type: ANALYTICS_TYPE.HINT_VIEWED });
    }
    setFactIndex(factIndex + 1);
  };

  const Previous = () => {
    sendSoSafeAnalytics({ type: ANALYTICS_TYPE.HINT_VIEWED });
    setFactIndex(factIndex - 1);
  };

  return (
    <Card withBorder radius={"lg"}>
      <div className={style.content}>
        <Title id="factTitle" className={style.title} order={3} size="h5">{t("translations:Did you know?")}</Title>
        <div role="group" aria-labelledby="factTitle">
          <p className={style.factContent} role="status" aria-live="polite">
            {facts[factIndex]?.content || ""}
          </p>
          <div className={style.scroll}>
            <IconButton.Outline
              ariaLabel={t("flamingo:prevFact")}
              icon={ChevronLeftIcon}
              disabled={factIndex === 0}
              onClick={Previous}
            />
            <div className={style.fontCount}>
              {factIndex + 1}/{facts.length}
            </div>
            <IconButton.Outline
              ariaLabel={t("flamingo:nextFact")}
              icon={ChevronRightIcon}
              disabled={isLastFact}
              onClick={Next}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};
