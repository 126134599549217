import React from 'react';
import { useTranslation } from 'react-i18next';

export const FeedbackError = () => {
  const { t } = useTranslation('translations');
  return (
        <small className='text-center text-danger'>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {`${t('An error has occured')} - ${t('Please try again later')}`}
        </small>
  );
};
