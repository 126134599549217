import React from "react";
import {
  Card,
  Grid,
  Group,
  Image,
  Progress,
  Stack,
  Title
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { useTranslation } from "react-i18next";
import { useUserProgress } from "shared/hooks/use-user-progress";
import { CertificateModal } from "shared/components/personalised-learning/certificate-modal";
import { UseWelcomeCardMessage } from "flamingo-e-learning-platform/training/components/welcome-card/welcome-card-messages/use-welcome-card-message";
import FLAGS from "../../../training/components/shared/check-for-flags";
import style from "./level-card.module.css";

interface LevelCardProps {
  onCertificate?: () => void;
}

const LevelCard = ({ onCertificate }: LevelCardProps) => {
  const { t } = useTranslation("flamingo");
  const { levelImage, levelName, progressBar } = useUserProgress();

  const MessageComponent = UseWelcomeCardMessage();

  return (
    <Card withBorder radius={"lg"}>
      <Grid>
        <Grid.Col span={{ base: 12, md: 4 }}>
          <Group justify="center">
            <Image alt="" src={levelImage} width="81.33px" height="98px"/>
          </Group>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 8 }}>
          <Stack>
            <Title order={2} size="h4">{levelName}</Title>
            <Progress
              value={Math.floor(progressBar.progress)}
              role="progressbar"
              aria-valuenow={progressBar.curXp}
              aria-valuemin={0}
              aria-valuemax={progressBar.totalXp}
              aria-labelledby="progress-text"
              aria-label={t('levelCard_experiencePoints_summary', { earned:progressBar.curXp, total: progressBar.totalXp })}
            />
            <label id="progress-text" className={style.progreeBarLevelXp}>
              <strong>{progressBar.curXp}</strong>
              <span className="sr-only">{t("experiencePoints")}</span>
              <span>{t("out_of")}</span>
              <strong>{progressBar.totalXp}</strong>
              <span className="sr-only" >{t("experiencePoints")}</span>
            </label>
            {!!MessageComponent && <MessageComponent />}
            {FLAGS.isCertificateActivated && (
                <CertificateModal />
            )}
          </Stack>
          </Grid.Col>
      </Grid>
    </Card>
  );
};

export default LevelCard;
