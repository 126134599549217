import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

// Components
import Footer from "shared/components/footer";
import { clsx } from "@sosafe-platform-engineering/fe-lib-ui-react";

const hideFooterOnLandscapePages = ["PlayerPage"];

type AppPageContentType = {
  children: JSX.Element | JSX.Element[];
};

export default function AppPageContent(props: AppPageContentType) {
  const { children } = props;

  const { i18n } = useTranslation("translations");
  const [activeComponent, setActiveComponent] = useState("");

  const location = useLocation();

  useEffect(() => {
    const pathArr = location.pathname.split("/");
    if (pathArr.length >= 4 && pathArr.includes("elearning")) {
      setActiveComponent("PlayerPage");
    } else {
      setActiveComponent("");
    }
  }, [location]);

  return (
    <div
      key="scrollContainer"
      id="scrollContainer"
      className={clsx(
        {
          arab: i18n.language === "ar",
          "pt-0": activeComponent === "PlayerPage",
          "pt-4": activeComponent !== "PlayerPage",
        },
        "app-page-content"
      )}
    >
      <div className="app-page-content-scroll-container px-md-2">
        {children}
      </div>
      <Footer
        hideOnLandscape={hideFooterOnLandscapePages.includes(activeComponent)}
      />
    </div>
  );
}
