import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { queryKey, STALE_TIME } from 'shared/modules/sosafe-connect';
import ActionTypes from 'shared/utilities/action-types';
import { UseReferenceHandler } from 'shared/utilities/use-reference-handler';

interface useSoSafeQueryProps<T> {
  requestMethod: () => Promise<AxiosResponse<T>>;
  key: queryKey;
  onSuccess?: (response: AxiosResponse<T>) => void;
  onError?: (error: any) => void;
  retry?: (failureCount: number, error: any) => boolean;
  requestType: ActionTypes;
  successType: ActionTypes;
  failedType: ActionTypes;
  /** Set this to false to disable automatic refetching when the query mounts or changes query keys.
     * To refetch the query, use the refetch method returned from the useQuery instance. Defaults to true. */
  enabled: boolean;
  useLocalStorage?: boolean;
}

export function useSoSafeQuery<T>({
  requestMethod,
  key,
  onSuccess,
  onError,
  successType,
  failedType,
  enabled,
  retry,
}: useSoSafeQueryProps<T>) {
  const dispatch = useDispatch();
  const checkReference = UseReferenceHandler();

  return useQuery([key], requestMethod, {
    enabled,
    staleTime: STALE_TIME,
    onSuccess: (response) => {
      onSuccess?.(response);
      dispatch({ payload: checkReference(response.data, dispatch), type: successType });
    },
    onError: (error: any) => {
      onError?.(error);
      if (error?.response?.data) {
        dispatch({ payload: checkReference(error.response.data, dispatch), type: failedType });
      } else if (error.name && error.statusCode) {
        dispatch({
          payload: checkReference(
            { title: error.name, status: error.statusCode, reference: '8CCA1873-7195-4EB7-8BF4-32CCF473B353' },
            dispatch,
          ),
          type: failedType,
        });
      } else {
        console.error(`Unexpected error while fetching data with key:${key}`, error);
      }
    },
    refetchOnWindowFocus: true,
    ...(retry ? { retry } : {}),
  });
}
