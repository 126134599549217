import AR from './ar/translations.json';
import BG from './bg/translations.json';
import CH from './ch/translations.json';
import CS from './cs/translations.json';
import DA from './da/translations.json';
import DE from './de/translations.json';
import EL from './el/translations.json';
import EN from './en/translations.json';
import ES from './es/translations.json';
import ET from './et/translations.json';
import FI from './fi/translations.json';
import FR from './fr/translations.json';
import HR from './hr/translations.json';
import HU from './hu/translations.json';
import IT from './it/translations.json';
import JA from './ja/translations.json';
import NL from './nl/translations.json';
import NO from './no/translations.json';
import PL from './pl/translations.json';
import PT from './pt/translations.json';
import RO from './ro/translations.json';
import RU from './ru/translations.json';
import SK from './sk/translations.json';
import SL from './sl/translations.json';
import SV from './sv/translations.json';
import TR from './tr/translations.json';
import VI from './vi/translations.json';
import ZH from './zh/translations.json';
import HI from './hi/translations.json';
import KO from './ko/translations.json';
import TH from './th/translations.json';
import UK from './uk/translations.json';

declare module 'react-i18next' {
  interface Resources {
    policy: typeof EN;
  }
}

export default {
  AR,
  BG,
  CH,
  CS,
  DA,
  DE,
  EL,
  EN,
  ES,
  ET,
  FI,
  FR,
  HR,
  HU,
  IT,
  JA,
  JP: JA,
  NL,
  NO,
  NB: NO,
  PL,
  PT,
  RO,
  RU,
  SK,
  SL,
  SV,
  SE: SV,
  TR,
  VI,
  ZH,
  HI,
  KO,
  TH,
  UK,
};
