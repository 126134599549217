import { useCategoriesRequest } from "elearning/hooks/use-categories-request";
import React, { useMemo } from "react";
import { CategoryProps, ModuleProps } from "types";
import useUser from "shared/hooks/use-user";
import { PersonalizedModule } from "../../types/module.type";
import { MODULE_STATUS } from "../../types/module-status.type";
import env from "../../components/shared/check-for-flags";

export const UsePersonalizedModule = () => {
  const { data, isLoading } = useCategoriesRequest(true);
  const { user } = useUser({ enabled: true });

  const flattenModules = useMemo((): Array<ModuleProps> => {
    if (!data?.data?.result) return [];
    return data.data.result.flatMap((category: CategoryProps) => [
      ...category.modules,
    ]);
  }, [data]);


  const getModuleStatus = (module: ModuleProps): MODULE_STATUS => {
    if (module.status === 1) return MODULE_STATUS.CONTINUE;
    if (module.status === 2) return MODULE_STATUS.START;
    if (module.status === 3) return MODULE_STATUS.RESTART;
    if (module.status === null) {
      if (env.isAwarenessAssessment && user?.campaign?.is_awareness_training_enabled) {
        return module.awarenessAssessmentIds?.length > 0 ? MODULE_STATUS.AWARENESS_ASSESSMENT : MODULE_STATUS.START;
      }
    }
    return MODULE_STATUS.START;
  }

  const personalizedModules = useMemo(
    (): Array<PersonalizedModule> =>
      flattenModules
        .map((mod) => (
          {
            ...mod,
            category: mod.category_key,
            id: mod.id,
            moduleKey: mod.module_name,
            thumbnailUrl: mod.image,
            status: getModuleStatus(mod),
          }
        ))
        .sort((modA, modB) => modA.sort - modB.sort),
    [flattenModules]
  );

  return {
    isLoading,
    personalizedModules,
  };
};
