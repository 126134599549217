import { AxiosResponse } from "axios";
import ActionTypes from "shared/utilities/action-types";
import { useDispatch } from "react-redux";
import useChameleon from "shared/hooks/app-hooks/use-chameleon";
import { UseGlobalSettingsContext } from "shared/modules/global-settings/state/global-settings-context.provider";
import { ProductAnalytics } from "shared/modules/product-analytics";
import {
  FetchAuthorizationReturnType,
  queryClient,
  queryKey,
  useSoSafeConnect,
} from "shared/modules/sosafe-connect";
import { useQueryClientContext } from "shared/modules/sosafe-connect/query/use-query-client-context";

export const useClearLoginData = () => {
  const userResult = queryClient.getQueryData<
    AxiosResponse<FetchAuthorizationReturnType>
  >([queryKey.AUTH]);

  const user = userResult?.data.result;

  const chameleon = useChameleon(user);
  const { removeQueryClientPersister } = useQueryClientContext();
  const { removeApiKey } = useSoSafeConnect();
  const { removeLocalToken } = UseGlobalSettingsContext();
  const dispatch = useDispatch();

  return (expiredSession?: boolean) => {
    sessionStorage.removeItem("MSAL_STATE");

    if (expiredSession && user) {
      const expiredUser = user.email ?? user.elearning_code ?? "";
      const key = user.use_sso ? "sessionExpiredSso" : "sessionExpired";
      sessionStorage.setItem(key, expiredUser);
    }

    /* Reward system still use redux therefore we need to clear the redux store */
    dispatch((disp) => disp({ type: ActionTypes.CLEAR_STORE }));

    removeQueryClientPersister();

    // third parts
    ProductAnalytics.getInstance().logout();
    chameleon.clear();

    removeLocalToken();
    removeApiKey();

    // Clear this workaround after the real fix of chameleon team
    window.location.reload();

    /* Remove queries needs to be after removing the api key to prevent mounting
     * the react-query hooks and triggering a request with an invalid api key */
    queryClient.removeQueries();
  };
};
