/**
 * returns the number of days between two date objects
 *
 * @param {Date} date1 a date Object
 * @param {Date} date2 a date Object
 * @returns {number} number of Days
 *
 * @category shared
 * @subcategory helpers
 * @exports daysBetweenDates
 */
export default function daysBetweenDates(date1: Date, date2: Date): number {
  const diffInMilliseconds = Math.abs(date1.getTime() - date2.getTime());
  return Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
}
