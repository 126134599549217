import { useQuery } from "@tanstack/react-query";
import { useHistory } from "react-router";
import { queryKey, useSoSafeConnect } from "shared/modules/sosafe-connect";

interface UseSurveyResultProps {
  userId?: number;
  surveyFeatureId: number;
  surveyTypeId: number;
  /* Set this to `false` to disable automatic refetching when the query mounts or changes query keys.
  To refetch the query, use the `refetch` method returned from the `useQuery` instance.
  Defaults to `true`. */
  enabled: boolean;
}

/**
 * Fetch the survey result of survey the user has answered
 */
export const useSurveyResult = ({
  userId,
  enabled,
  surveyFeatureId,
  surveyTypeId,
}: UseSurveyResultProps) => {
  const { endPoints, apiKey } = useSoSafeConnect();
  const history = useHistory();

  const { data, isLoading } = useQuery(
    [queryKey.SURVEY_HUB_RESULT, surveyTypeId, surveyFeatureId],
    () =>
      endPoints.surveyHubApi.fetch.getSurveyResult({
        userId: userId || 0,
        surveyTypeId,
        surveyFeatureId,
      }),
    {
      enabled: enabled && !!apiKey && !!userId,
      retry: false,
      // no need to fetch from be after the first time
      staleTime: Infinity,
      onError: () => {
        // this cost a constant loader if there is no survey data
        // history.replace("/survey");
      },
      onSuccess: (response) => {
        // in case the user access the survey directly by url
        // if survey result exist redirect to home
        if (response?.data?.result?.length) {
          history.push("/");
        }
      },
    }
  );

  return {
    answeredSurveys: data?.data,
    isFetchingSurveysAnswers: isLoading,
  };
};
