/**
 * @deprecated since survey-hub implementation
 */
import { SurveyProps } from '../sosafe-connect';

export enum SURVEY_STEP_TYPES {
  START = 'START',
  STEP = 'STEP',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export interface SurveyContextProps {
  currentSurveyStep: SURVEY_STEP_TYPES;
  currentSurvey: SurveyProps | null;
  setCurrentSurveyStep: React.Dispatch<React.SetStateAction<SURVEY_STEP_TYPES>>
}

export interface ParsedSurvey extends SurveyProps {
  form: any
  ui: any,
}
