import React, { useEffect } from "react";

import { shallowEqual, useSelector } from "react-redux";
import { useNotificationSystemContext } from "shared/modules/notification-system";
import { RootState } from "state/reducers";
import { Notification, NotificationType, Rewards } from "./types";

const NotificationRewardStateInterceptor = () => {
  const { setNotifications } = useNotificationSystemContext();

  const { rewards } = useSelector<RootState, { rewards: Rewards }>(
    (state) => state.rewards,
    shallowEqual
  );

  // We still have to use this Redux reward state because we still don't
  // have a axios centralized and it would because very hard to intercept all instances
  // of axios all over the app
  // So, monitoring the state is the closest of centralized intercept that we have for now
  useEffect(() => {
    if (Object.keys(rewards).length) {
      const modals: Notification[] = [];
      if (rewards?.xp && !rewards?.level) {
        modals.push({
          type: NotificationType.XP,
          xp: rewards.xp as number,
          displayOrder: 1,
        });
      }

      if (rewards?.level) {
        modals.push({
          type: NotificationType.LEVEL,
          level: rewards.level,
          xp: rewards.xp,
          displayOrder: 2,
        });
      }

      if (rewards?.badges?.length) {
        modals.push({
          type: NotificationType.BADGE,
          badges: rewards.badges,
          displayOrder: 3,
        });
      }

      if (modals.length) {
        setNotifications(modals);
      }
    }
  }, [rewards]);

  return <React.Fragment />;
};

export default NotificationRewardStateInterceptor;
