import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import React, { FunctionComponent } from "react";
import { queryKey } from "../query-key";

interface QueryContextProps {
  removeQueryClientPersister: () => void;
}

export const QueryContext = React.createContext<QueryContextProps>({
  removeQueryClientPersister: () => {
    /* mock */
  },
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

export const forceResetQueriesStore = () => {
  queryClient.removeQueries();
  window.localStorage.removeItem("queries");
};

/**
 * exposes queryClient to window so cypress can invalidate cache for cases that
     we post/put direct to backend without using the e-learning */
if (window.elearningRuntimeConfig.CYPRESS_ENV) {
  window.queryClient = queryClient;
}

/** default stale time used by useQuery */
export const STALE_TIME = 30000;
const persistQueryKeys: queryKey[] = [
  queryKey.AUTH,
  queryKey.ELEARNING,
  queryKey.SURVEY_HUB_SURVEY_ACTIVE,
  queryKey.SURVEY_HUB_RESULT,
  queryKey.LEARNING_PATH_RECALCULATION_NEEDED,
  queryKey.LEARNING_PATH_RECALCULATION_TRIGGERED,
];

/** Provide the QueryClientProvider and the React Query Devtools */
export const QueryContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const localStoragePersister = createSyncStoragePersister({
    storage: window.localStorage,
    key: "queries",
    throttleTime: 1000,
  });

  const removeQueryClientPersister = () => {
    localStoragePersister.removeClient();
  };

  persistQueryClient({
    queryClient,
    persister: localStoragePersister,
    dehydrateOptions: {
      shouldDehydrateQuery: (prop) => {
        const key = prop.queryKey[0];
        if (!key) return false;
        return persistQueryKeys.includes(key as queryKey);
      },
    },
  });

  return (
    <QueryContext.Provider value={{ removeQueryClientPersister }}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        {children}
      </QueryClientProvider>
    </QueryContext.Provider>
  );
};
