import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import scanResponseForReward from 'state/reward-middleware';


function onRequest(config: AxiosRequestConfig) {
  let { headers } = config;
  if (config.url?.includes('auth')) {
    headers = {
      ...headers,
      'Cache-Control': 'no-cache',
    };
  }
  return {
    ...config,
    headers,
    timeout: 40000,
  };
}

function onResponseError(err) {
  if (err?.response?.status >= 500) {
    if (window.location.pathname !== '/503') {
      window.location.href = '/503';
    }
  }

  throw err;
}

function onResponse(response: AxiosResponse) {
  const { data } = response || {};
  if (data) {
    try {
      const dataObj = JSON.parse(data);
      const { reference } = dataObj;
      switch (reference) {
        case 'FFCEBC74-F43B-48F9-8D60-CCEC0475A2C5': // LMS server down
        case '169F44E5-3DE9-455C-A29D-5725B513B447': // API server down
        case 'ED90A8E5-2E30-4A20-9B0F-1323E30ED102': // Fileserver Verbindung nicht möglich:
        case '8C641CC3-A6AE-4137-8111-0A695A735A8A': // DB Server kann nicht erreicht werden:
        case '787F4603-7910-425F-B117-ACEEF54DA04F': // DB Verbindung existiert nicht:
        case '81B40FCF-72F6-4B15-A22B-5804B355D2E4': // DB Verbindung Fehlgeschlagen:
        case 'F79D123A-DBC3-46F6-A26F-7FC6EC1251D8': // DB Server Festplatte ist voll:
        case 'EBDA8E7D-2848-4AC5-B8EA-51EDE2C15CC1': // DB Server Arbeitsspeicher ist voll:
        case 'FAD0DB70-8550-40CE-9E86-434CD7FE8E69': // DB Server zu viele Verbindungen:
          window.location.href = '/503';
          break;
        default:
          break;
      }
    } catch (error) {
      return response;
    }
  }
  return response;
}



/**
 * Interceptor: Attach apikey from local storage in case current axiosInstance does not have headers setup
 * @param config AxiosRequestConfig
 * @returns AxiosRequestConfig
 */
const interceptorHasAuthorization = (config: AxiosRequestConfig, onMissingAuth: () => void) => {
  const requestApikey = config.headers?.Authorization;
  if (requestApikey) return config;

  const localStorageApiKey = window.localStorage.getItem('apikey');

  if (!localStorageApiKey) {
    onMissingAuth();
    throw new axios.Cancel('Operation canceled due to missing authorization');
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: localStorageApiKey,
    },
  };
};

export const SoSafeInterceptors = (config: AxiosRequestConfig, callback: () => void) => ({
  interceptorHasAuthorization: () => interceptorHasAuthorization(config, callback),
  503: () => onResponseError(config),
});

type SoSafeInterceptorsKey = keyof ReturnType<typeof SoSafeInterceptors>;

/**
 * Add a sosafe axios request interceptor
 * @param axiosInstance
 * @param interceptorKey
 * @param callback
 */
export const addSoSafeRequestInterceptor = (
  axiosInstance: AxiosInstance,
  interceptorKey: SoSafeInterceptorsKey,
  callback: () => void,
) => {
  axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => SoSafeInterceptors(config, callback)[interceptorKey]() as InternalAxiosRequestConfig);
  axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => onRequest(config) as InternalAxiosRequestConfig);
};

export const addSoSafeResponseInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use((config: AxiosResponse) => onResponse(config), onResponseError);
  axiosInstance.interceptors.response.use((config: AxiosResponse) => scanResponseForReward(config), onResponseError);
}
