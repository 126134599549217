import React from 'react';
import { useHistory } from 'react-router';
import { openBadgeModal } from 'shared/components/badge-modal';
import ResponsivePicture from 'shared/components/responsive-picture';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@sosafe-platform-engineering/fe-lib-ui-react';
import { BadgeProps } from 'shared/modules/sosafe-connect/response-type';
import './badge.scss';

interface BadgeComponentProps {
  badge: BadgeProps,
  fromView: string,
  height: number
}

/**
 * Component to display an achievement badge
 *
 * @category elearning
 * @subcategory components
 * @exports Badge
 * @component
 */
const Badge = ({ badge, fromView, height = 75 }: BadgeComponentProps) => {
  const received = badge.received;
  const history = useHistory();
  const { i18n } = useTranslation(['translations']);

  return (
        <Tooltip
            content={badge.name}
        >

            <div
                role='button'
                onClick={() => openBadgeModal(badge, history, i18n, fromView)}
                aria-label={badge.name}
            >
                <ResponsivePicture
                    grayscale={!received}
                    src={badge.image}
                    alt={badge.name}
                    fit='contain'
                    defaultHeight={height}
                />
            </div>
        </Tooltip>
  );
};

export default Badge;
