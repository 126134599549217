import {
  Button,
  Text,
  Title,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Shine } from "shared/svgs";
import { ModalBody } from "flamingo-e-learning-platform/utilities/modal-alert/modal-body";
import { useModalContext } from "modal-context/modal-context";
import { ALERT_TYPE } from "flamingo-e-learning-platform/utilities/modal-alert/shared";
import style from "./badge-modal.module.css";

interface BadgeProps {
  name: string;
  image: string;
  description: string;
  received: string | null;
}

interface BadgeModalProps {
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  badge: BadgeProps | null;
}

/**
 * Show a badge modal with image name and description.
 * includes 'Close' button translations
 *
 * @param {function} onClose
 * @param {object}   badge      Object with name, image and description keys
 *
 * @example { How to use }
 *  <BadgeModal
 *      onClose={()=> {setMyBadge(null)}}
 *      badge={myBadge}
 *  />
 *
 * @component
 * @category shared flamingo
 * @returns component
 */
export const BadgeModal = ({ onClose, badge }: BadgeModalProps) => {
  const { t } = useTranslation(["translations", "flamingo"]);
  const { close } = useModalContext();

  const onModalClose = () => {
    onClose();
    close();
  };

  const altText =
    badge?.received && badge?.name
      ? `${badge?.name} - ${t("flamingo:badgeAchievedPrev")}`
      : `${badge?.name} - ${t("flamingo:badgeNotAchievedPrev")}`;

  const modalProps = {
    content: (
      <div className={style.badgeModal}>
        <div className={style.content}>
          <img alt={altText} src={badge?.image} />
          <h2>{badge?.name}</h2>
          <Text className={style.description}>{badge?.description}</Text>
        </div>
        <div className={style.shineWrapper}>
          <Shine className={style.shine} />
        </div>
      </div>
    ),
    footer: (
      <Button variant="secondary" onClick={onModalClose}>
        {t("translations:Close")}
      </Button>
    ),
    type: ALERT_TYPE.NONE,
  };

  return <ModalBody {...modalProps} />;
};
