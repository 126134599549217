import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CheckSquareIcon,
  ClipboardIcon,
  IconButton,
} from "@sosafe-platform-engineering/fe-lib-ui-react";
import { copyString } from "./help";

interface CopyToClipboardProps {
  content: string;
  ariaLabel?: string;
  className?: string;
}

/**
 * copies a string to the users clipboard
 *
 * @param {String} str String to copy to clipboard
 * @returns {Boolean} copy successful
 *
 * @category shared
 * @subcategory helpers
 * @exports copyToClipboard
 */

export const CopyToClipboard = ({
  content,
  className,
  ariaLabel,
  ...rest
}: CopyToClipboardProps & JSX.Element) => {
  const { t } = useTranslation("translations");
  const [copyState, setCopyState] = useState(0);

  const CopyButton = useCallback(() => {
    if (copyState === 1) {
      return (
        <IconButton
          {...rest}
          color="success"
          className={`${className}`}
          onClick={() => copyString(content, setCopyState)}
          ariaLabel={ariaLabel || t("Copy Text")}
          icon={CheckSquareIcon}
        />
      );
    }

    return (
      <IconButton.Outline
        {...rest}
        color="success"
        className={`${className}`}
        onClick={() => copyString(content, setCopyState)}
        ariaLabel={ariaLabel || t("Copy Text")}
        icon={ClipboardIcon}
      />
    );
  }, [copyState]);

  return <CopyButton />;
};
