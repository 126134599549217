import React from "react";
import styles from "./survey-header.module.css";

const SurveyHeader = ({ headerText }) => (
  <div className={styles.header}>
    <h4>{headerText}</h4>
  </div>
);

export default SurveyHeader;
