import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GameProgressLevelProps } from "types";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import {
  Button,
  Text,
  Title,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { ALERT_TYPE } from "flamingo-e-learning-platform/utilities/modal-alert/shared";
import useUser from "shared/hooks/use-user";
import isPLuser from "shared/helpers/personalizedLearning/is-pl-user";
import AnimatedNumber from "../animated-number";
import Progressbar from "../progress-bar";
import { calculateProgress } from "../progress-bar/progressbar.utils";
import RewardShieldImage from "../reward-shield-image";
import { FeedbackError } from "../feedback";
import { ModalBody } from "../../../flamingo-e-learning-platform/utilities/modal-alert/modal-body";
import { useModalContext } from "../../../modal-context/modal-context";

interface NewLevelRewardProps {
  type: "xp" | "levelUp";
  currentLevel: GameProgressLevelProps;
  nextLevel?: GameProgressLevelProps;
  receivedXp: number;
  currentXp: number;
  onNext(): void;
}

type RewardType = "current" | "next";

export const NewLevelReward = ({
  currentLevel,
  nextLevel,
  currentXp,
  onNext,
  receivedXp,
  type,
}: NewLevelRewardProps) => {
  const { t } = useTranslation("translations");
  const [rewardType, setRewardType] = useState<RewardType>("current");
  const { close } = useModalContext();
  const { user } = useUser({ enabled: false });
  const showPersonalizedLearning = isPLuser(user);

  const isCurrent = type === "xp" || rewardType === "current";

  const Reward = useCallback(() => {
    const level = isCurrent ? currentLevel : nextLevel;
    const title = isCurrent
      ? "Experience points received"
      : "New level reached";

    const currentXpBasedOnType = useMemo(() => {
      if (type === "xp") return currentXp;
      return isCurrent ? currentXp - receivedXp : currentXp;
    }, [type, currentXp, receivedXp]);

    if (!level) return <FeedbackError />;

    const progress = calculateProgress({
      minXp: level.xp,
      maxXp: level.xp_max,
      currentXp: currentXpBasedOnType,
      receivedXp,
    });

    // progress bar
    // the final percentage of current should always be 100%
    const endProgressBar =
      isCurrent && type === "levelUp" ? 100 : progress.percentageXp;

    // animated number
    // the current level xp can only ne as hight as the maxLevelXp
    const endAnimatedNumber = useMemo(() => {
      if (type === "xp") return progress.currentLevelXp;
      return isCurrent ? progress.maxLevelXp : progress.currentLevelXp;
    }, [isCurrent, progress]);

    const startAnimatedNumber = useMemo(() => {
      if (type === "xp") return progress.currentStartLevelXp;
      return isCurrent ? progress.currentLevelXp : 0;
    }, [isCurrent, progress]);

    const onNextClick = () => {
      close();
      onNext();
    };

    const modalProps = {
      title: showPersonalizedLearning ? undefined : (
        <Title size="h3">{t(title)}</Title>
      ),
      content: (
        <div>
          <RewardShieldImage image={level.image} />
          <h2 id="level-reward-title" className="text-secondary mb-2">
            {level.name}
          </h2>
          <SwitchTransition>
            <CSSTransition
              in={true}
              timeout={300}
              classNames={{
                appear: "fade",
              }}
              unmountOnExit
            >
              <React.Fragment>
                {rewardType === "current" && (
                  <Text size="h3">+{receivedXp} EP</Text>
                )}
                <Progressbar
                  aria-valuetext={`${endProgressBar}% - ${
                    progress.currentLevelXp
                  } ${t("out of")} ${progress.maxLevelXp} ${t(
                    "Experience points"
                  )}`}
                  className="mb-1"
                  currentLevelXp={endAnimatedNumber}
                  progress={endProgressBar}
                  labelledBy="level-reward-title"
                  animate
                  startProgress={0}
                  delay={0.2}
                  maxValue={progress.maxLevelXp}
                />
                <Text
                  aria-hidden={true}
                  id="progress-description"
                  className="mb-3"
                >
                  <AnimatedNumber
                    start={startAnimatedNumber}
                    end={endAnimatedNumber}
                    duration={1}
                    delay={0}
                  />
                  /
                  <span data-testid="maxXpLevel">{progress.maxLevelXp} EP</span>
                </Text>
              </React.Fragment>
            </CSSTransition>
          </SwitchTransition>
        </div>
      ),
      footer: (
        <Button
          onClick={onNextClick}
          disabled={isCurrent && type === "levelUp"}
          data-testid="lets-go-button"
        >
          {t("Great, let's go!")}
        </Button>
      ),
      type: ALERT_TYPE.NONE,
    };

    return <ModalBody {...modalProps} />;
  }, [currentLevel, nextLevel, rewardType, currentXp]);

  useEffect(() => {
    if (type === "levelUp") {
      // change reward type from current to next after 2000 ms
      const timeout = setTimeout(() => {
        setRewardType("next");
      }, 2000);
      return () => clearTimeout(timeout);
    }
    return () => {
      /** */
    };
  }, [type]);

  return <Reward />;
};
