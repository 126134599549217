const ACTIVE_PRESENTER_PATH = 'HTML5/test.html';

/**
 *
 * @param scormEntryPoint SCORM entry point path
 * @param options checkOnlyForDefaultModules = Check if the Active presenter module is Default, the other option would be a Custom Module which is 'scorm/HTML5/test.html'
 * @returns Boolean (true/false)
 */
export default function checkIsActivePresenter(scormEntryPoint = '', { checkOnlyForDefaultModules } = { checkOnlyForDefaultModules: false }) {
  if (checkOnlyForDefaultModules) {
    return scormEntryPoint === ACTIVE_PRESENTER_PATH;
  }
  return scormEntryPoint?.includes(ACTIVE_PRESENTER_PATH);
}
