import React from "react";
import { useModalContext } from "modal-context/modal-context";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import useCertificate from "shared/hooks/use-certificate";
import { useSoSafeAnalytics } from "shared/hooks/use-sosafe-analytics";
import useUser from "shared/hooks/use-user";
import { ANALYTICS_TYPE, QueryContextProvider, SoSafeAxiosProvider } from "shared/modules/sosafe-connect";
import { Shine } from 'shared/svgs';
import { URL_RESOURCE_API } from "shared/helpers";
import { Button, Text } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { store } from "state/store/store";
import style from "./use-certificate-modal.module.css";

export const UseCertificateModal = () => {
  const { fire, close } = useModalContext();


  const showCertificateModal = () => {
    fire({
      html: (
        <Provider store={store}>
          <QueryContextProvider>
            <SoSafeAxiosProvider>
              <CertModal />
            </SoSafeAxiosProvider>
          </QueryContextProvider>
        </Provider>
      ),
      onClose: async () => {
        close();
      },
      size: "lg",
    })
  };

  return { showCertificateModal };
}

function CertModal() {
  const { t } = useTranslation('translations');
  const { user } = useUser({});
  const { loading, download } = useCertificate();
  const { sendSoSafeAnalytics } = useSoSafeAnalytics();
  const customerToken = user && user?.customer ? user?.customer.token : '';

  return (
    <div className={style.container} >
      <div className={style.container}>
        <img
          className={style.certificateImage}
          src={`${URL_RESOURCE_API()}/${customerToken}/img/assets/certificate.png`} alt={'test'} />

        <div className={style.shineWrapper}>
          <Shine className={style.shine} />
        </div>
      </div>

      <Text pb={10} >{t('Here you can download your highly deserved certificate!')}</Text>

      <Button
        disabled={loading}
        onClick={() => {
          download();
          sendSoSafeAnalytics({ type: ANALYTICS_TYPE.CERT_DOWNLOADED });
        }}
      >
        <Text>{loading ? t('Loading ...') : t('Download now')}</Text>
      </Button>

    </div>
  )
}
