import React from "react";
import { useTranslation } from "react-i18next";

interface BadgeSectionTitleProps {
  row: number;
  isLast: boolean;
  children: React.ReactNode;
}

const BADGE_SECTION_TITLES = {
  1: "State of knowledge",
  2: "Learning achievements",
  3: "Rewards",
  4: "Challenges",
  5: "Strengthening",
};

export const BadgeSection: React.FunctionComponent<BadgeSectionTitleProps> = ({
  children,
  row,
  isLast,
}) => {
  const { t } = useTranslation("translations");

  const title = BADGE_SECTION_TITLES[row];
  const translatedTitle = title ? t(title) : "";

  return (
    <React.Fragment>
      <h6 className="u-paragraph mb-2 u-textBold text-center text-muted">
        {translatedTitle}
      </h6>
      <div
        className={`d-flex flex-row justify-content-center flex-wrap ${
          isLast ? "" : "border-bottom mb-3"
        }`}
      >
        {children}
      </div>
    </React.Fragment>
  );
};
