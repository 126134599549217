/**
 * This component replaces the component of surveyJS with our custom one
 */
import React from "react";
import { ReactElementFactory, ReactSurveyElement } from "survey-react-ui";
import { useTranslation } from "react-i18next";
import {
  Group,
  Radio,
  Text,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";

export const SOSAFE_RADIO_BUTTON = "sosafe-radio-button";

class RadioButton extends ReactSurveyElement {
  renderElement() {
    const { question, item, currentPageNb, totalPagesCount } = this.props;
    const itemId = question.getItemId(item);
    const isLastQuestionPage = currentPageNb === totalPagesCount - 1;

    // The component changes based on whether it is needs to display more that 2 items or not
    const isSmallContainer = question.choices.length <= 2;

    const handleOnChange = () => {
      question.value = item.value;
    };

    const handleKeyDown = (event) => {
      // Get all elements with the class 'radioCard'
      const radioCards = document.querySelectorAll(".surveyRadioCard");

      // Find the currently focused element
      const currentRadioCard = document.activeElement;

      // Convert NodeList to an array to use array methods
      const radioCardsArray = Array.from(radioCards);
      if (currentRadioCard) {
        // Get the current index of the focused element
        const currentIndex = radioCardsArray.indexOf(currentRadioCard);

        if (event.key === "ArrowRight" && currentIndex !== -1) {
          // Move focus to the next element or circle back to the first element
          const nextIndex = (currentIndex + 1) % radioCardsArray.length;
          (radioCardsArray[nextIndex] as any).focus();

          // Stop the event from propagating
          event.stopPropagation();
        } else if (event.key === "ArrowLeft" && currentIndex !== -1) {
          // Move focus to the previous element or circle back to the last element
          const previousIndex =
            (currentIndex - 1 + radioCardsArray.length) %
            radioCardsArray.length;
          (radioCardsArray[previousIndex] as any).focus();

          // Stop the event from propagating
          event.stopPropagation();
        }
      }
    };

    // Use the useTranslation hook
    const { t } = this.props;
    return (
      <div role="presentation">
        <Radio.Card
          onClick={handleOnChange}
          onKeyDownCapture={handleKeyDown}
          id={itemId}
          aria-label={
            isLastQuestionPage
              ? item.text
              : `${t("answer")}: ${item.text}.`
          }
          aria-describedby="action-description"
          className="surveyRadioCard"
          radius="md"
          value={item.value}
          checked={question.value === item.value}
        >
          <Group
            gap="xs"
            justify="center"
            w={95}
            h={isSmallContainer ? "auto" : 145}
            p="sm"
            style={{
              textAlign: "center",
              alignItems: "flex-start",
              wordWrap: "break-word",
            }}
          >
            <Radio.Indicator />
            <Text fw={500}>{item.text}</Text>
          </Group>
        </Radio.Card>
      </div>
    );
  }
}

// Higher-order component to inject the t function from useTranslation
const withTranslation = (Component) => (props) => {
  const { t } = useTranslation("survey_hub");
  return <Component {...props} t={t} />;
};

const TranslatedRadioButton = withTranslation(RadioButton);

export const registerSoSafeRadioButton = ({
  currentPageNb,
  totalPagesCount,
}: {
  currentPageNb: number;
  totalPagesCount: number;
}) => {
  ReactElementFactory.Instance.registerElement(SOSAFE_RADIO_BUTTON, (props) => (
    <TranslatedRadioButton
      {...props}
      currentPageNb={currentPageNb}
      totalPagesCount={totalPagesCount}
    />
  ));
};
