import React from "react";
import { useModalContext } from "modal-context/modal-context";
import { ModalAlertConfig } from "./shared";
import { ModalBody } from "./modal-body";

export const UseShowDecisionAlert = () => {
  const modal = useModalContext();

  const show = (props: ModalAlertConfig) => modal.fire({
    html: <ModalBody {...props} />,
    onClose: async () => {
      await props.onClose?.();
      modal.close();
    },
  });

  return { show, close: modal.close };
};
