import { useEffect } from "react";
import { checkRedirect, getUrlParameter } from "shared/helpers";
import { useSoSafeConnect } from "shared/modules/sosafe-connect";
import { useItWasntMeRequest } from "authentication/hooks/use-it-wasnt-me-request";
import { UserType } from "types";
import { RouteComponentProps } from 'react-router-dom';

export default function useItWasntMeConnection(user: UserType | undefined, location: { search: string; }, history: RouteComponentProps["history"]){
    const { endPoints } = useSoSafeConnect();
    const { mutate: deleteActivation } = useItWasntMeRequest();
    useEffect(() => {
        const itwasntmeParam = getUrlParameter(location.search, "itwasntme");
        if (itwasntmeParam && itwasntmeParam.length) {
          deleteActivation({ code: itwasntmeParam });
          history.push("/");
        }
    
        const soSafeSource = getUrlParameter(location.search, "sosa_source");
        if (soSafeSource?.length) {
          const soSafeTarget = getUrlParameter(location.search, "sosa_target");
          const target = soSafeTarget?.length ? soSafeTarget : "";
          endPoints.fetch.report({ code: soSafeSource, target }).catch(() => {
            /** fire and forget */
          });
        }
          
        checkRedirect(location, history, user);
        
    
        if (window.location.href[window.location.href.length - 1] === "#") {
          history.push("/");
        }
    
        return () => {};
      }, []);
}