import { MutableRefObject, SetStateAction, useEffect } from "react";

export default function useOutsideAlerter(ref: MutableRefObject<HTMLInputElement | null>, setIsMenuOpen: { (value: SetStateAction<boolean>): void; (arg0: boolean): void; }) {
    useEffect(() => {
    // Close dropdown if the click is happening outside of the dropdown area
    function handleClickOutside(event: { target: any; }) {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsMenuOpen(false);
        }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
    // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
    };
    }, [ref]);
}