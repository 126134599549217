import { useMutation } from "@tanstack/react-query"
import { useSoSafeConnect } from "shared/modules/sosafe-connect"
import { PostSurveyResponse } from "survey-hub/api/survey-hub.api";
import { PostSurveyProps } from "survey-hub/components/survey/types/post-survey.type";


interface UsePostSurveyAnswersProps {
  onSucess: (result: PostSurveyResponse) => void
  onError: <T = unknown>(error: T) => void
}

export const usePostSurveyAnswers = ({ onSucess, onError }: UsePostSurveyAnswersProps) => {

  const { endPoints } = useSoSafeConnect();

  return useMutation(
    (props: PostSurveyProps) => endPoints.surveyHubApi.post.postSurveyAnswers(props),
    {
      onSuccess: (response) => {
        onSucess(response.data);
      },
      onError: (error) => {
        onError(error);
      }
    }
  )
}
