/**
 * @deprecated since survey-hub implementation
 */
import React, { useEffect, useMemo, useState } from "react";
import { useGetSurvey } from "shared/hooks/use-survey/use-get-survey";
import { ParsedSurvey, SurveyContextProps, SURVEY_STEP_TYPES } from "./types";

const SurveyContextDefault: SurveyContextProps = {
  currentSurveyStep: SURVEY_STEP_TYPES.START,
  currentSurvey: null,
  setCurrentSurveyStep: () => {
    console.error(
      "cant call setCurrentSurveyStep:  SurveyProvider not initialized"
    );
  },
};

export const SurveyContext = React.createContext(SurveyContextDefault);

export const SurveyProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }: { children: React.ReactNode }) => {
  const {
    isLoading,
    error,
    data: surveyResponse,
    refetch,
  } = useGetSurvey(false);
  const surveys = surveyResponse?.data.result || [];
  const [currentSurveyStep, setCurrentSurveyStep] = useState<SURVEY_STEP_TYPES>(
    SURVEY_STEP_TYPES.START
  );

  useEffect(() => {
    // delay popup so it's not the first thing to show up
    // this should be treat as a temporary solution until we refactor onBoard system
    const timeout = setTimeout(() => refetch(), 200);
    return () => clearTimeout(timeout);
  }, []);

  const currentSurvey: ParsedSurvey | null = useMemo(() => {
    if (surveys.length) {
      const curSurvey = surveys[0];
      return {
        ...curSurvey,
        form: JSON.parse(curSurvey.form),
        ui: JSON.parse(curSurvey.ui),
      };
    }
    return null;
  }, [surveys]);

  if (isLoading || error || !currentSurvey) return null;

  return (
    <SurveyContext.Provider
      value={{
        ...SurveyContextDefault,
        currentSurvey,
        currentSurveyStep,
        setCurrentSurveyStep,
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};
