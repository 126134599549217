import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useInitTranslations from "lang/use-init-translations.hook";
import { SURVEY_TYPE, SURVEY_STEP } from "../constants";
import SurveyHeader from "./survey/survey-header";
import Survey from "./survey/survey";
import translations from "../lang";
import styles from "./survey-hub.template.module.css";

interface Props {
  WelcomeComponent: React.FC<{ onStart: () => void }>
  surveyType: SURVEY_TYPE
  CompletedComponent: React.FC
}

/**
 * Survey Hub template which controls a full survey e2e based on survey status and components passed in
 * Try not to use this directly, instead create a HOC component which calls it like culture-survey-hub.page.tsx
 * A welcome component is shown -> Start button clicked -> Survey is shown for completion -> Preview page shown -> Confirm clicked -> Completed page is shown
 * @category survey
 * @exports SurveyHubTemplate
 * @component
 */

const SurveyHubTemplate: React.FC<Props> = ({ WelcomeComponent, surveyType, CompletedComponent }) => {
  const { translationsReady } = useInitTranslations(translations, "survey_hub");
  const { t } = useTranslation("survey_hub");
  const [surveyStep, setSurveyStep] = useState<SURVEY_STEP>(
    SURVEY_STEP.WELCOME
  );

  if (!translationsReady) {
    return null;
  }

  //  TODO: For header text for new survey types do it like this: sur_SURVEY_TYPE_TEXT[SURVEY_TYPE.CULTURE_AND_CONTEXT] e.g. inProgress_hrsSelfRating etc.
  const headerText = surveyType === SURVEY_TYPE.CULTURE_AND_CONTEXT ? t(surveyStep) : '';

  return (
    <div className={styles.surveyHubContainer}>
      <span className="sr-only" id="action-description" aria-hidden={true}>{t("by_click")}</span>
      <div
        className={`${styles.surveyHubContent}${surveyStep === SURVEY_STEP.PREVIEW ? " sosafe-SurveyHub-Preview" : ""
          }`}
      >
        <SurveyHeader headerText={headerText} />
        {surveyStep === SURVEY_STEP.WELCOME && (
          <WelcomeComponent
            onStart={() => setSurveyStep(SURVEY_STEP.IN_PROGRESS)}
          />
        )}
        {/* The in progress and preview survey steps are controlled from within the survey library */}
        {(surveyStep === SURVEY_STEP.IN_PROGRESS ||
          surveyStep === SURVEY_STEP.PREVIEW) && (
            <Survey surveyStep={surveyStep} setSurveyStep={setSurveyStep} surveyType={surveyType} />
          )}
        {surveyStep === SURVEY_STEP.COMPLETED && <CompletedComponent />}
      </div>
    </div>
  );
}

export default SurveyHubTemplate;
