import {
  Title,
  Text,
  Button,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import useElearningApi from "flamingo-e-learning-platform/hooks/use-elearning-api";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loading from "shared/components/loading";
import useMetaLinks from "shared/hooks/use-meta-links";
import { CategoryProps } from "types";
import styles from "./calculating-learning-path.module.css";

const MAX_RETRIES = 4;
const RETRY_INTERVAL = 5000;

export const CalculatingLearningPathPage = () => {
  const links = useMetaLinks();
  const { t } = useTranslation("translations");
  const [numberOfRetries, setNumberOfRetries] = useState(MAX_RETRIES);
  const [waitingTooLong, setWaitingTooLong] = React.useState(false);

  const onSuccess = (response: CategoryProps[]) => {
    const isCaclulated = response.length > 0;

    if (numberOfRetries === 0 && !isCaclulated) {
      setWaitingTooLong(true);
    }

    if (!isCaclulated && numberOfRetries > 0) {
      setTimeout(() => {
        setNumberOfRetries(numberOfRetries - 1);
      }, RETRY_INTERVAL);
    }
  };

  const onError = () => {
    if (numberOfRetries > 0) {
      setTimeout(() => {
        setNumberOfRetries(numberOfRetries - 1);
      }, RETRY_INTERVAL);
    }
  };

  const { refetch } = useElearningApi(onSuccess, onError, numberOfRetries > 0);

  const onRetry = () => {
    setNumberOfRetries(MAX_RETRIES);
    setWaitingTooLong(false);
  };

  const onGoToSupport = () => {
    window.location.href = links.support;
  };

  useEffect(() => {
    refetch();
  }, [numberOfRetries]);

  return (
    <div className={styles.content}>
      {!waitingTooLong && (
        <div>
          <Title>{t("calculatingLearningPath_calculating_title")}</Title>
          <Text>{t("calculatingLearningPath_calculating_description")}</Text>
          <div className={styles.content}>
            <Loading />
          </div>
        </div>
      )}
      {waitingTooLong && (
        <div>
          <Title>{t("calculatingLearningPath_error_title")}</Title>
          <Text>{t("calculatingLearningPath_error_description")}</Text>
          <br />
          <div className={styles.content}>
            <div className={styles.contentButton}>
              <Button
                aria-label={t("calculatingLearningPath_error_button_tryAgain")}
                onClick={onRetry}
              >
                {t("calculatingLearningPath_error_button_tryAgain")}
              </Button>
              <Button
                aria-label={t(
                  "calculatingLearningPath_error_button_ContactSupport"
                )}
                variant="secondary"
                onClick={onGoToSupport}
              >
                {t("calculatingLearningPath_error_button_ContactSupport")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
