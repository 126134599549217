import React from "react";
import {
  isSurveyPassed,
} from "flamingo-e-learning-platform/awareness-assessment/utils/survey-utils";
import { PersonalizedModule } from "flamingo-e-learning-platform/training/types/module.type";
import { SurveySummaryResult } from "flamingo-e-learning-platform/awareness-assessment/types";
import { AwarenessAssessemntFailed } from "./awareness-aseessement-failed";
import { AwarenessAssessemntPassed } from "./awareness-assessment-passed";

interface AssessmentFeedbackProps {
  result: Array<SurveySummaryResult>;
  module: PersonalizedModule;
}

export const AwarenessAssessmentFeedback = ({
  result,
  module,
}: AssessmentFeedbackProps) => {
  const passed = isSurveyPassed(result);

  return (
    <React.Fragment>
      {passed && (
        <AwarenessAssessemntPassed module={module} />
      )}
      {!passed && <AwarenessAssessemntFailed module={module} result={result} />}
    </React.Fragment>
  );
};
