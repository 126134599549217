/**
 * gets a formatted users name (slices name with too many chars)
 *
 * @param {Object} user user-Object with firstname and/or lastame fields
 * @param {Number} maxDisplayLength max length of the name, before we cut it off and append `'''`
 * @returns {String} display name
 *
 * @category shared
 * @subcategory helpers
 * @exports getDisplayName
 */
export default function getDisplayName(user, maxDisplayLength = 45) {
  let displayName = `${user?.firstname || ''} ${user?.lastname || ''}`;
  if (displayName.length > maxDisplayLength) displayName = `${displayName.slice(0, maxDisplayLength - 1)}...`;
  return displayName.trim();
}
