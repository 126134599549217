import { KEYS } from 'shared/utilities/constants';

/**
 * triggers a class change on the target element, depending on if the user interacts with mouse or keyboard
 *
 * @param {Element} target HTML Element
 * @param {String} className name of the class to be added on keyboard interaction
 *
 * @category shared
 * @subcategory helpers
 * @exports focusA11yHelper
 */
export default function focusA11yHelper(target, className) {
  const classList = target?.classList;
  if (!classList) return;

  const clickHandler = (e) => {
    if (classList.contains(className) && ((e.pointerType === undefined && e.detail === 1) || !!e.pointerType)) {
      classList.remove(className);
    }
  };

  const tabHandler = (key) => {
    if (!classList.contains(className) && Object.values(KEYS).includes(key)) {
      classList.add(className);
    }
  };
  target.addEventListener('click', (e) => { clickHandler(e); });
  target.addEventListener('keydown', (e) => { tabHandler(e.which); });
}
