import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Popover, Box, Text } from '@sosafe-platform-engineering/fe-lib-ui-mantine-react';

interface PersistentTooltipProps {
  children: ReactNode,
  label: string;
}
const PersistentTooltip = (props: PersistentTooltipProps) => {
  const {children, label} = props;
  const [opened, setOpened] = useState(false);

  const close = () => {
    setOpened(false);
  }
  const open = () => {
    setOpened(true);
  }

  // Use useCallback to ensure the handleKeyDownEvent function has a stable reference
  const handleKeyDownEvent = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      close();
    }
  }, []);

  useEffect(() => {
    if (opened) {
      document.addEventListener('keydown', handleKeyDownEvent);
    }

    // Cleanup: remove the event listener when the component is unmounted or when 'opened' changes
    return () => {
      document.removeEventListener('keydown', handleKeyDownEvent);
    };
  }, [opened, handleKeyDownEvent]);

  return (
    <Box onMouseEnter={open} onMouseLeave={close} onFocus={open} onBlur={close}>
       <Popover
        opened={opened}
        position='top'
        offset={0}
        >
          <Popover.Target>
            {children}
          </Popover.Target>
          <Popover.Dropdown bg="var(--tooltip-bg, var(--mantine-color-gray-9))" p="xs md">
            <Text c="var(--mantine-color-white)" size='sm'>
              {label}
            </Text>
          </Popover.Dropdown>
      </Popover>
    </Box>

  );
};

export default PersistentTooltip;
