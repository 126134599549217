/**
 * @deprecated since survey-hub implementation
 */
import { AxiosResponse } from 'axios';
import { getSurveyReturnProps, queryClient, queryKey } from '../sosafe-connect';

export const emptySurveyQueryResponse = () => {
  queryClient.setQueryData<AxiosResponse<getSurveyReturnProps>>([queryKey.SURVEY], (currentResponse) => {
    if (!currentResponse) return currentResponse;
    return {
      ...currentResponse,
      data: {
        reference: currentResponse.data.reference,
        result: [],
      },
    };
  });
};
