/**
 * @deprecated since survey-hub implementation
 */
import React from "react";
import { Button } from "@sosafe-platform-engineering/fe-lib-ui-react";
import { useTranslation } from "react-i18next";
import { emptySurveyQueryResponse } from "../utils";

export const SurveySuccess = () => {
  const { t } = useTranslation("translations");

  const onClose = () => {
    emptySurveyQueryResponse();
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row">
        <h3 className="mb-2 h2">{t("Thank you!")}</h3>
      </div>

      <div className="d-flex flex-column">
        <p>{t("Your details have been saved.")}</p>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <Button
          ariaLabel={t("Close")}
          className="ml-auto py-1"
          onClick={onClose}
        >
          {t("Close")}
        </Button>
      </div>
    </div>
  );
};
