import React, { useState, useEffect, useRef } from "react";

// SurveyJS imports
import { SurveyModel, Question } from "survey-core";
import { Survey as SurveyJS } from "survey-react-ui";
import "./surveyjs.override.css";
import "survey-core/survey.i18n";

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  Spinner,
  clsx,
} from "@sosafe-platform-engineering/fe-lib-ui-react";
import {
  Button,
  Progress,
  Text,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { debounce } from "lodash";

import useUser from "shared/hooks/use-user";
import { checkReference } from "shared/utilities/reference-handling";
import { useQuery } from "@tanstack/react-query";
import { queryKey, useSoSafeConnect } from "shared/modules/sosafe-connect";
import { parseCodeLanguageToISO6391 } from "shared/utilities/languages";
import useInitTranslations from "lang/use-init-translations.hook";
import { useTranslation } from "react-i18next";
import { usePostSurveyAnswers } from "survey-hub/hooks/use-post-survey-answer";
import { customCssSurvey } from "flamingo-e-learning-platform/awareness-assessment/utils/survey-utils";
import { SURVEY_STEP } from "../../constants";
import { registerSoSafeRadioButton } from "../survey-custom-components/radio-button/radio-button";
import { customSoSafeItemComponentMap } from "../survey-custom-components";
import translations from "../../lang";
import styles from "./survey.module.css";
import "./survey-sosafe-default.css";

type SurveyDefaults = {
  showPreviewBeforeComplete: SurveyModel["showPreviewBeforeComplete"];
  showCompletedPage: SurveyModel["showCompletedPage"];
  questionsOnPageMode: SurveyModel["questionsOnPageMode"];
  showQuestionNumbers: SurveyModel["showQuestionNumbers"];
  showProgressBar: SurveyModel["showProgressBar"];
  showNavigationButtons: SurveyModel["showNavigationButtons"];
};

// Default properties that we support so the survey functions how UX wants.
const SURVEY_DEFAULTS: SurveyDefaults = {
  showPreviewBeforeComplete: "showAnsweredQuestions",
  showCompletedPage: false,
  questionsOnPageMode: "questionPerPage",
  showQuestionNumbers: "off",
  showProgressBar: "off",
  showNavigationButtons: false,
};

const customCss = {
  body: "custom-survey-body",
  page: {
    root: "sosafe-survey-hub-page",
  },
};

/**
 * @category Survey
 * @exports Survey
 * @component
 */
export default function Survey({ surveyStep, setSurveyStep, surveyType }) {
  // Register custom input field

  const { user } = useUser({});
  const { endPoints } = useSoSafeConnect();

  const { translationsReady } = useInitTranslations(translations, "survey_hub");
  const { t } = useTranslation("survey_hub");

  const [survey, setSurvey] = useState<SurveyModel | undefined>();
  const [pageNo, setPageNo] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState<string[]>([]);
  const [currentPageAnswered, setCurrentPageAnswered] = useState(false);
  const [isFormValidOnCurrentPage, setIsFormValidOnCurrentPage] =
    useState(false);
  const isLastPage =
    survey && survey.currentPageNo === survey.visiblePages.length - 1;

  useEffect(() => {
    registerSoSafeRadioButton({
      currentPageNb: pageNo,
      totalPagesCount: survey?.PageCount ?? 0,
    });
  }, []);

  const prevPage = () => {
    survey?.prevPage();
  };
  const nextPage = () => {
    survey?.nextPage();
  };
  const showPreview = () => {
    survey?.showPreview();
  };
  const completeSurvey = () => {
    survey?.completeLastPage();
  };

  const stepIndicatorClassName =
    surveyStep === SURVEY_STEP.PREVIEW
      ? styles.surveyProgress__disabled
      : styles.surveyProgress;

  let surveyCompleted = false;

  const { data: surveyResult } = useQuery(
    [queryKey.SURVEY_HUB],
    () => endPoints.surveyHubApi.fetch.getActiveSurvey(surveyType),
    {
      enabled: !survey,
      onSuccess: (response) => {
        const { surveyData } = response.data;

        if (surveyData) {
          surveyData.locale = parseCodeLanguageToISO6391(
            user?.language ?? "en"
          );

          // For each question, check if we have a custom component to use instead of SurveyJS default component
          for (const page of surveyData.pages) {
            for (const element of page.elements) {
              element.itemComponent =
                customSoSafeItemComponentMap[element.type];
            }
          }

          const surveyObject = { ...surveyData, ...SURVEY_DEFAULTS };
          const surveyModel = new SurveyModel(surveyObject);
          surveyModel.css = { ...customCssSurvey("survey-hub"), ...customCss };
          setSurvey(surveyModel);
        }
      },
      onError: (err: any) =>
        checkReference(
          err?.response?.data || {
            title: err.name,
            status: err.statusCode,
            reference: "get_error_reference_local",
          }
        ),
    }
  );

  const postSurveyResults = usePostSurveyAnswers({
    onSucess: () => setSurveyStep(SURVEY_STEP.COMPLETED),
    onError: (err: any) => {
      checkReference(
        err?.response?.data || {
          title: err.name,
          status: err.statusCode,
          reference: "get_error_reference_local",
        }
      );
    },
  });

  const validateForm = () => {
    const questions: Question[] = [...(survey?.currentPage.questions || [])];
    const isValid = questions.every(
      (question: Question) => question.isRequired && question.value
    );
    setIsFormValidOnCurrentPage(isValid);
  };

  // Everytime a page in surveyjs is changed
  const onCurrentPageChanged = (newSurvey: {
    state: string;
    currentPageNo: number;
    currentPage: { questions: Question[] };
  }) => {
    if (!surveyCompleted) {
      // Only allow completion of survey on the preview page
      setSurveyStep(
        newSurvey.state === "preview"
          ? SURVEY_STEP.PREVIEW
          : SURVEY_STEP.IN_PROGRESS
      );
      setPageNo(newSurvey.currentPageNo);
      registerSoSafeRadioButton({
        currentPageNb: newSurvey.currentPageNo,
        totalPagesCount: survey?.PageCount ?? 0,
      });
      const currentPageQuestions = newSurvey.currentPage.questions.map(
        (question: Question) => question.name
      );
      setCurrentPageAnswered(
        currentPageQuestions.every((questionId) =>
          answeredQuestions.includes(questionId)
        )
      );

      validateForm();
    }
  };

  const goToTheNextPage = debounce(nextPage, 700);

  // Any question value changed
  const onValueChanged = (sender: any, options: { name: string }) => {
    const questionId: string = options.name;
    if (!answeredQuestions.includes(questionId)) {
      setAnsweredQuestions([...answeredQuestions, questionId]);
    }
    validateForm();
    goToTheNextPage();
  };

  const onComplete = () => {
    surveyCompleted = true;
    if (user?.id && surveyResult?.data.id && survey?.data) {
      postSurveyResults.mutate({
        userId: user?.id,
        surveyId: surveyResult?.data.id,
        answers: survey?.data,
      });
    }
  };

  let wrapperClasses = styles.surveyJSWrapper;

  if (currentPageAnswered || isLastPage) {
    wrapperClasses += ` ${styles.animationFinished}`;
  }

  if (isFormValidOnCurrentPage) {
    wrapperClasses += ` ${styles.bodyAnimatedUp}`;
  }

  const surveyContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const container = surveyContainerRef.current;
    if (container) {
      const actionBars = container.querySelectorAll('.sd-action-bar');
      actionBars.forEach(element => element.remove());
    }
  }, [surveyStep]);

  return (
    <div className={styles.surveyContainer}>
      {!survey || postSurveyResults.isLoading || !translationsReady ? (
        <div className={styles.loading}>
          <Spinner size="lg" />
        </div>
      ) : (
        <>
          <div className={styles.surveyContainer_content}>
            {surveyStep !== SURVEY_STEP.PREVIEW && (
              <div className={styles.surveyProgress_container}>
                <Progress
                  color="teal"
                  role="progressbar"
                  aria-valuetext={`${t("step")} ${pageNo + 1} ${t("out_of")} ${survey.pageCount}`}
                  aria-valuemin={0}
                  aria-valuemax={survey.pageCount}
                  aria-valuenow={pageNo}
                  className={stepIndicatorClassName}
                  value={Math.floor(((pageNo + 1) / survey.pageCount) * 100)}
                  size="lg"
                />
                <label aria-hidden="true">
                  <Text size="xs" mt={0} c="gray.7">
                    <span className="sr-only">{t("step")}</span>
                    <strong>{pageNo + 1}</strong> {t("out_of")}{" "}
                    <strong>{survey.pageCount}</strong>
                    <span className="sr-only">{t("steps")}</span>
                  </Text>
                </label>
              </div>
            )}

            <div className={wrapperClasses} ref={surveyContainerRef}>
              <SurveyJS
                model={survey}
                onCurrentPageChanged={onCurrentPageChanged}
                onValueChanged={onValueChanged}
                onComplete={onComplete}
              />
            </div>
          </div>
          <div
            className={clsx(
              styles.actionBar,
              survey.currentPageNo > 0 ? styles.bothButtons : ""
            )}
          >
            {survey.currentPageNo > 0 && (
              <Button
                size="sm"
                variant="secondary"
                aria-label={t("previous")}
                onClick={prevPage}
                className={styles.button}
              >
                <ChevronLeftIcon />
              </Button>
            )}
            {!isLastPage && (
              <Button
                size="sm"
                variant="secondary"
                disabled={!isFormValidOnCurrentPage}
                aria-label={t("next")}
                onClick={nextPage}
              >
                <ChevronRightIcon />
              </Button>
            )}
            {isLastPage && surveyStep === SURVEY_STEP.IN_PROGRESS && (
              <Button
                variant="primary"
                size="sm"
                disabled={!isFormValidOnCurrentPage}
                aria-label={t("preview")}
                onClick={showPreview}
              >
                {t("preview")}
              </Button>
            )}
            {surveyStep === SURVEY_STEP.PREVIEW && (
              <Button
                variant="primary"
                size="sm"
                aria-label={t("complete")}
                onClick={completeSurvey}
              >
                {t("complete")}
              </Button>
            )}
          </div>
        </>
      )}
    </div>
  );
}
