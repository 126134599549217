/**
 * @deprecated since survey-hub implementation
 */
import React, { useMemo } from "react";
import { Button } from "@sosafe-platform-engineering/fe-lib-ui-react";
import { useTranslation } from "react-i18next";
import { useSurvey } from "shared/hooks/use-survey";
import { ParsedSurvey } from "../types";
import { emptySurveyQueryResponse } from "../utils";

interface SurveyStartProps {
  survey: ParsedSurvey;
  onNext(): void;
}

export const SurveyStart = ({ survey, onNext }: SurveyStartProps) => {
  const { t } = useTranslation("translations");
  const { name, description, mandatory } = survey;
  const { saveSurvey } = useSurvey({
    survey: survey.id,
    onSuccess: () => {
      /** */
    },
    onError: () => {
      /** */
    },
  });

  const translatedDescription = useMemo(() => mandatory
      ? t("Your employer has marked this questionnaire as mandatory.")
      : t("Your employer has marked this questionnaire as optional."), [mandatory]);

  const onCancel = () => {
    saveSurvey({});
    emptySurveyQueryResponse();
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row">
        <h3 className="mb-2 h2">{name}</h3>
      </div>

      <div className="d-flex flex-column">
        <p>
          {description ||
            t("Please take a few moments to answer the following questions.")}
        </p>
        <p className="u-textMedium">{translatedDescription}</p>
      </div>
      <div className="d-flex flex-row justify-content-between">
        {!mandatory && (
          <Button.Outline
            ariaLabel={t("Cancel")}
            onClick={onCancel}
            className="py-1"
          >
            {t("Cancel")}
          </Button.Outline>
        )}

        <div />

        <Button ariaLabel={t("Start")} onClick={onNext} className="py-1">
          {t("Start")}
        </Button>
      </div>
    </div>
  );
};
