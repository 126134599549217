/**
 * @deprecated since survey-hub implementation
 */
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import { SURVEY_STEP_TYPES } from "../types";
import { useSurveyContext } from "../use-survey-context";
import "./style/survey.scss";
import "./style/survey-modal.scss";
import { SurveyContentModal } from "./survey-content-modal";
import { SurveyFailed } from "./survey-failed";
import { SurveyStart } from "./survey-start";
import { SurveySuccess } from "./survey-success";

export const Survey = () => {
  const { currentSurveyStep, currentSurvey, setCurrentSurveyStep } =
    useSurveyContext();
  const { i18n } = useTranslation("translations");
  const nodeRef = useRef(null);
  const show = true;
  const arab = `${i18n.language === "ar" ? "arab" : ""}`;

  return (
    <CSSTransition
      in={true}
      nodeRef={nodeRef}
      timeout={300}
      classNames={{
        appear: "fade",
      }}
      unmountOnExit
    >
      <div className="w-100 h-100 position-fixed survey-overlay">
        <div
          className={`card shadow survey-tooltip ${
            show ? "show" : "hide"
          } ${arab}`}
          role="dialog"
        >
          <div ref={nodeRef}>
            {currentSurveyStep === SURVEY_STEP_TYPES.START && currentSurvey && (
              <SurveyStart
                survey={currentSurvey}
                onNext={() => setCurrentSurveyStep(SURVEY_STEP_TYPES.STEP)}
              />
            )}

            {currentSurveyStep === SURVEY_STEP_TYPES.STEP && currentSurvey && (
              <SurveyContentModal
                survey={currentSurvey}
                onSubmitSuccess={() =>
                  setCurrentSurveyStep(SURVEY_STEP_TYPES.SUCCESS)
                }
                onSubmitError={() =>
                  setCurrentSurveyStep(SURVEY_STEP_TYPES.FAILED)
                }
              />
            )}

            {currentSurveyStep === SURVEY_STEP_TYPES.SUCCESS && (
              <SurveySuccess />
            )}

            {currentSurveyStep === SURVEY_STEP_TYPES.FAILED && <SurveyFailed />}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
