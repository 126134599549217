import Form from 'react-bootstrap/Form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from 'shared/components/custom-button';
import Rating from 'shared/components/rating';
import SvgIcon from 'shared/svgs/helper/svg-icon';
import { Close } from 'shared/svgs';
import { feedbackSwal } from './use-feedback';

export function FeedbackForm(props) {
  const { stars, language } = props;
  const { i18n, t } = useTranslation(['translations', 'placeholderTranslations']);
  const showSurvey = language === 'en' || language === 'de';
  return (
    <Form className="text-dark" onSubmit={() => props.sendFeedback()}>
      <CustomButton
        className="modal-close-x stroke-black"
        variant="link"
        label={<SvgIcon Icon={Close} className="" />}
        onClick={() => feedbackSwal.close()}
      />
      <span className="h3">{t('Your feedback is important to us.')}</span>

      <Form.Group>
        <Form.Label className="mt-3 mb-3" htmlFor="feedback_rating">
          {t('Rate our learning modules')}:
        </Form.Label>
        <div className="d-flex flex-row justify-content-center">
          <Rating className="" rate={stars} setRate={props.setStars} />
        </div>
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor="feedback_text">
          {t('Your message to us')}:<span className="fs-80 ml-2 u-textMedium">*</span>
        </Form.Label>
        <Form.Control
          id="feedback_text"
          as="textarea"
          className="form-control"
          placeholder=""
          autoComplete="off"
          onChange={(e) => props.onChange({ message: e.target.value })}
        />
      </Form.Group>
      <Form.Group className="feedback mb-1">
        <Form.Label className="mb-2" htmlFor="email">
          {t('Your email')}:<span className="ml-2 u-textMedium">*</span>
        </Form.Label>
        <br />
        <div className="mb-3">
          <span className="u-textMedium">
            {t(
              'Your feedback will be completely anonymous. Enter your email if you would like us to contact you.'
            )}
          </span>
        </div>
        <Form.Control
          id="email"
          type="email"
          className="form-control mt-3"
          placeholder={t('placeholderTranslations:Email')}
          onChange={(e) => props.onChange({ email: e.target.value })}
        />
      </Form.Group>
      <div className="d-flex mb-1">
        <small className="text-left mb-3">{t('* Optional')}</small>
      </div>
      <div className="feedback d-flex">
        <CustomButton
          className="feedback-btn w-100"
          variant="outline-secondary"
          label={t('Cancel')}
          onClick={() => feedbackSwal.close()}
        />
        <CustomButton
          className={`feedback-btn w-100 ${i18n.language === 'ar' ? 'mr-1' : 'ml-1'}`}
          label={t('Send')}
          disabled={!stars}
          isSubmitButton
        />
      </div>
    </Form>
  );
}
