const references = {
  shouldLogout: [
    '8F06CB8F-8050-44A7-A1F9-12CA2190D6A3', // campaign not found
    '26D5B901-332F-46E0-958D-A3C9C88A3C51', // no campaign found
    'BE19B0EE-46C6-4DF6-A03F-7910EC6AD48F', // login elearning not activate
    '060B6065-F24F-4E56-B4DA-CE1D9E6A1272', // login elearning not activate 2
    '618D8B25-E86C-4ED2-AE64-EE7253938D65', // campaign not found
    '7011998F-3372-42A0-BB77-EB248DE8C2BC', // no campaign found when posting e-learning
    '0F12ACC5-0F6E-4905-974D-F254218B523E', // API token invalid
  ],
};

export const ReferenceAction = {
  shouldLogout: (reference: string) => references.shouldLogout.includes(reference),
};
