import { UsePersonalizedModule } from "flamingo-e-learning-platform/training/hooks/use-personalized-module";
import { PersonalizedModule } from "flamingo-e-learning-platform/training/types/module.type";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { welcomeMessageUtils } from ".";

const DefaultMessage = () => {
  const { t } = useTranslation("flamingo");
  return <p>{t("welcome_description")}</p>;
};


/**
 * @description This function provides the utils method responsible for calculating and
 * return the right messages depending on the learner status.
 * the order of the utils messages represents priority, the 0 highest priority
 * All utils method must return a component/ ()=> React.ReactElement
 */
const WelcomeCardMessageProvider = (
  personalizedModules: PersonalizedModule[],
  date: string
) => [
    // add here more utils to get the message
    welcomeMessageUtils.getOverDueMessage(personalizedModules, date),
  ];

/**
 * @description Go through the list of utils methods and call them to get the right message
 * or null, if null is returned by the method, means no message to be shown for
 * that method.
 */
const getWelcomeCardMessageComponent = (
  personalizedModules: PersonalizedModule[]
) => {
  const result = WelcomeCardMessageProvider(
    personalizedModules,
    new Date().toISOString()
  ).find((messageComponent) => messageComponent !== null);

  return result;
};

export const UseWelcomeCardMessage = () => {

  const { personalizedModules, isLoading } = UsePersonalizedModule();
  const [MessageComponent, setMessageComponent] =
    useState<() => React.ReactElement>();


  useEffect(() => {
    if (isLoading) return;

    const selectedMessageComponent =
      getWelcomeCardMessageComponent(personalizedModules);

    if (selectedMessageComponent) {
      setMessageComponent(() => selectedMessageComponent);
      return;
    }

    setMessageComponent(() => DefaultMessage);
  }, [personalizedModules, isLoading]);

  return MessageComponent;
}
