/*
 * Formats date to eg: 15 Dec 2023
 * {day of the month} ${three first letters of the month}
 */
export const getDayAndMonthYearString = (stringDate: string | null, language?: string) => {
  if (stringDate && language) {
    const day = new Date(stringDate).toLocaleDateString(language, {
      day: 'numeric',
    });
    const month = new Date(stringDate)
      .toLocaleDateString(language, { month: 'short' })
      .replace(/\./g, '');
    const year = new Date(stringDate).toLocaleString(language, {
      year: 'numeric',
    });
    return `${day} ${month} ${year}`;
  }
  return '';
};
