import { useSoSafeMutation } from 'shared/hooks/use-sosafe-mutation';
import { queryKey, useSoSafeConnect } from 'shared/modules/sosafe-connect';
import ActionTypes from 'shared/utilities/action-types';

interface useSendModuleFeedbackProps {
  onSuccess(): void
}

export const useSendModuleFeedback = ({ onSuccess }: useSendModuleFeedbackProps) => {
  const { endPoints } = useSoSafeConnect();

  return useSoSafeMutation({
    key: queryKey.MODULE_FEEDBACK,
    requestMethod: endPoints.post.sendModuleFeedback,
    requestType: ActionTypes.SEND_MODULE_FEEDBACK_REQUEST,
    successType: ActionTypes.SEND_MODULE_FEEDBACK_SUCCESS,
    failedType: ActionTypes.SEND_MODULE_FEEDBACK_FAILED,
    onSuccess,
  });
};
