import React from "react";
import PersistentTooltip from "shared/components/persistent-tooltip/persistent-tooltip";
import style from "./badge.module.css";

interface BadgeProps {
  url: string;
  toolTipMsg: string;
  onClick: () => void;
  alt: string;
}

export const Badge = ({ url, toolTipMsg, onClick, alt}: BadgeProps) => (
  <PersistentTooltip label={toolTipMsg}>
    <button className={style.badge} onClick={onClick} aria-label={alt}>
      <img alt={alt} src={url} />
    </button>
  </PersistentTooltip>
);
