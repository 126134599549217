import React from 'react';
import { Card } from '@sosafe-platform-engineering/fe-lib-ui-react';

import './policies-card.component.css';

interface PoliciesCardProps {
  children: React.ReactNode;
}

export default function PoliciesCard(props: PoliciesCardProps) {
  const { children } = props;
  return (
        <div className='sosafe-PoliciesCard-wrapper' >
            <Card className='sosafe-PoliciesCard' >
                <Card.Body className='u-flex u-flexCol u-sizeFull u-flexAlignItemsCenter'>
                    {children}
                </Card.Body>
            </Card>
        </div>
  );
}
