import React, { useMemo } from "react";
import { matchPath, Redirect, useLocation } from "react-router";
import { IRouterConfig } from "./router-type";

interface Props {
  currentRouterController: IRouterConfig;
}

/*
 * Adds a fallback not supported urls
 * for now we redirect the user to the default route
 * but we could also add some pretty component here
 * */
export const Router404 = ({ currentRouterController }: Props) => {
  const location = useLocation();

  const isPathAvailable = useMemo(
    () =>
      !!currentRouterController.routes.find((route) =>
        matchPath(location.pathname, { path: route.path, exact: true })
      ),
    [currentRouterController.routes, location.pathname]
  );

  if (isPathAvailable) return null;

  return <Redirect to={currentRouterController.defaultRoute} />;
};
