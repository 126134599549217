import { useQuery } from "@tanstack/react-query";
import { queryKey, useSoSafeConnect } from "shared/modules/sosafe-connect";
import { UseReferenceHandler } from "shared/utilities/use-reference-handler";

interface UseGetLearningPathProps {
  enabled?: boolean;
}

/**
 * Fetch the survey result of survey the user has answered
 */
export const useShouldGetLearningPath = ({
  enabled,
}: UseGetLearningPathProps) => {
  const { endPoints, apiKey } = useSoSafeConnect();
  const checkReference = UseReferenceHandler();
  return useQuery(
    [queryKey.ELEARNING],
    () => endPoints.fetch.getElearningApi(),
    {
      enabled: enabled && !!apiKey,
      retry: false,
      staleTime: Infinity,
      onError: (err: {
        response: { data: any };
        name: any;
        statusCode: any;
      }) => {
        checkReference(
          err?.response?.data || {
            title: err.name,
            status: err.statusCode,
            reference: "get_error_reference_local",
          }
        );
      },
    }
  );
};
