import { MODULE_STATUS } from "flamingo-e-learning-platform/training/types/module-status.type";

type RequiredProps = { status: string, id: number };

export const sortCompleteModuleToLast = <T extends RequiredProps>(modules: T[]) =>
  modules.sort((modA, modB) => {
    if (modA.status !== MODULE_STATUS.RESTART && modB.status === MODULE_STATUS.RESTART) return -1;
    if (modA.status === MODULE_STATUS.RESTART && modB.status !== MODULE_STATUS.RESTART) return 1;
    return 0;
  });
