import React from "react";
import useUser from "shared/hooks/use-user";
import { LevelProgressContextProps } from "./level-progress.types";

export const LevelProgressContext =
  React.createContext<LevelProgressContextProps>({ xp: 0, loading: true });

type LevelProgressProviderProps = {
  children: React.ReactNode;
};

export const LevelProgressProvider: React.FunctionComponent<
  LevelProgressProviderProps
> = ({ children }: LevelProgressProviderProps) => {
  const { user, loading } = useUser({ enabled: false });

  return (
    <LevelProgressContext.Provider
      value={{
        loading,
        prevLevel: user?.game.progress.lastLevel,
        currentLevel: user?.game.progress.level,
        nextLevel: user?.game.progress.nextLevel,
        xp: user?.game.progress.xp || 0,
      }}
    >
      {children}
    </LevelProgressContext.Provider>
  );
};
