export const BADGE_VIEWS = {
  START_PAGE: {
    LEVEL_CARD: "start page - level card",
  },

  ACHIEVEMENTS_PAGE: {
    ACTIVITY_PANEL: "achievement page - activity panel",
    BADGE_PANEL: "achievement page - badge details",
  },
};

export const ACHIEVEMENT_PAGE_OPENED_FROM = {
  START_PAGE: {
    BADGE_DETAILS: "start page - badge details",
    LEVEL_DETAILS: "start page - level details",
    OVERVIEW_OF_RESULTS: "start page - overview of results",
    HEADER_MENU: "start page - header menu",
    WELCOME_CARD: "start page - welcome card",
  },
  DIRECT_ACCESS: "direct access",
};

export const MODULE_START_TYPE = {
  STARTED: "started",
  CONTINUED: "continued",
  RESTARTED: "restarted",
};

export const MODULE_OPENED_FROM = {
  DIRECT_ACCESS: "direct access",
  QUICKSTART_CARD: "quickstart card",
  CATEGORY_CARD: "category card",
  MODULE_CARD: "module card",
  NEXT_MODULE_POP_UP: "next module pop up",
  ACHIEVEMENT_PROGRESS_CARD: "achievement progress card",
  ACHIEVEMENT_ACTIVITIES_CARD: "achievement activities card",
};

export enum TRACKED_EVENTS {
  BADGE_OPENED = "ELearningPlatformBadgeOpened",
  ACHIEVEMENT_PAGE_OPENED = "ELearningPlatformAchievementPageOpened",
  SINGLE_CATEGORY_VIEW_OPENED = "ELearningPlatformSingleCategoryViewOpened",
  MODULE_OPENED = "ELearningPlatformModuleOpened",
  MODULE_COMPLETION_STATUS = "ELearningPlatformModuleCompletionStatus",
  MODULE_COMPLETION_PASSED = "ELearningModulePassed", // only used for chameleon
  MODULE_COMPLETION_FAILED = "ELearningModuleFailed", // only used for chameleon
  SURVEY_COMPLETED = "SurveyCompleted", // only used for chameleon to activate the survey for the new users
  START_PAGE_VIEWED = "ELearningPlatformStartPageViewed",
  CERTIFICATE_DOWNLOAD = "ELearningPlatformCertificateDownload",
}
