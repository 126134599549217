
export const ERROR_CODES = [
  // Login
  {
    id: 'LOGIN_ACCOUNT_ALREADY_EXISTS',
    reference: '8F8E18C2-C4E2-4E24-B980-A532C32F031E',
    message: 'messageTranslations:This email address has already been assigned to a user account.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'LOGIN_ACCOUNT_EXPIRED',
    reference: 'F23C2072-C14B-4253-A6F0-DD23B160B82B',
    message: 'messageTranslations:The requested account is expired.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'LOGIN_ACCOUNT_NOT_EXIST',
    reference: 'A79E2011-9C70-41CE-A221-9C0022F7BE1E',
    message: 'messageTranslations:The requested account does not exist.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'LOGIN_ACTIVATE_NOT_EXIST',
    reference: '921BE1AB-BDDB-4CD6-A727-35751B453A93',
    message: 'messageTranslations:Your account has not been activated yet.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'LOGIN_BAD_REQUEST',
    reference: '5C284FD6-08B1-4A51-887D-E4E3A7E4B5DB',
    message: 'messageTranslations:E-Mail invalid',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'LOGIN_CAMPAIGN_NOT_FOUND',
    reference: '58C0C9F9-A738-498B-AAE5-273BEAA0978C',
    message: 'messageTranslations:The E-Learning is not active for your company.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'LOGIN_ELEARNING_NOT_ACTIVATED_2',
    reference: '060B6065-F24F-4E56-B4DA-CE1D9E6A1272',
    message: 'messageTranslations:E-Learning not activated.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'LOGIN_ELEARNING_NOT_ACTIVATED',
    reference: 'BE19B0EE-46C6-4DF6-A03F-7910EC6AD48F',
    message: 'messageTranslations:E-Learning not activated.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'NO_CAMPAIGN_FOUND',
    reference: '26D5B901-332F-46E0-958D-A3C9C88A3C51',
    message: 'messageTranslations:E-Learning not activated.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'CAMPAIGN_NOT_FOUND',
    reference: '8F06CB8F-8050-44A7-A1F9-12CA2190D6A3',
    message: 'messageTranslations:E-Learning not activated.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'CAMPAIGN_NOT_FOUND_2',
    reference: '618D8B25-E86C-4ED2-AE64-EE7253938D65',
    message: 'messageTranslations:E-Learning not activated.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'CAMPAIGN_NOT_FOUND_ELEARNING_POST',
    reference: '7011998F-3372-42A0-BB77-EB248DE8C2BC',
    message: 'messageTranslations:E-Learning not activated.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'LOGIN_FAILED_SSO_1',
    reference: '50CAA3CF-7536-4153-9A6F-949D810003AF',
    message: 'messageTranslations:Microsoft token invalid',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'LOGIN_FAILED_SSO_2',
    reference: '5D7FF670-6609-4E7D-BB3F-5CA39760E301',
    message: 'messageTranslations:Google token invalid',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'LOGIN_FAILED_SSO_3',
    reference: 'AB5F4D11-4041-4B26-92A6-D2A01475B493',
    message: 'messageTranslations:Microsoft token invalid',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'LOGIN_FAILED_SSO',
    reference: '20E74D90-675D-4543-8981-4AE1411501FA',
    message: 'messageTranslations:Google token invalid',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'LOGIN_FAILED',
    reference: '5FA7C53C-0C5B-4FCD-9F01-EE278FFE235E',
    message: 'messageTranslations:Password missing',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'LOGIN_NOT_ACTIVATED',
    reference: '66A6CB8B-2250-4D1B-9B46-6F64DA19B01B',
    message: 'messageTranslations:Your account has not been activated yet.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'LOGIN_NOT_ALLOWED',
    reference: 'C4848556-C830-4B66-9873-D049B90BEEFE',
    message: 'messageTranslations:Access not allowed.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'LOGIN_PASSWORD_INVALID_2',
    reference: 'D4B74765-6E79-41BF-9FD2-FE87AFFBF07A',
    message: 'messageTranslations:Password invalid.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'LOGIN_PASSWORD_INVALID',
    reference: 'CC4FA0CB-CEB0-4D1E-880E-4ED4EE9CA298',
    message: 'messageTranslations:Password invalid.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'LOGIN_SSO_NOT_ALLOWED',
    reference: 'C18BF815-12B4-442B-8F36-CDFE93CD3CB5',
    message: 'messageTranslations:Single-Sign-On is not activated.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'LOGIN_INVALID',
    reference: '24EBFCBD-F363-4C35-BF04-B09660D2B299',
    message: 'messageTranslations:Incorrect email or password',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  // Registration
  {
    id: 'REGISTRATION_ACCOUNT_EXISTS',
    reference: '34FF4649-E908-42F1-9346-2CFCB8E3A4F3',
    message: 'messageTranslations:This email address has already been assigned to a user account.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_ADDRESS_INVALID_1',
    reference: 'E4A251D7-3DC6-4D9E-AB3A-71FFF5AF1CE9',
    message: 'messageTranslations:This address is not valid.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_ADDRESS_INVALID_2',
    reference: '35DBABBE-A573-46C8-AEDF-917533B80A5F',
    message: 'messageTranslations:This email address is invalid.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_ADDRESS_MISSING',
    reference: '540BFD4C-A5F8-44D0-9BA6-6AA187B52404',
    message: 'messageTranslations:There is no customer assigned to your account on this domain.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_ADDRESS_NOT_FOUND',
    reference: 'DE4655EB-9AB3-4C0F-ACB3-366876CFF5C0',
    message:
      'messageTranslations:This email address is not part of the list of allowed addresses for this company.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_ADDRESS_OR_CODE_MISSING_2',
    reference: 'DBCD761C-4626-49EF-9E33-9C194711D22B',
    message: 'messageTranslations:Please enter an email address or a sign up code.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_ADDRESS_OR_CODE_MISSING',
    reference: 'A188281D-5F6E-4F50-89BF-5F9BBFB05B36',
    message: 'messageTranslations:Please enter an email address or a sign up code.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_CODE_NOT_VALID_1',
    reference: 'B4290067-75D6-4592-8DC0-77071EAC1262',
    message: 'messageTranslations:This sign up code is invalid.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_CODE_NOT_VALID',
    reference: 'BCE08FB3-2CDC-4AED-8BFA-5AC2AB3326DA',
    message: 'messageTranslations:This sign up code is invalid.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_DATA_FAILED_EXISTS',
    reference: '58D14128-51FB-4DED-AEAC-5AAD0211E9B5',
    message: 'messageTranslations:This email address has already been assigned to a user account.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_ELEARNING_NOT_ACTIVE_1',
    reference: 'E5FDF86B-ED75-4449-8961-4C47310647AA',
    message: 'messageTranslations:The E-Learning is not active for your company.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_ELEARNING_NOT_ACTIVE_2',
    reference: 'BAF76426-7224-4EAB-A5AE-3E04FF65875E',
    message: 'messageTranslations:The E-Learning is not active for your company.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_ELEARNING_NOT_ACTIVE_3',
    reference: '9D88A80C-ACCD-4084-AE03-6A95D6DA0BF4',
    message: 'messageTranslations:The E-Learning is not active for your company.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_ELEARNING_NOT_ACTIVE_4',
    reference: '2D9A03E1-2EE4-4BC6-8BAE-2E5BAF7ABB5F',
    message: 'messageTranslations:The E-Learning is not active for your company.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_FAILED_SSO_1',
    reference: 'A7A1FBE7-6D36-41B8-B087-234ED3795D5D',
    message: 'messageTranslations:Google Check failed',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_FAILED_SSO_2',
    reference: 'A46CB423-C10D-4EAB-AB13-15036C877BAE',
    message: 'messageTranslations:Microsoft check failed',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_FAILED_SSO_3',
    reference: '6B8FDC49-EC29-4E77-88B2-6ED4BBDE4824',
    message: 'messageTranslations:Microsoft check failed',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_FAILED_SSO_NOT_ACTIVE',
    reference: '151A0746-232C-4EF8-BAA2-43799A419C82',
    message: 'messageTranslations:Single-Sign-On is not activated.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_FAILED_SSO',
    reference: 'A41E4B6B-A8B1-4022-AD73-3B2113D308FA',
    message: 'messageTranslations:Google check failed',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_LOGIN_EXISTS_NO_PRODUCT',
    reference: '9059B863-C2CA-4601-97CB-24A6DA6F5A04',
    message: 'messageTranslations:Your account is not assigned to a product.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_LOGIN_EXISTS_NOT_ACTIVE_1',
    reference: 'D0500A09-F51D-4CF4-872C-0657C7490B75',
    message: 'messageTranslations:This email address has already been assigned to a user account.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_LOGIN_EXISTS_NOT_ACTIVE_2',
    reference: '897BDC3D-CAB2-4440-9945-8CA2A0EBAE29',
    message: 'messageTranslations:Your account has not been activated yet.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_LOGIN_EXISTS_NOT_ACTIVE_3',
    reference: '12D4A6A6-3DE2-46D0-AF78-C5466C7367F8',
    message: 'messageTranslations:Your account has not been activated yet.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_NOT_ALLOWED',
    reference: '20E9C4C1-C4A2-41A1-8981-0F2399B222A8',
    message: 'messageTranslations:Invalid code',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_PASSWORD_BROKEN',
    reference: '516A4ABB-8830-46BF-A1A5-7AB190173E7F',
    message:
      'messageTranslations:There is an issue with your current password. Please reset your password and log in again.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_PASSWORD_WEAK',
    reference: 'FA7EF7C4-985D-4FD5-9038-22E0A3C98547',
    message: 'messageTranslations:Your password does not meet the minimum security requirements.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_RESELLER_CODE_NOT_VALID',
    reference: '9978919A-A1EE-4E0F-9B59-B862FD51EAE0',
    message: 'messageTranslations:The Reseller Code you entred is not valid.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'REGISTRATION_SSO_NOT_ALLOWED',
    reference: 'C0DAEE4C-3A22-46FC-9AE1-31619411C5FA',
    message: 'messageTranslations:Single-Sign-On is not activated.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  // Password
  {
    id: 'PASSWORD_ACCOUNT_NOT_VERIFIABLE',
    reference: '95DE79E0-7067-404E-B7AB-2AAE5BD2E98C',
    message: 'messageTranslations:Incorrect email or password',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'PASSWORD_ATTEMPTS_LOCKED_OUT',
    reference: '74066AB7-93F0-4B9F-8DD5-975D26E068CA',
    message:
      'messageTranslations:The password was entered incorrectly too many times. Please try again later.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'PASSWORD_NOT_EDITABLE_1',
    reference: 'DC92073B-8C82-4347-84AD-40CD1A2E15FD',
    message: 'messageTranslations:The password cannot be changed.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'PASSWORD_NOT_EDITABLE_2',
    reference: '6281912F-0402-4B30-ABDE-E2DB27407444',
    message: 'messageTranslations:The password cannot be changed.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'PASSWORD_SET_OLD_1',
    reference: 'FBFF8B86-7B33-40C3-AC22-6749742FF977',
    message: 'messageTranslations:Please use a new password',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'PASSWORD_SET_OLD_2',
    reference: '1A84A078-47C0-4DA4-99BF-3AE4C6815FEB',
    message: 'messageTranslations:Please use a new password',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'PASSWORD_SET_OLD_3',
    reference: '5F43673C-BA4C-4820-A46B-8A3EE769F4B2',
    message: 'messageTranslations:Please use a new password',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'PASSWORD_TOKEN_INVALID',
    reference: '03BD0321-6BC3-40B9-A036-7188FA1F5DA6',
    message: 'messageTranslations:API Token invalid.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  // Validation
  {
    id: 'VALIDATION_FAILED',
    reference: 'F33F9B19-39D7-4059-88C7-9CEBD66A22B0',
    message: 'messageTranslations:Logged out',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  // ItWasntMe
  {
    id: 'ITWASNTME_FAILED',
    reference: 'A3A3D187-7E5A-437B-92D3-39FBA3752A21',
    message:
      'messageTranslations:Your link is invalid or has already been used. Please contact support.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  // Account
  {
    id: 'PASSWORD_OLD_WRONG',
    reference: '499C8EFD-00F6-4C0F-9B03-42147A39B94B',
    message: 'messageTranslations:Your old password is wrong.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  // Certificate
  {
    id: 'CERTIFICATE_BAD_REQUEST',
    reference: 'D9E06CDF-33F9-48A2-86BC-2B2CA0B8C828',
    message: 'messageTranslations:An error has occured (no int)',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'CERTIFICATE_NOT_ACTIVE',
    reference: 'E26283EF-4DFA-4538-B119-9E2A7F7F779D',
    message: 'messageTranslations:Certificate is not activated',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'CERTIFICATE_USER_NOT_FOUND',
    reference: '6D0D6546-26D1-4802-A425-6A2099C25BC6',
    message: 'messageTranslations:User not found',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'CERTIFICATE_NO_MODULE',
    reference: '5C412D74-7C37-4A95-B890-B0A813E1AA3E',
    message: "messageTranslations:You didn't pass a module yet.",
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'CATEGORIES_FETCH_FAILED',
    reference: 'B548348F-DAA4-4232-B558-82202A81EC25',
    message: 'messageTranslations:No running e-learning campaign.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'ELEARNING_NOT_ACTIVE',
    reference: '94E1AFCD-CDCD-47AF-8C84-33CD16A3B8E0',
    message: 'messageTranslations:No running e-learning campaign.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },

  {
    id: 'AUTH_TEMPORARY_INVALID',
    reference: '8595105A-E431-4D49-843D-C6AA3930FFBB',
    message: null,
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'AUTH_ROLE_ACCESS',
    reference: '2CFF5FC6-BA93-48CA-B87C-4CFAC86F9D5C',
    message: null,
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'AUTH_CUSTOMER_ACCESS',
    reference: '79917939-D48D-488A-B2CB-96F650C9FB12',
    message: null,
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'API_TOKEN_MISSING',
    reference: '250532AD-B877-47DB-B1E2-8CFED586BCBD',
    message: null,
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'API_TOKEN_INVALID_2',
    reference: 'E3A48F0F-87CC-4995-AE41-338EFD5A6B67',
    message: 'messageTranslations:API Token invalid.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  }, // Api Token is invalid

  {
    id: 'API_TOKEN_INVALID_2',
    reference: '0FD990BA-0C9E-4016-8842-F2FB7A009C31',
    message: 'messageTranslations:The password cannot be changed.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },

  {
    id: 'NO_MODULES_UNLOCKED_FOR_LANG',
    reference: '31EDE2D3-5AA3-472F-A77C-6686BA32379B',
    message: null,
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'NO_MODULES_ACTIVE_FOR_LANG',
    reference: '34C962BA-C650-4BC5-BF2A-DE24E994BBD5',
    message: 'messageTranslations:No modules active for user language',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'NO_MODULES_ACTIVE',
    reference: '44814B84-556E-484A-95FD-BB12B378FF0C',
    message: 'messageTranslations:No modules active overall',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },

  {
    id: 'BAD_REQUEST',
    reference: 'FB98F88F-3B00-4327-AC40-DC70154D43E6',
    message: 'messageTranslations:Invalid request. Expected hex',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'BAD_REQUEST_DOMAIN',
    reference: 'E7DF3F4C-2A5E-4616-B8C7-992BF38BF635',
    message: 'messageTranslations:Invalid request. Expected FQDN',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'SAML_ADDRESS_NOT_FOUND',
    reference: 'E839C003-9DED-4C52-A19A-4036EB173D24',
    message: 'messageTranslations:The requested account does not exist.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'SAML_NO_CUSTOMER',
    reference: 'A55158C9-98CD-44EB-9132-0C9CA0F8AC96',
    message: 'messageTranslations:The E-Learning is not active for your company.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'SAML_NO_CAMPAIGN',
    reference: 'B3E1E53F-D8D7-4EE9-AF8C-F994765A807E',
    message: 'messageTranslations:No running e-learning campaign.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },

  {
    id: 'NOT_ACTIVE_1',
    reference: 'E9681A8D-190C-45F9-8C8A-72FF27655D73',
    message: 'messageTranslations:E-Learning not activated.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'NOT_ACTIVE_2',
    reference: '628DCBCC-2DEF-4CB4-A84E-D5E1A8CFE84F',
    message: 'messageTranslations:E-Learning not activated.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'NOT_ACTIVE_3',
    reference: '1D5D1EB3-A21C-4574-AE63-FF0B86FEDF36',
    message: 'messageTranslations:E-Learning not activated.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  {
    id: 'NO_CUSTOMER_FOUND',
    reference: '8046FA6E-C03A-48A9-A0DA-3BBDE124142E',
    message: 'messageTranslations:The E-Learning is not active for your company.',
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  // Module feedback
  {
    id: 'SEND_MODULE_FEEDBACK_FAILED',
    reference: '62CCAE23-19EA-419C-B9EC-86758AEAE7A4',
    message: null,
    type: 'MODAL',
    title: 'messageTranslations:An error has occured',
  },
  // Too many requests - status 429
  {
    id: 'TOO_MANY_REQUESTS_ON_CLOSED_ROUTE',
    reference: '71869517-17C8-4682-B735-070A5E2C7921',
    message: 'messageTranslations:An error has occurred, please try again in a few minutes.',
  },
  {
    id: 'TOO_MANY_REQUESTS_ON_OPEN_ROUTE',
    reference: 'E03605DD-A07A-40D3-ACE2-5809C6B61EE8',
    message: 'messageTranslations:An error has occurred, please try again in a few minutes.',
  },
  {
    id: 'TOO_MANY_DOWNLOAD_REQUESTS',
    reference: 'B6AF0136-4A4B-4362-9485-B488E424B7ED',
    message: 'messageTranslations:An error has occurred, please try again in a few minutes.',
  },
  // Session expired
  {
    id: 'API_TOKEN_INVALID',
    reference: '0F12ACC5-0F6E-4905-974D-F254218B523E',
    message: 'messageTranslations:Session Expired',
  },
];
