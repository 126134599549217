import React, { useEffect, useRef } from "react";
import { PersonalizedModule } from "flamingo-e-learning-platform/training/types/module.type";
import { Button } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import confetti from "canvas-confetti";
import styles from "./awareness-assessment-feedback.module.css";

interface AssessmentPassedProps {
  module: PersonalizedModule;
}

export const AwarenessAssessemntPassed = ({
  module,
}: AssessmentPassedProps) => {
  const history = useHistory();
  const { t } = useTranslation("awareness-assessment");
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!canvasRef.current) return;
    confetti.create(canvasRef.current, {
      resize: true,
      useWorker: true,
    })({ particleCount: 200, spread: 200, });
  }, [canvasRef]);

  return (
    <div className={styles.awarenessAssessmentPassed}>
      <canvas ref={canvasRef} className={styles.canvasConfetti} ></canvas>
      <div>
        <h4>{`${t("success")}!`}</h4>
        <h4>{`${t("youSkippedTheLesson")} “${module.name}”!`}</h4>
      </div>
      <div className={styles.thumbnailContainer}>
        <img src={module.thumbnailUrl} alt="module thumbnail" />
      </div>
      <div className={styles.footer}>
        <Button
          data-testid="next-lesson-button"
          aria-label={t("nextLesson")}
          onClick={() => history.push("/")}
        >
          {t("goToTraining_button_text")}
        </Button>
      </div>
    </div>
  );
};
