/* eslint-disable no-console */
/* eslint-disable no-alert */
import React, { FC, useState } from "react";
import {
  Button,
  Notification,
  Avatar,
  Badge,
  Card,
  Text,
  Pill,
  Checkbox,
  Chip,
  Code,
  Highlight,
  Blockquote,
  FileInput,
  PasswordInput,
  ColorInput,
  ColorSwatch,
  ColorPicker,
  JsonInput,
  Select,
  NumberInput,
  NumberFormatter,
  PinInput,
  Radio,
  Rating,
  Slider,
  SegmentedControl,
  Indicator,
  Switch,
  Tooltip,
  Modal,
  Accordion,
  Alert,
  Anchor,
  Breadcrumbs,
  Burger,
  Kbd,
  List,
  Loader,
  CogsAnimation,
  Progress,
  Skeleton,
  Tabs,
  Timeline,
  Table,
  Drawer,
  Affix,
  Popover,
  Image,
  BackgroundImage,
  Grid,
  Paper,
  Stepper,
  Spoiler,
  PillsInput,
  PillGroup,
  PillsInputField,
  Tree,
  RangeSlider,
  HoverCard,
  HoverCardTarget,
  HoverCardDropdown,
  Flex,
  MultiSelect,
  Title,
  StepperStep,
  StepperCompleted,
  Pagination,
  ActionIcon,
  CloseButton,
  FileButton,
  CopyButton,
  RingProgress,
  NavLink,
  Autocomplete,
  Combobox,
  ComboboxTarget,
  InputBase,
  ComboboxChevron,
  InputPlaceholder,
  ComboboxDropdown,
  ComboboxOption,
  ComboboxOptions,
  useCombobox,
  TagsInput,
  Mark,
  Fieldset,
  TextInput,
  NativeSelect,
  Textarea,
  Collapse,
  Group,
  Box,
  Divider,
  ScrollArea,
  Dialog,
  LoadingOverlay,
  Menu,
  MenuItem,
  MenuLabel,
  MenuTarget,
  MenuDivider,
  MenuDropdown,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import {
  IconAdjustments,
  IconArrowsLeftRight,
  IconHome,
  IconMessageCircle,
  IconPhoto,
  IconSearch,
  IconSettings,
  IconTrash,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react/icons";
import { useDisclosure } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react/hooks";

type TestComponentType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: FC<any>;
  name: string;
  props: object;
}[];

const components: TestComponentType = [
  {
    Component: () => {
      const [opened, { toggle }] = useDisclosure(true);
      return (
        <Box>
          <Group justify="center" mb={5}>
            <Button onClick={toggle}>Toggle content</Button>
          </Group>
          <Collapse in={opened}>
            <Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              nec purus ut sem fermentum.
            </Text>
          </Collapse>
        </Box>
      );
    },
    name: "Collapse",
    props: {
      label: "Your name",
      placeholder: "Your name",
      description: "describe it",
    },
  },
  {
    Component: Textarea,
    name: "Textarea",
    props: {
      label: "Your name",
      placeholder: "Your name",
      description: "describe it",
    },
  },
  {
    Component: ScrollArea,
    name: "ScrollArea",
    props: {
      h: 150,
      children: (
        <>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry&apos;s standard dummy text
          ever since the 1500s, when an unknown printer took a galley of type
          and scrambled it to make a type specimen book. Lorem Ipsum is simply
          dummy text of the printing and typesetting industry. Lorem Ipsum has
          been the industry&apos;s standard dummy text ever since the 1500s,
          when an unknown printer took a galley of type and scrambled it to make
          a type specimen book.
          <br />
          <br />
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry&apos;s standard dummy text
          ever since the 1500s, when an unknown printer took a galley of type
          and scrambled it to make a type specimen book.
        </>
      ),
    },
  },
  {
    Component: Divider,
    name: "Divider",
    props: {
      my: "md",
      label: "Label in the center",
      labelPosition: "center",
      w: "100%",
    },
  },
  {
    Component: TextInput,
    name: "TextInput",
    props: {
      label: "Your name",
      placeholder: "Your name",
    },
  },
  {
    Component: Fieldset,
    name: "Fieldset",
    props: {
      legend: "Hello world",
      children: (
        <>
          <TextInput label="Your name" placeholder="Your name" />
          <TextInput label="Email" mt="md" placeholder="Email" />
        </>
      ),
    },
  },
  {
    Component: Title,
    name: "Title",
    props: {
      children: "Hello world",
    },
  },
  {
    Component: Text,
    name: "Text",
    props: {
      children: "Hello world",
    },
  },
  {
    Component: MultiSelect,
    name: "MultiSelect",
    props: {
      w: "100%",
      data: ["React", "Angular", "Vue", "Svelte"],
      label: "Your favorite libraries",
      placeholder: "Pick value",
    },
  },
  {
    Component: Autocomplete,
    name: "Autocomplete",
    props: {
      label:
        "Your favorite library (see official mantine docs for more features)",
      placeholder: "Pick value or enter anything",
      data: ["React", "Angular", "Vue", "Svelte"],
    },
  },
  {
    Component: () => {
      const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
      });

      const [value, setValue] = useState<string | null>(null);

      return (
        <Combobox
          store={combobox}
          onOptionSubmit={(val) => {
            setValue(val);
            combobox.closeDropdown();
          }}
        >
          <ComboboxTarget>
            <InputBase
              component="button"
              onClick={() => combobox.toggleDropdown()}
              pointer
              rightSection={<ComboboxChevron />}
              rightSectionPointerEvents="none"
              type="button"
            >
              {value || <InputPlaceholder>Pick value</InputPlaceholder>}
            </InputBase>
          </ComboboxTarget>
          <ComboboxDropdown>
            <ComboboxOptions>
              <ComboboxOption value="🍎 Apples">🍎 Apples</ComboboxOption>
              <ComboboxOption value="🍌 Bananas">🍌 Bananas</ComboboxOption>
              <ComboboxOption value="🥦 Broccoli">🥦 Broccoli</ComboboxOption>
              <ComboboxOption value="🥕 Carrots">🥕 Carrots</ComboboxOption>
              <ComboboxOption value="🍫 Chocolate">🍫 Chocolate</ComboboxOption>
            </ComboboxOptions>
          </ComboboxDropdown>
        </Combobox>
      );
    },
    name: "Combobox",
    props: {},
  },
  {
    Component: NavLink,
    name: "NavLink",
    props: {
      href: "#required-for-focus",
      label: "With icon",
      leftSection: <IconHome size="1rem" stroke={1.5} />,
    },
  },
  {
    Component: CogsAnimation,
    name: "CogsAnimation",
    props: {},
  },
  {
    Component: ActionIcon,
    name: "ActionIcon",
    props: {
      children: (
        <IconAdjustments style={{ width: "70%", height: "70%" }} stroke={1.5} />
      ),
    },
  },
  {
    Component: NumberFormatter,
    name: "NumberFormatter",
    props: { suffix: " EUR", value: 1000000, thousandSeparator: true },
  },
  {
    Component: Indicator,
    name: "Indicator",
    props: {
      children: (
        <Avatar
          size="lg"
          radius="sm"
          src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-1.png"
        />
      ),
      src: "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-7.png",
      h: "50%",
    },
  },
  {
    Component: Image,
    name: "Image",
    props: {
      src: "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-7.png",
      h: "100px",
    },
  },
  {
    Component: ColorSwatch,
    name: "ColorSwatch",
    props: {
      color: "rgba(234, 22, 174, 0.5)",
    },
  },
  {
    Component: Alert,
    name: "Alert",
    props: {
      children: <Flex p={"md"}>Hello world</Flex>,
      withCloseButton: true,
    },
  },
  { Component: Loader, name: "Loader", props: {} },
  {
    Component: Button,
    name: "Button",
    props: { children: "Click me", onClick: () => window.alert("Hello world") },
  },
  {
    Component: CloseButton,
    name: "CloseButton",
    props: { onClick: () => window.alert("closed") },
  },
  {
    Component: FileButton,
    name: "FileButton",
    props: {
      accept: "image/*",
      onChange: () => {},
      children: (props) => <Button {...props}>Upload image</Button>,
    },
  },
  {
    Component: CopyButton,
    name: "CopyButton",
    props: {
      value: "Copy me",
      children: (props) => <Button {...props}>Copy me</Button>,
    },
  },
  { Component: Badge, name: "Badge", props: { children: "Hello world" } },
  {
    Component: Chip,
    name: "Chip",
    props: { children: "Hello world", defaultChecked: true },
  },
  {
    Component: Notification,
    name: "Notification",
    props: { children: "Hello world" },
  },
  { Component: Avatar, name: "Avatar", props: { children: "FP" } },
  { Component: Pill, name: "Pill", props: { children: "Hello world" } },
  {
    Component: Checkbox,
    name: "Checkbox",
    props: { label: "Hello world", defaultChecked: true },
  },
  { Component: Code, name: "Code", props: { children: "Hello world" } },
  {
    Component: Highlight,
    name: "Highlight",
    props: { highlight: "hello", children: "Hello world" },
  },
  { Component: Mark, name: "Mark", props: { children: "Hello world" } },
  {
    Component: Blockquote,
    name: "Blockquote",
    props: { children: "Hello world" },
  },
  {
    Component: FileInput,
    name: "FileInput",
    props: { placeholder: "Select file", w: "100%" },
  },
  {
    Component: PasswordInput,
    name: "PasswordInput",
    props: { w: "100%" },
  },
  { Component: ColorInput, name: "ColorInput", props: {} },
  { Component: ColorPicker, name: "ColorPicker", props: {} },
  { Component: JsonInput, name: "JsonInput", props: { w: "100%" } },
  { Component: NumberInput, name: "NumberInput", props: { w: "100%" } },
  { Component: PinInput, name: "PinInput", props: {} },
  {
    Component: Select,
    name: "Select",
    props: { data: ["React", "Angular", "Vue"] },
  },
  {
    Component: NativeSelect,
    name: "NativeSelect",
    props: { data: ["React", "Angular", "Vue"] },
  },
  {
    Component: Radio,
    name: "Radio",
    props: { label: "Hello world", defaultChecked: true },
  },
  { Component: Rating, name: "Rating", props: { label: "Rating", value: 3 } },
  {
    Component: Slider,
    name: "Slider",
    props: {
      label: "Slider",
      w: "100%",
      marks: [
        { value: 20, label: "20%" },
        { value: 50, label: "50%" },
        { value: 80, label: "80%" },
      ],
    },
  },
  {
    Component: SegmentedControl,
    name: "SegmentedControl",
    props: {
      label: "SegmentedControl",

      data: [
        { value: "React", label: "React" },
        { value: "Vue", label: "Vue" },
      ],
    },
  },
  {
    Component: Switch,
    name: "Switch",
    props: {
      label: "Switch",
      defaultChecked: true,
    },
  },
  {
    Component: Tooltip,
    name: "Tooltip",
    props: { label: "Tooltip", children: <Button>Hover me</Button> },
  },
  {
    Component: Accordion,
    name: "Accordion",
    props: {
      children: (
        <>
          <Accordion.Item value="Accordion Item">
            <Accordion.Control>Toggle me</Accordion.Control>
            <Accordion.Panel>Hello world</Accordion.Panel>
          </Accordion.Item>
        </>
      ),
    },
  },
  {
    Component: Anchor,
    name: "Anchor",
    props: { href: "#", children: "Hello world" },
  },
  {
    Component: Breadcrumbs,
    name: "Breadcrumbs",
    props: {
      children: [
        <Anchor href="#" key={1}>
          Hello
        </Anchor>,
        <Anchor href="#" key={2}>
          World
        </Anchor>,
      ],
    },
  },
  { Component: Kbd, name: "Kbd", props: { children: "Hello world" } },
  {
    Component: List,
    name: "List",
    props: {
      children: [
        <List.Item key="hello">Hello</List.Item>,
        <List.Item key="world">World</List.Item>,
      ],
    },
  },
  { Component: Progress, name: "Progress", props: { value: 20, w: "100%" } },
  {
    Component: RingProgress,
    name: "RingProgress",
    props: {
      sections: [
        {
          color: "blue",
          value: 34,
        },
        {
          color: "red",
          value: 27,
        },
        {
          color: "purple",
          value: 16,
        },
      ],
    },
  },
  { Component: Skeleton, name: "Skeleton", props: { height: "20px" } },
  {
    Component: Table,
    name: "Table",
    props: {
      children: (
        <>
          <Table.Thead>
            <Table.Tr>
              <Table.Th> Column 1</Table.Th>
              <Table.Th>Column 2</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <Table.Tr>
              <Table.Td>Table Cell</Table.Td>
              <Table.Td>Table Cell</Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </>
      ),
    },
  },
  {
    Component: Tabs,
    name: "Tabs",
    props: {
      defaultValue: "1",
      children: (
        <Tabs.List>
          <Tabs.Tab value="1">Tab 1</Tabs.Tab>
          <Tabs.Tab value="2">Tab 2</Tabs.Tab>
        </Tabs.List>
      ),
    },
  },
  {
    Component: Timeline,
    name: "Timeline",
    props: {
      active: 1,
      children: [
        <Timeline.Item key={1}>Hello</Timeline.Item>,
        <Timeline.Item key={2}>World</Timeline.Item>,
        <Timeline.Item key={3}>Welcome</Timeline.Item>,
      ],
    },
  },
  {
    Component: BackgroundImage,
    name: "BackgroundImage",
    props: {
      h: "100%",
      src: "https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-7.png",
      children: <Text>BackgroundImage</Text>,
    },
  },
  {
    Component: Paper,
    name: "Paper",
    props: { shadow: "sm", p: "md", children: <Text>Paper</Text> },
  },
  {
    Component: Popover,
    name: "Popover",
    props: {
      children: (
        <Popover width={200} position="bottom" withArrow shadow="md">
          <Popover.Target>
            <Button>Click me</Button>
          </Popover.Target>
          <Popover.Dropdown>
            <Text size="sm">Hello world</Text>
          </Popover.Dropdown>
        </Popover>
      ),
    },
  },
  {
    Component: Spoiler,
    name: "Spoiler",
    props: {
      maxHeight: 60,
      showLabel: "Show more",
      hideLabel: "Hide",
      children: (
        <div>
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem
          accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae
          ab illo inventore veritatis et quasi
        </div>
      ),
    },
  },
  {
    Component: MultiSelect,
    name: "MultiSelect",
    props: {
      data: ["Hello", "World", "Welcome"],
      placeholder: "Select options",
      searchable: true,
      w: "100%",
    },
  },
  {
    Component: Tree,
    name: "Tree",
    props: {
      data: [
        {
          expanded: true,
          label: "- Hello",
          value: "Hello",
          children: [
            {
              label: "Child 1-1",
              children: [],
            },
            {
              label: "Child 1-2",
              children: [],
            },
          ],
        },
        {
          label: "- World",
          value: "World",
          children: [
            {
              label: "Child 2-1",
              children: [],
            },
            {
              label: "Child 2-2",
              children: [],
            },
          ],
        },
      ],
    },
  },
  {
    Component: RangeSlider,
    name: "RangeSlider",
    props: {
      w: "100%",
      label: "RangeSlider",
      defaultValue: [20, 80],
      min: 0,
      max: 100,
      step: 1,
      onChange: (value) => console.log("RangeSlider value:", value),
    },
  },
  {
    Component: HoverCard,
    name: "HoverCard",
    props: {
      children: (
        <>
          {" "}
          <HoverCardTarget>
            <Button>Hover me</Button>
          </HoverCardTarget>
          <HoverCardDropdown>
            <Text size="sm">
              Hover card is revealed when user hovers over target element, it
              will be hidden once mouse is not over both target and dropdown
              elements
            </Text>
          </HoverCardDropdown>
        </>
      ),
      title: "HoverCard Title",
      body: "HoverCard Body",
    },
  },
  {
    Component: () => {
      const [pills, setPills] = useState(["React"]);

      return (
        <PillsInput>
          <PillGroup>
            {pills.map((pill) => (
              <Pill key={pill}>{pill}</Pill>
            ))}
            <PillsInputField
              placeholder="Enter tags"
              onKeyDown={(event) => {
                const target = event.target as HTMLInputElement;

                if (event.key === "Enter") {
                  setPills([...pills, target.value]);
                  target.value = "";
                }

                if (
                  event.key === "Backspace" &&
                  target.value === "" &&
                  pills.length > 0
                ) {
                  setPills((prevPills) => {
                    const currPills = [...prevPills];
                    currPills.pop();
                    return currPills;
                  });
                }
              }}
            />
          </PillGroup>
        </PillsInput>
      );
    },
    name: "PillsInput",
    props: {},
  },
  {
    Component: TagsInput,
    name: "TagsInput",
    props: {
      description: "Press Enter to submit a tag",
      label: "Tags input",
      placeholder: "Enter tag",
    },
  },
  {
    Component: () => (
      <>
        <Text size="xs">Fixed to bottom right</Text>
        <Affix position={{ bottom: 20, right: 20 }}>
          <Button>Affix Button</Button>
        </Affix>
      </>
    ),
    name: "Affix",
    props: {},
  },
  {
    Component: () => {
      const [drawerOpened, setDrawerOpened] = useState(false);

      return (
        <>
          <Button onClick={() => setDrawerOpened(true)}>Click me</Button>
          <Drawer
            opened={drawerOpened}
            onClose={() => setDrawerOpened(false)}
            title="Menu"
          >
            <Text>Hello World</Text>
          </Drawer>
        </>
      );
    },
    name: "Drawer",
    props: {},
  },
  {
    Component: Menu,
    name: "Menu",
    props: {
      children: (
        <>
          <MenuTarget>
            <Button>Toggle menu</Button>
          </MenuTarget>
          <MenuDropdown>
            <MenuLabel>Application</MenuLabel>
            <MenuItem
              leftSection={<IconSettings style={{ width: 14, height: 14 }} />}
            >
              Settings
            </MenuItem>
            <MenuItem
              leftSection={
                <IconMessageCircle style={{ width: 14, height: 14 }} />
              }
            >
              Messages
            </MenuItem>
            <MenuItem
              leftSection={<IconPhoto style={{ width: 14, height: 14 }} />}
            >
              Gallery
            </MenuItem>
            <MenuItem
              leftSection={<IconSearch style={{ width: 14, height: 14 }} />}
              rightSection={
                <Text size="xs" c="dimmed">
                  ⌘K
                </Text>
              }
            >
              Search
            </MenuItem>

            <MenuDivider />

            <MenuLabel>Danger zone</MenuLabel>
            <MenuItem
              leftSection={
                <IconArrowsLeftRight style={{ width: 14, height: 14 }} />
              }
            >
              Transfer my data
            </MenuItem>
            <MenuItem
              color="red"
              leftSection={<IconTrash style={{ width: 14, height: 14 }} />}
            >
              Delete my account
            </MenuItem>
          </MenuDropdown>
        </>
      ),
    },
  },
  {
    Component: () => {
      const [visible, { toggle }] = useDisclosure(false);

      return (
        <Flex direction={"column"}>
          <Box pos="relative" mb={"lg"}>
            <LoadingOverlay
              visible={visible}
              zIndex={1000}
              overlayProps={{ radius: "sm", blur: 2 }}
            />

            <Image
              src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/images/bg-8.png"
              height={160}
              alt="Norway"
            />
          </Box>

          <Button onClick={toggle}>Toggle overlay</Button>
        </Flex>
      );
    },
    name: "LoadingOverlay",
    props: {},
  },
  {
    Component: () => {
      const [opened, { toggle, close }] = useDisclosure(false);

      return (
        <>
          <Group justify="center">
            <Button onClick={toggle}>Toggle dialog</Button>
          </Group>

          <Dialog
            opened={opened}
            withCloseButton
            onClose={close}
            size="lg"
            radius="md"
          >
            <Text size="sm" mb="xs" fw={500}>
              Subscribe to email newsletter
            </Text>

            <Group align="flex-end">
              <TextInput
                placeholder="hello@gluesticker.com"
                style={{ flex: 1 }}
              />
              <Button onClick={close}>Subscribe</Button>
            </Group>
          </Dialog>
        </>
      );
    },
    name: "Dialog",
    props: {},
  },
  {
    Component: () => {
      const [modalOpened, setModalOpened] = useState(false);
      return (
        <>
          <Button onClick={() => setModalOpened(true)}>Click me</Button>
          <Modal
            opened={modalOpened}
            onClose={() => setModalOpened(false)}
            title="Modal Title"
          >
            <Text>Hello world</Text>
          </Modal>
        </>
      );
    },
    name: "Modal",
    props: {},
  },
  {
    Component: () => {
      const [burgerOpened, setBurgerOpened] = useState(false);
      return (
        <Burger
          opened={burgerOpened}
          onClick={() => setBurgerOpened((prev) => !prev)}
          title="Toggle Menu"
        />
      );
    },
    name: "Burger",
    props: {},
  },
  {
    Component: (props) => {
      const [active, setActive] = useState(0);

      return (
        <Stepper
          {...props}
          onStepClick={(s) => {
            setActive(s + 1);
          }}
          active={active}
        />
      );
    },
    name: "Stepper",
    props: {
      orientation: "horizontal",
      w: "100%",
      variant: "neutral",
      children: [
        <StepperStep key={1} label="First step">
          Step 1 content: Create an account
        </StepperStep>,
        <StepperStep key={2} label="Second step">
          Step 2 content: Verify email
        </StepperStep>,
        <StepperStep key={3} label="Final step">
          Step 3 content: Get full access
        </StepperStep>,
        <StepperCompleted key={4}>Completed</StepperCompleted>,
      ],
    },
  },
];

const QAPage = () => {
  const [activePage, setActivePage] = useState(1);

  const componentsPerPage = Math.ceil(components.length / 3);

  const startIndex = (activePage - 1) * componentsPerPage;
  const endIndex = activePage * componentsPerPage;

  const componentsForPage = components.slice(startIndex, endIndex);

  return (
    <Flex direction={"column"} align={"center"} h={"100%"} w="100%">
      <Title order={4} mb={"md"}>
        UI Library Components
      </Title>
      <Grid
        gutter={{ base: "md", xl: "xl" }}
        grow
        w={"100%"}
        h={"100%"}
        align="stretch"
        justify="stretch"
        p={{ xl: "lg" }}
      >
        {componentsForPage.map(({ Component, name, props }, index) => (
          <Grid.Col span={3} key={index} h={250}>
            <Card withBorder h="100%" bd="1px solid gray.5">
              <Title order={6} mb={"md"}>
                {name}
              </Title>
              <Flex justify="center" align="center" h="100%">
                <Component
                  key={index}
                  {...props}
                  data-testId={`qa-page-${name}`}
                />
              </Flex>
            </Card>
          </Grid.Col>
        ))}
      </Grid>

      <Pagination
        mt={"sm"}
        total={3}
        onChange={(page) => {
          setActivePage(page);
        }}
      />
    </Flex>
  );
};

export default QAPage;
