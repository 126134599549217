import { addHttp } from "shared/helpers";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import GlobalSettingsContext from "shared/modules/global-settings/state/global-settings-context.provider";
import { UserType } from "types";
import { GlobalSettings } from "shared/modules/global-settings/hooks/use-global-settings.hooks";
import useUser from "./use-user";

type LinkType = "support" | "imprint" | "privacy" | "accessibility";

const getSupportDomainByLanguage = (language: string) =>
  `${language === "en" ? "" : `${language}.`}support.sosafe.de/`;

const supportedLanguees = ["en", "de", "fr", "nl", "es"];

const generalSupportLink = {
  en: "UserDoc",
  de: "ndok",
  fr: "UDOCFR",
  nl: "ADOCNL",
};

const registrationSupportLink = {
  en: `${generalSupportLink.en}/how-to-register-for-the-e-learning-platform`,
  de: `${generalSupportLink.de}/erste-anmeldung-registrierung`,
  fr: `${generalSupportLink.fr}/premiere-connexion-inscription-a-la-plateforme-de-`,
  nl: `${generalSupportLink.nl}/eerste-registratie-bij-het-platform-voor-e-learnin`,
};

const loginSupportLink = {
  en: `${generalSupportLink.en}/login-and-registration-errors-can-t-log-in`,
  de: `${generalSupportLink.de}/fehler-beim-einloggen-und-registrieren-kein-login-`,
  fr: `${generalSupportLink.fr}/je-recois-un-message-d-erreur-lors-de-la-connexion`,
  nl: `${generalSupportLink.nl}/ik-krijg-een-foutmelding-als-ik-inlog-of-me-regist`,
};

const privacyLink = {
  en: "sosafe-awareness.com/privacy/",
  de: "sosafe-awareness.com/de/datenschutz/ ",
  fr: "sosafe-awareness.com/fr/politique-de-confidentialite/",
  nl: "sosafe-awareness.com/nl/privacybeleid/",
  es: "sosafe-awareness.com/es/politica-de-privacidad/",
};

const accessibilityLink = {
  en: "support.sosafe.de/INFO/accessibility-statement",
  de: "de.support.sosafe.de/WINFO/erklarung-zur-barrierefreiheit",
  fr: "fr.support.sosafe.de/INFADFR/declaration-d-accessibilite",
  nl: "nl.support.sosafe.de/EXTRAINFNL/toegankelijkheidsverklaring",
};

const imprintLink = {
  en: "sosafe-awareness.com/imprint/",
  de: "sosafe-awareness.com/de/impressum/",
  fr: "sosafe-awareness.com/fr/mentions-legales/",
  nl: "sosafe-awareness.com/nl/impressum/",
  es: "sosafe-awareness.com/es/informacion-legal/",
};

function getSupportLink(language: string) {
  const url = window.location.href;
  const apikey = localStorage.getItem("apikey");

  if (url.includes("register")) {
    const link = registrationSupportLink[language];
    if (link) return link;
    return null;
  }

  // not logged in
  if (!apikey) {
    const link = loginSupportLink[language];
    if (link) return link;
    return null;
  }

  const link = generalSupportLink[language];

  if (link) return link;

  return null;
}

function getPersonalizedLinkFromCampaignSettings(
  user: UserType | undefined,
  globalSettings: GlobalSettings,
  key: LinkType
) {
  const campaignLink = user?.campaign?.[key];

  // Check for custom links in campaign settings if available use them
  if (campaignLink) {
    if (campaignLink === "none") {
      return "none";
    }
    return addHttp(campaignLink);
  }

  const globalSettingsLink = globalSettings[key];

  // check for global settings if available use them
  if (globalSettingsLink) {
    if (globalSettingsLink === "none") {
      return "none";
    }
    return addHttp(globalSettingsLink);
  }

  return null;
}

function determineCorrectLink(
  user: UserType | undefined,
  globalSettings: GlobalSettings,
  language: string,
  key: LinkType
) {
  const languageWithFallback = supportedLanguees.includes(language) ? language : "en";

  const customLink = getPersonalizedLinkFromCampaignSettings(user, globalSettings, key);

  if (customLink === "none") return null;
  if (customLink) return customLink;

  // if no custom link is available use the default links
  switch (key) {
    case "support": {
      const supportLink = getSupportLink(languageWithFallback);
      if (supportLink)
        return addHttp(`${getSupportDomainByLanguage(languageWithFallback)}${supportLink}`);
      return addHttp(`${getSupportDomainByLanguage("en")}${supportLink}`);
    }

    case "imprint": {
      const link = imprintLink[languageWithFallback];
      if (link) return addHttp(link);
      return addHttp(imprintLink.en);
    }

    case "accessibility": {
      const link = accessibilityLink[languageWithFallback];
      if (link) return addHttp(link);
      return addHttp(accessibilityLink.en);
    }

    case "privacy": {
      const link = privacyLink[languageWithFallback];
      if (link) return addHttp(link);
      return addHttp(imprintLink.en);
    }

    default:
      if (language === "en" || !supportedLanguees.includes(languageWithFallback)) {
        return "https://support.sosafe.de";
      }
      return `https://${language}.support.sosafe.de`;
  }
}

export default function useMetaLinks() {
  const [links, setLinks] = useState({
    support: "",
    imprint: "",
    privacy: "",
    accessibility: "",
  });
  const { globalSettings } = useContext(GlobalSettingsContext);

  const { user } = useUser({});
  const { i18n } = useTranslation([]);
  const { language } = i18n;

  useEffect(() => {
    setLinks({
      support: determineCorrectLink(user, globalSettings, language, "support"),
      imprint: determineCorrectLink(user, globalSettings, language, "imprint"),
      privacy: determineCorrectLink(user, globalSettings, language, "privacy"),
      accessibility: determineCorrectLink(user, globalSettings, language, "accessibility"),
    });
  }, [user, globalSettings, language, window.location.href]);

  return {
    // used on <a/> tags
    ...links,
    // used on buttons eg: header dropdown
    getSupportLink: () => determineCorrectLink(user, globalSettings, language, "support"),
    getImprintLink: () => determineCorrectLink(user, globalSettings, language, "imprint"),
    getPrivacyLink: () => determineCorrectLink(user, globalSettings, language, "privacy"),
    getAccessibilityLink: () =>
      determineCorrectLink(user, globalSettings, language, "accessibility"),
  };
}
