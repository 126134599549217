
import { useState } from "react";
import useUser from "shared/hooks/use-user";

export interface PasswordState {
  oldpassword: string;
  newpassword: string;
  newpasswordRepeat: string;
}

type FieldKey = keyof PasswordState;
type ErrorState = Record<FieldKey, string | null>;

const initialState: PasswordState = {
  oldpassword: "",
  newpassword: "",
  newpasswordRepeat: "",
}

const initialErrorState: ErrorState = {
  oldpassword: null,
  newpassword: null,
  newpasswordRepeat: null,
}

interface Props {
  validator: (key: FieldKey, value: string, state: PasswordState) => string | null;
}

export const usePasswordState = ({ validator }: Props) => {
  const { user, loading } = useUser({});
  const [state, setState] = useState<PasswordState>(initialState);
  const [error, setError] = useState<Record<FieldKey, string | null>>(initialErrorState);

  const updateField = (key: FieldKey, value: string) => {
    setState((prev) => ({
      ...prev,
      [key]: value,
    }));

  }


  const validate = (key: FieldKey) => {
    const fieldError = validator(key, state[key], state);

    setError((prev) => ({
      ...prev,
      [key]: fieldError,
    }));

    return fieldError;

  }

  const isValid = Object.values(error).every((err) => err === null);

  const validateAllFields = () => {
    const isAllFieldsValid = Object.keys(state).some((key) => {
      return !validate(key as FieldKey);
    });
    return isAllFieldsValid;
  }

  return {
    updateField,
    loading: loading || user === undefined,
    validate,
    validateAllFields,
    isValid,
    error,
    state
  }
}
