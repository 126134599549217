import React from "react";
import { useTranslation } from "react-i18next";
import { Title } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import style from "./badge-section.module.css";

interface BadgeSectionTitleProps {
  row: number;
  children: React.ReactNode;
}

const BADGE_SECTION_TITLES = {
  1: "State of knowledge",
  2: "Learning achievements",
  3: "Rewards",
  4: "Challenges",
  5: "Strengthening",
};

export const BadgeSection: React.FunctionComponent<BadgeSectionTitleProps> = ({
  children,
  row,
}) => {
  const { t } = useTranslation("translations");

  const title = BADGE_SECTION_TITLES[row];
  const translatedTitle = title ? t(title) : "";

  return (
    <React.Fragment>
      <Title className={style.content} order={3} size="h5">{translatedTitle}</Title>
      <div className={style.badges}>{children}</div>
      <hr className={style.line} />
    </React.Fragment>
  );
};
