import React, { useMemo } from "react";
import { Card, Title } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { useTranslation } from "react-i18next";
import { useGetBadge } from "shared/hooks/use-get-badge";
import { BADGE_VIEWS } from "elearning/helpers";
import { filterBadgesCard } from "gamification/components/badge-card/utils";
import Loading from "shared/components/loading";
import { BadgeSection } from "../badge-section/badge-section";
import BadgeButton from "../badge/badge-button";

import style from "./badges.module.css";

export const BadgesCard = () => {
  const { data: badgeResponse, isLoading, error } = useGetBadge();
  const { receivedAmount, sections } = useMemo(
    () => filterBadgesCard(badgeResponse?.data.result || []),
    [badgeResponse]
  );
  const { t } = useTranslation("flamingo");

  if (error)
    return (
      <Card withBorder radius={"lg"}>
        {t("badge_error")}
      </Card>
    );

  return (
    <Card withBorder radius={"lg"}>
      <div className={style.badgesBody}>
        <div className={style.title}>
          <div className={style.subtitle}>
            <Title order={2} size="h4" className={style.subtitle}>
              <span>{t("badges")}</span>
              <span className={style.subtitleNumbers}>
                {`${receivedAmount} / ${badgeResponse?.data.result ? badgeResponse?.data.result.length : 0}`}
              </span>
            </Title>
          </div>
        </div>

        {isLoading ? (
          <Loading className={style.loading} />
        ) : (
          <div className={style.badgesContent}>
            {sections.map((badges, index) => (
              <BadgeSection key={index} row={badges[0]?.row || 0}>
                {badges.map((badge) => (
                  <BadgeButton
                    key={badge.id}
                    badge={badge}
                    fromView={BADGE_VIEWS.ACHIEVEMENTS_PAGE.BADGE_PANEL}
                    height={100}
                  />
                ))}
              </BadgeSection>
            ))}
          </div>
        )}
      </div>
    </Card>
  );
};
