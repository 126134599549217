import React, { FunctionComponent } from "react";

export interface AuthContextProps {
  signIn: () => void;
  signOut: () => void;
  signUp: () => void;
}

const initialContextData: AuthContextProps = {
  signIn: () => {
    console.warn("Auth context has not been initialized");
  },
  signOut: () => {
    console.warn("Auth context has not been initialized");
  },
  signUp: () => {
    console.warn("Auth context has not been initialized");
  },
};

export const AuthContext = React.createContext(initialContextData);

type AuthContextProviderProps = {
  children?: React.ReactNode;
  contextProps: AuthContextProps;
};

export const AuthContextProvider: FunctionComponent<
  AuthContextProviderProps
> = ({ children, contextProps }: AuthContextProviderProps) => (
  <AuthContext.Provider value={contextProps}>{children}</AuthContext.Provider>
);

export const useAuthContext = () => {
  const context = React.useContext(AuthContext);
  if (!context)
    throw new Error(
      "useAuthContext can only be used inside <AuthContextProvider/>"
    );
  return context;
};
