import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { queryKey, useSoSafeConnect } from "shared/modules/sosafe-connect";
import useUser from "./use-user";

export const isUserAllowedOnPL = () => {
  const { apiKey, endPoints } = useSoSafeConnect();
  const { user } = useUser({});
  const [customLoading, setCustomeLoading] = useState(true);
  const isPersonalizedLearning = user?.campaign.isPersonalizedLearning ?? false;

  const { isSuccess, refetch } = useQuery(
    [queryKey.IS_USER_READY_TO_USE_PL],
    () => endPoints.learningPathApi.fetch.isUserReadyToUsePL(user?.id || 0), {
    enabled: !!apiKey && isPersonalizedLearning && user !== undefined,
    staleTime: Infinity,
    refetchOnWindowFocus: true,
    retry: false,
    // use custom loading because in this case a 400 + is a valid response in
    // which we use to determine if the route should be allowed or not
    onError: () => { setCustomeLoading(false) },
    onSuccess: () => { setCustomeLoading(false) }
  });

  return {
    isAllowed: isSuccess,
    isLoading: customLoading,
    refetch
  }

}
