import React from 'react';
import { Trans } from 'react-i18next';
import useMetaLinks from 'shared/hooks/use-meta-links';

export const PrivacyLink = () => {
  const { privacy } = useMetaLinks();
  return (
        <React.Fragment>
            <hr className='w-100'/>
            <small className='text-center'>
                <Trans
                    i18nKey='moduleFeedbackTranslations:privacy_notice'
                    components={{
                      policylink: (
                            <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href={privacy}
                            />
                      ),
                    }}
                />
            </small>
        </React.Fragment>
  );
};
