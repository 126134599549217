import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { queryKey } from 'shared/modules/sosafe-connect';
import ActionTypes from 'shared/utilities/action-types';
import { UseReferenceHandler } from 'shared/utilities/use-reference-handler';

interface useSoSafeMutationProps<T> {
  requestMethod(params: T): Promise<AxiosResponse<any>>;
  key: queryKey;
  onSuccess?: (response: AxiosResponse<any>) => void;
  onError?: (error: any) => void;
  onBeforeMutate?: () => void;
  requestType: ActionTypes;
  successType: ActionTypes;
  failedType: ActionTypes;
}

export function useSoSafeMutation<T>({
  requestMethod,
  key,
  onSuccess,
  onError,
  onBeforeMutate,
  successType,
  failedType,
}: useSoSafeMutationProps<T>) {
  const dispatch = useDispatch();
  const checkReference = UseReferenceHandler();

  return useMutation([key], requestMethod, {
    onSuccess: (response) => {
      onSuccess?.(response);
      dispatch({ payload: checkReference(response.data, dispatch), type: successType });
    },
    onError: (error: any) => {
      onError?.(error);
      if (error?.response?.data) {
        dispatch({ payload: checkReference(error.response.data, dispatch), type: failedType });
      } else if (error.name && error.statusCode) {
        dispatch({
          payload: checkReference(
            { title: error.name, status: error.statusCode, reference: '8CCA1873-7195-4EB7-8BF4-32CCF473B353' },
            dispatch,
          ),
          type: failedType,
        });
      } else {
        console.error(`Unexpected error while mutating data with key:${key}`, error);
      }
    },
    onMutate: () => onBeforeMutate?.()
  });
}
