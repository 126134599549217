import { useSoSafeQuery } from 'shared/hooks/use-sosafe-query';
import { queryKey, useSoSafeConnect } from 'shared/modules/sosafe-connect';
import ActionTypes from 'shared/utilities/action-types';

export const useGetBadge = (fetchOnMount?: boolean) => {
  const { endPoints } = useSoSafeConnect();

  return useSoSafeQuery({
    enabled: fetchOnMount ?? true,
    key: queryKey.BADGES,
    requestMethod: endPoints.fetch.getBadges,
    requestType: ActionTypes.GET_BADGES_REQUEST,
    successType: ActionTypes.GET_BADGES_SUCCESS,
    failedType: ActionTypes.GET_BADGES_FAILED,
  });
};
