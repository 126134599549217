import { AxiosInstance } from "axios";
import { URL_SURVEY_HUB_API } from "shared/helpers/url/url";
import { PostSurveyProps } from "survey-hub/components/survey/types/post-survey.type";
import { SurveyHubGetActiveSurveyResponse } from "types/survey-hub-types";

const getSurvey = async (axiosInstance: AxiosInstance, surveyId: number) => {
  try {
    const { data } = await axiosInstance.get(`${URL_SURVEY_HUB_API()}surveys/${surveyId}`);
    return data;
  } catch (error: any) {
    return error;
  }
};

type AnswerDataType = Record<string, Array<string>>;
export type AssessmentResultType = Array<{ questionName: string, passed: boolean }>

export type PostSurveyResponse = {
  surveyId: number;
  answerData: AnswerDataType;
  validationResult?: AssessmentResultType;
}

const postSurvey = async (axiosInstance: AxiosInstance, props: PostSurveyProps) => {
  const { userId, surveyId, answers } = props;
  return axiosInstance.post<PostSurveyResponse>(`${URL_SURVEY_HUB_API()}survey-results`, {
    userId,
    surveyId,
    answerData: answers,
  });
};

interface GetSurveyResultProps {
  userId: number;
  surveyTypeId: number;
  surveyFeatureId: number;
}

const getSurveyResult = async (
  axiosIstance: AxiosInstance,
  { userId, surveyTypeId, surveyFeatureId }: GetSurveyResultProps
) =>
  axiosIstance.get(
    `${URL_SURVEY_HUB_API()}survey-results/?user_id=${userId}&survey_type_id=${surveyTypeId}&survey_feature_id=${surveyFeatureId}`
  );

const getActiveSurvey = async (axiosInstance: AxiosInstance, surveyTypeId: number) =>
  axiosInstance.get<SurveyHubGetActiveSurveyResponse<any>>(
    `${URL_SURVEY_HUB_API()}surveys/${surveyTypeId}/active`
  );

export const surveyHubApi = (axiosInstance: AxiosInstance) => ({
  fetch: {
    getSurveyDataById: (surveyId: number) => getSurvey(axiosInstance, surveyId),
    getSurveyResult: (props: GetSurveyResultProps) => getSurveyResult(axiosInstance, props),
    getActiveSurvey: (surveyTypeId: number) => getActiveSurvey(axiosInstance, surveyTypeId),
  },
  post: {
    postSurveyAnswers: (props: PostSurveyProps) => postSurvey(axiosInstance, props),
  },
});
