import React from 'react';

import { DownloadDataType } from 'downloads/downloads.types';
import MaterialCard from '../download-card/download-card.component';

import './download-row.component.css';

interface MaterialRowProps {
  /** array of materials, see MaterialDataType for more informations */
  downloads: DownloadDataType[];
}
/** displays a row of materials. Is responsive and will wrap into multiple rows */
export default function DownloadRow(props: MaterialRowProps) {
  const { downloads } = props;
  const allMaterials = downloads
    .filter((download) => download.enabled)
    .map((download) => (
            <MaterialCard
                key={`download_${download.id}`}
                download={download}
            />
    ));
  if (allMaterials.length > 0) {
    return (
            <div className='Grid Grid--withGutter sosafe-DownloadPage-downloadRow'>
                {allMaterials}
            </div>
    );
  }
  return null;
}
