import { Button, TextArea } from "@sosafe-platform-engineering/fe-lib-ui-react";
import { useSendModuleFeedback } from "shared/hooks/use-send-module-feedback";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SendModuleFeedbackProps } from "shared/modules/sosafe-connect";
import { FeedbackError } from "./feedback-error";
import { PrivacyLink } from "./privacy-link";
import { RatingSelection } from "./rating-selection";
import { RecommendationSelection } from "./recommendation-selection";

type FeedbackType = "relevance" | "recommendation";

interface ModuleFeedbackProps {
  type: FeedbackType;
  moduleId: string;
  initialAnswer: number;
  onSendFeedback(props: SendModuleFeedbackProps): void;
  onSkip(): void;
}

export const ModuleFeedback = ({
  type,
  moduleId,
  initialAnswer,
  onSendFeedback,
  onSkip,
}: ModuleFeedbackProps) => {
  const { t } = useTranslation("moduleFeedbackTranslations");
  const [feedbackAnswer, setFeedbackAnswer] = useState<number>(initialAnswer);
  const [userHasAnswered, setUserHasAnswered] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const {
    isLoading,
    mutate: sendFeedback,
    isError,
  } = useSendModuleFeedback({
    onSuccess: () =>
      onSendFeedback({
        message,
        [type === "relevance" ? "rating" : "recommendation"]: feedbackAnswer,
        module: moduleId,
      }),
  });

  const onChangeMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const handleOnSelect = (n: number) => {
    setUserHasAnswered(true);
    setFeedbackAnswer(n);
  };

  const onSubmit = () => {
    const props = {
      message,
      module: moduleId,
      [type === "relevance" ? "rating" : "recommendation"]: feedbackAnswer,
    };
    return userHasAnswered ? sendFeedback(props) : onSkip();
  };

  return (
    <React.Fragment>
      <div className="d-flex flex-column text-left p-1">
        <h3 className="mb-2 h2 font-weight-normal">{t("module_finished")}</h3>
        <h4 className="mb-2 h3 d-inline-flex">
          {t(
            type === "relevance"
              ? "relevance_question"
              : "recommendation_question"
          )}
        </h4>
        <div>
          {type === "relevance" && (
            <RatingSelection
              setRating={handleOnSelect}
              rating={feedbackAnswer}
            />
          )}

          {type === "recommendation" && (
            <RecommendationSelection
              setRecommendation={handleOnSelect}
              recommendation={feedbackAnswer}
            />
          )}

          {userHasAnswered && (
            <TextArea
              data-testid="user-feedback-message"
              name="user feedback message"
              label={t("user_message")}
              value={message}
              onChange={onChangeMessage}
            />
          )}

          <div className="ModuleFeedback-submit-button">
            <Button
              className="u-marginTop24"
              ariaLabel={t(userHasAnswered ? "next" : "skip")}
              name="submitButton"
              onClick={onSubmit}
              disabled={isLoading}
            >
              {t(userHasAnswered ? "next" : "skip")}
            </Button>
          </div>

          {isError && <FeedbackError />}
        </div>
        <PrivacyLink />
      </div>
    </React.Fragment>
  );
};
