export enum SURVEY_STEP {
  WELCOME = 'welcome',
  IN_PROGRESS = 'inProgress',
  PREVIEW = 'preview',
  COMPLETED = 'completed',
  ERROR = 'error',
}

export enum SURVEY_TYPE {
  AWARENESS_ASSESSMENT = 1,
  CULTURE_AND_CONTEXT = 2,
  HRS_SELF_RATING = 3,
}

export enum FEATURE_TYPE {
  PERSONALIZED_LEARNING = 1,
  HRS = 2
}

export enum SURVEY_STATUS {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED"
}


/*
  Array of mandants to mark as not MSP relevant.
  Used for hiding the culture survey. Mandants within this array will get the survey.
*/
export const PARTNER_BLACK_LIST = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
