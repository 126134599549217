import React, { useEffect, useMemo, useState } from "react";

import {
  Button,
  Title,
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import Loading from "shared/components/loading";
import { Model } from "survey-core";
import { PersonalizedModule } from "flamingo-e-learning-platform/training/types/module.type";
import { useTranslation } from "react-i18next";
import { usePostSurveyAnswers } from "survey-hub/hooks/use-post-survey-answer";
import useUser from "shared/hooks/use-user";
import { UseShowDecisionAlert } from "flamingo-e-learning-platform/utilities/modal-alert/use-show-decision-alert";
import { ALERT_TYPE } from "flamingo-e-learning-platform/utilities/modal-alert/shared";
import ErrorCollapsible from "shared/components/error-collapsible";
import useMetaLinks from "shared/hooks/use-meta-links";
import { useHistory, useLocation } from "react-router";
import { queryClient, queryKey } from "shared/modules/sosafe-connect";
import {
  AwarenessAssessmentRouteStateType,
  SurveySummaryResult,
} from "flamingo-e-learning-platform/awareness-assessment/types";
import { parseCodeLanguageToISO6391 } from "shared/utilities/languages";
import AwarenessAssessmentExitAlert from "./components/awareness-assessment-alert/awareness-assessment-exit-alert";
import { addCustomItems, attachSoSafeSurveyDefaults, customCssSurvey, sanitizeSurveyModel } from "./utils/survey-utils";
import { AwarenessAssessmentSurvey } from "./components/awareness-assessment-survey";
import { registerSoSafeCheckboxItem } from "./components/survey-custom-elements/survey-checkbox";

import style from "./awareness-assessment.page.module.css";

interface AwarenessAssessmentControllerProps {
  surveyData: any;
  surveyId: number;
  module: PersonalizedModule;
}

export const AwarenessAssessmentController = ({
  surveyData,
  surveyId,
  module,
}: AwarenessAssessmentControllerProps) => {
  const [survey, setSurvey] = useState<Model>();
  const [modalOpened, setModalOpened] = useState(false);
  
  const history = useHistory();
  const location = useLocation<AwarenessAssessmentRouteStateType>();
  const { user } = useUser({});
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  const { t } = useTranslation("awareness-assessment");
  const { t: translate } = useTranslation([
    "messageTranslations",
    "translations",
  ]);
  const [surveyResult, setSurveyResult] =
    useState<Array<SurveySummaryResult> | null>(location.state?.answer ?? null);

  const sosafeSurvey = useMemo(() => {
    const sanitizedSurvey = sanitizeSurveyModel(surveyData);
    const surveyWithCustomItems = addCustomItems(sanitizedSurvey);
    const surveyWithSoSafeDefaults = attachSoSafeSurveyDefaults(
      surveyWithCustomItems
    );
    return surveyWithSoSafeDefaults;
  }, []);

  const link = useMetaLinks();

  const { show, close } = UseShowDecisionAlert();

  const errorAlert = (error: unknown) => {
    const onClose = async () => {
      history.push("/");
      close();
    };

    show({
      onClose,
      title: (
        <Title>{translate("messageTranslations:An error has occured")}</Title>
      ),
      content: (
        <>
          <ErrorCollapsible error={error} />
        </>
      ),
      footer: (
        <>
          <Button
            variant="primary"
            onClick={() => window.location.replace(link.support)}
          >
            {translate("translations:Go to support")}
          </Button>
          <Button variant="secondary">
            {translate("translations:Back to the home page")}
          </Button>
        </>
      ),
      type: ALERT_TYPE.ERROR,
    });
  };

  const postSurveyResult = usePostSurveyAnswers({
    onSucess: (result) => {
      try {
        if (!survey) throw new Error("Survey was not initialized correctly");
        if (!result.validationResult)
          throw new Error("Not able to validate the survey result");

        const surveySummary = result.validationResult.map((question) => {
          const { passed } = question;
          const text = survey.getQuestionByName(question.questionName).title;
          return { text, passed };
        });

        setSurveyResult(surveySummary);

        // add answered flag to location state to prevent the user from
        // restarting the survey
        location.state = { ...(location.state ?? {}), answer: surveySummary };
        history.replace(location);

        // invalidate queries to fetch new data once the user goes to training
        // or achievements page
        queryClient.invalidateQueries([queryKey.ELEARNING]);
        queryClient.invalidateQueries([queryKey.BADGES]);
        queryClient.invalidateQueries([queryKey.ACTIVITIES]);
      } catch (e) {
        errorAlert(e);
      }
    },
    onError: (e) => {
      errorAlert(e);
    },
  });


  useEffect(() => {
    if (!user?.language) return;
    registerSoSafeCheckboxItem();

    const surveyModel = new Model(sosafeSurvey);
    surveyModel.css = customCssSurvey("awareness-assessment");

    const language = parseCodeLanguageToISO6391(user?.language || "en");
    surveyModel.locale = language;

    setSurvey(surveyModel);
  }, [sosafeSurvey, user?.language]);

  useEffect(() => {
    const unblock = history.block((newLocation) => {
        // Prevent navigation if the user has not completed the survey
        if (!surveyResult && !modalOpened && newLocation.pathname !== "/awareness-assessment") {
            setModalOpened(true);
            return false; // Block the navigation
        }
        return undefined; // Allow the navigation
    });

    return () => {
        unblock(); // Cleanup on unmount
    };
}, [history, modalOpened, surveyResult]);


  const onNext = () => {
    if (survey && survey.currentPageNo === survey.pageCount - 1) {
      survey?.doComplete();
      return;
    }
    survey?.nextPage();
  };

  const onComplete = () => {
    if (!survey) return;
    postSurveyResult.mutate({
      userId: user?.id || 0,
      surveyId,
      answers: survey?.data,
    });
  };

  const onExit = () => {
    if (!surveyResult){
      setModalOpened(true);
    } else {
      history.push("/");
    }
  };

  const onModalClose = () => { setModalOpened(false)};

  if (!survey || !surveyData) return <Loading />;

  return (
    <div className={style.controller}>
        <div className={style.page}>
            <Button
                variant="outline"
                className={style.exitButton}
                aria-label={t("exitAssessment")}
                onClick={onExit}
            >
                {t("exitAssessment")}
            </Button>
            <AwarenessAssessmentExitAlert modalOpened={modalOpened} onModalClose={onModalClose}/>
            <AwarenessAssessmentSurvey
                surveyData={survey}
                module={module}
                onComplete={onComplete}
                setIsNextDisabled={setIsNextDisabled}
                surveyResult={surveyResult}
            />
        </div>
            {!surveyResult && (
            <div className={style.footer}>
                <Button
                    data-testid="next-button"
                    disabled={isNextDisabled}
                    className={style.nextButton}
                    aria-label={t("next")}
                    onClick={onNext}
                    >
                {t("next")}
                </Button>
            </div>
        )}
    </div>
    
  );
};
