import React from "react";
import { Alert, Button, Checkbox, Text } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { useModalContext } from "modal-context/modal-context";
import { IconBulb, IconCopy } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react/icons";
import { ALERT_TYPE } from "./shared";
import { UseShowDecisionAlert } from "./use-show-decision-alert";


export const ModalAlertPage = () => {
  const { close } = useModalContext();
  const { show } = UseShowDecisionAlert();

  return (
    <div>
      <h1>Modal Alert Playground page</h1>
      <p>{`
        This page is only available for development environments, and it's used
        to help visualize the modal alerts
        `}
      </p>

      <div style={{ display: "flex", gap: "8px" }}>
        <Button
          onClick={() =>
            show({
              title: <Text>Success</Text>,
              content: <p>This is a success alert</p>,
              footer: <React.Fragment>
                <Button onClick={close}>{"Let's go"}</Button>
                <Button variant="secondary" onClick={close}>Close</Button>
              </React.Fragment>,
              type: ALERT_TYPE.SUCCESS,
            })
          }
        >
          Success
        </Button>

        <Button
          onClick={() =>
            show({
              title: <Text>Error</Text>,
              content: <p>No customer found for this user</p>,
              footer: <React.Fragment>
                <Button variant="danger" onClick={close}>Reload page</Button>
                <Button variant="secondary" onClick={close}>Close</Button>
              </React.Fragment>,
              type: ALERT_TYPE.ERROR,
            })
          }
        >
          Error
        </Button>

        <Button
          onClick={() =>
            show({
              title: <Text>New version available</Text>,
              content: <p>A new version of this <strong>lesson</strong> is available, would like to update</p>,
              footer: <div style={{ display: "flex", width: "100%", justifyContent: "end", gap: "8px" }}>
                <Button variant="gradient" onClick={close}>Update</Button>
                <Button variant="secondary" onClick={close}>{"No thank's"}</Button>
              </div>,
              type: ALERT_TYPE.INFO,
            })
          }
        >
          Info
        </Button>

        <Button
          onClick={() =>
            show({
              title: <Text>Your password is about to expire</Text>,
              content: <h5>Expires in <strong>3</strong> days</h5>,
              footer: <React.Fragment>
                <Button variant="primary" onClick={close}>Change password</Button>
                <Button variant="danger" onClick={close}>{"I will do it later"}</Button>
              </React.Fragment>,
              type: ALERT_TYPE.WARNING,
            })
          }
        >
          Warning
        </Button>

        <Button
          onClick={() =>
            show({
              title: <Text>Leave a lesson in progress?</Text>,
              content: <p>If you leave the lesson now, you will lose your progress</p>,
              footer: <React.Fragment>
                <Button variant="primary" onClick={close}>Keep learning</Button>
                <Button variant="danger" onClick={close}>{"Leave it"}</Button>
              </React.Fragment>,
              type: ALERT_TYPE.QUESTION,
            })
          }
        >
          Question
        </Button>

        <Button
          onClick={() =>
            show({
              title: <div>
                        <Text fw={600} size='xl' style={{ alignSelf: 'center' }}>Thank you!</Text>
                        <Text fw={600} size='xl' style={{ alignSelf: 'center' }}>Your account has been created.</Text>
                     </div>,
              content:<div>
                        <Alert
                          icon={<IconBulb />}
                          title="Attentation: Please remember this password"
                          variant="info"
                          style={{marginTop: '34px'}}
                        ></Alert>
                        <Text size="xs" style={{marginTop: '16px'}}>Whitout this you will lose access to your account and your learning progress.</Text>
                        <div style={{display: 'flex', justifyContent: 'center', gap: '16px', marginTop: '40px'}}>
                          <Text fw={600} size='xl' c='blue' style={{ alignSelf: 'center' }}>9007C9</Text>
                          <Button
                            onClick={() => {}}
                            leftSection={<IconCopy size={16} />}
                          >
                            Copy
                          </Button>
                        </div>
                        <div style={{marginTop: '16px', display: 'flex', justifyContent: 'center', gap: '16px'}}>
                          <Checkbox variant="danger" style={{alignSelf:'center'}}/>
                          <Text size="xs" style={{alignSelf:'center'}}>I copied this password</Text>
                        </div>
                      </div>,
              footer: <div style={{ display: "flex", width: "100%", justifyContent: "end" }}>
                        <Button type='submit' variant="secondary" onClick={close}>Login directly</Button>
                      </div>,
              type: ALERT_TYPE.SUCCESS,
            })
          }
        >
          Login
        </Button>



      </div>

    </div>
  );
};
