import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Checkbox, Collapsible, LockIcon } from '@sosafe-platform-engineering/fe-lib-ui-react';
import { UseReferenceHandler } from 'shared/utilities/use-reference-handler';
import ActionTypes from 'shared/utilities/action-types';
import { getStatusOfModule } from 'elearning/helpers';
import { MODULE_STATES } from 'shared/utilities/module-states';
import useUser from 'shared/hooks/use-user';

import './module-state-changer.scss';
import { checkModuleLocked } from 'elearning/helpers/check';
import { RootState } from 'state/reducers';
import {
  LessonStatus, queryClient, queryKey, useSoSafeConnect,
} from 'shared/modules/sosafe-connect';
import { LangSwitch } from './cheats/lang-switch';

const fakeModuleCompletePayload = {
  version: 2,
  score: 100,
  progress: 100,
  suspend_data: '',
  lesson_status: LessonStatus.PASSED,
};

const fakeModuleInitPayload = {
  version: 2,
  score: 0,
  progress: 20,
  suspend_data: '',
  lesson_status: LessonStatus.PAUSED,
};

export const ModuleStateChanger = () => {
  const {
    user,
    loading,
  } = useUser({});
  const { categories } = useSelector((state: RootState) => state.modules, shallowEqual);
  const dispatch = useDispatch();
  const { endPoints } = useSoSafeConnect();
  const checkReference = UseReferenceHandler();

  // Function to initialize module if it is untreated until now.
  const initModule = async (module): Promise<void> => {
    const payload = { module: module.id, ...fakeModuleInitPayload };
    try {
      // Send a request with fake data so that the necessary table entries are created in our backend
      await endPoints.post.updateElearning(payload);
      queryClient.invalidateQueries([queryKey.AUTH]);
    } catch (error: any) {
      if (error.response) {
        checkReference(error.response.data);
      } else {
        checkReference({ title: error.name, status: error.statusCode, reference: '013E2DF7-6C55-4F84-8FF2-372593ED5DD5' });
      }
    }
  };

  const setModuleToPassed = async (module): Promise<void> => {
    if (getStatusOfModule(module).some((st) => st === MODULE_STATES.UNTREATED)) {
      await initModule(module); // Make sure the necessary tables are created in our DB
    }
    const payload = { module: module.id, ...fakeModuleCompletePayload };
    // Post fake completion data to backend

    endPoints.post.updateElearning(payload)
      .then((response) => {
        const {result} = checkReference(response.data);
        // Merge current frontend state with new data from backend
        const newActiveModuleData = {
          ...module,
          progress: result.progress,
          slide: result.slide,
          point: result.point,
          score: result.score,
          suspend_data: result.suspend_data,
          status: result.status,
        };
        // Dispatch redux-action to appropriately reflect our changes in the UI
        dispatch({ type: ActionTypes.MODULE_UPDATE_PROGRESS, payload: { module: newActiveModuleData } });

        queryClient.invalidateQueries([queryKey.ELEARNING]);
      })
      .catch((error) => {
        if (error.response) {
          checkReference(error.response.data);
        } else {
          checkReference({ title: error.name, status: error.statusCode, reference: '013E2DF7-6C55-4F84-8FF2-372593ED5DD5' });
        }
      });
  };

  return (
    <div className='sosafe-modulechanger'>
      <LangSwitch />
      {categories.map((cat) => (
          <div key={`div1_${cat.name}`}>
            <Collapsible
              key={`div2_${cat.name}`}
              className='sosafe-modulechanger-categories'
              title={cat.name}
              titleSize='h3'
              ignoreAccordion={true}
            >
              {cat.modules.map((mod, idx) => {
                const modLocked = checkModuleLocked(user, mod);
                return (
                  <div
                    key={`${mod.name}`}
                    className='sosafe-modulechanger-checkbox-layout'
                  >
                    {`${idx + 1}.`}
                    <Checkbox
                      className={'sosafe-modulechanger-checkbox'}
                      key={`checkbox_${mod.name}`}
                      name={`checkbox_${mod.name}`}
                      label={mod.name}
                      value={mod.progress === 100 || (mod.statusOfModule && mod.statusOfModule.includes('PASSED'))}
                      disabled={loading || modLocked || mod.progress === 100}
                      onChange={() => {
                        setModuleToPassed(mod);
                      }}
                    />
                    {modLocked && (
                      <LockIcon
                        key={`lock_icon_${mod.name}`}
                        className='sosafe-modulechanger-lockIcon'
                      />
                    )}
                  </div>
                );
              })}
            </Collapsible>
            <hr />
          </div>
        ))}
    </div >
  );
};
