import { useCategoriesRequest } from 'elearning/hooks/use-categories-request';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import { useSoSafeMutation } from 'shared/hooks/use-sosafe-mutation';
import { queryClient, queryKey, useSoSafeConnect } from 'shared/modules/sosafe-connect';
import ActionTypes from 'shared/utilities/action-types';
import { LANGUAGE_MANAGER } from 'shared/utilities/languages';
import { getIdByReference } from 'shared/utilities/reference-handling';
import { RootState } from 'state/reducers';

function useUser(propUser, reference) {
  const [user, setUser] = useState(propUser);
  const [prevReference, setPrevReference] = useState(reference);

  useEffect(() => {
    if (prevReference !== reference) {
      if (getIdByReference(reference) === 'CHANGE_ACCOUNT_SUCCESS') {
        setUser({
          isEdit: false,
          ...propUser,
        });
      }
      setPrevReference(reference);
    }
  }, [setPrevReference, propUser, reference, prevReference]);

  return {
    user,
    setUser: (data) => setUser({
      ...user,
      ...data,
    }),
  };
}

export const LangSwitch = () => {
  const { user, reference } = useSelector((state: RootState) => state.auth, shallowEqual);
  const { customer } = user;
  const { user: userData } = useUser(user, reference);
  const { t, i18n } = useTranslation(['translations', 'languagesTranslations']);
  const { refetch: refetchCategories } = useCategoriesRequest();
  const { endPoints } = useSoSafeConnect();
  const { mutate: updateAccount } = useSoSafeMutation({
    key: queryKey.UPDATE_USER_DATA,
    requestMethod: endPoints.put.updateUserData,
    requestType: ActionTypes.ACCOUNT_UPDATE_REQUEST,
    successType: ActionTypes.ACCOUNT_UPDATE_SUCCESS,
    failedType: ActionTypes.ACCOUNT_UPDATE_FAILED,
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey.ELEARNING]);
      refetchCategories();
    },
  });

  useEffect(() => {
    if (i18n.language !== user.language) {
      i18n.changeLanguage(user.language);
      window.document.documentElement.lang = user.language;
    }
  }, [i18n, user]);

  let langOptions: Array<any> = [];
  if (customer?.languages?.languages?.length) {
    let sortedLang: Array<any> = LANGUAGE_MANAGER.filter((lang) => user?.customer.languages.languages.find((key) => key === lang.key) || lang.key === user.language);
    sortedLang = sortedLang.map((lang) => lang.value).sort();
    sortedLang = sortedLang.map((value) => LANGUAGE_MANAGER.find((lang) => lang.value === value));
    langOptions = sortedLang.map((lang) => (
            <option
                key={lang.key}
                value={lang.key}
            >{lang.value}</option>
    ));
  } else {
    langOptions = [(
            <option
                key='en'
                value='en'
            >
                {t('languagesTranslations:en')}
            </option>
    )];
  }

  return (
        <Form.Group style={{ marginLeft: '0.5rem', width: '300px' }}>
            <Form.Label
                className='text-muted'
                htmlFor='language'
            >
                {t('translations:Language')}
            </Form.Label>
            <Form.Control
                as='select'
                defaultValue={i18n.language}
                className='custom-select'
                id='language'
                name='language'
                onChange={(e) => {
                  const data = {
                    firstname: userData.firstname,
                    lastname: userData.lastname,
                    language: e.currentTarget.value,
                    email: userData.email,
                    gender: userData.gender,
                  };
                  updateAccount(data);
                }}
            >
                {langOptions}
            </Form.Control>
        </Form.Group>
  );
};
