import { useSoSafeQuery } from 'shared/hooks/use-sosafe-query';
import { queryKey, useSoSafeConnect } from 'shared/modules/sosafe-connect';
import ActionTypes from 'shared/utilities/action-types';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { getUrlParameter } from 'shared/helpers';

/** Hook: Activate user account */
export const UseRequestActivateAccount = () => {
  const { endPoints } = useSoSafeConnect();
  const location = useLocation();
  const activate = getUrlParameter(location.search, 'activate');

  const fetch = useSoSafeQuery({
    enabled: false,
    key: queryKey.ACTIVATE_ACCOUNT,
    requestMethod: () => endPoints.fetch.activateAccount({ code: activate || '' }),
    requestType: ActionTypes.AUTH_ACTIVATE_REQUEST,
    successType: ActionTypes.AUTH_ACTIVATE_SUCCESS,
    failedType: ActionTypes.AUTH_ACTIVATE_FAILED,
  });

  useEffect(() => {
    if (activate) {
      fetch.refetch();
    }
  }, []);

  return fetch;
};
