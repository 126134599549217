import { calculateProgress } from "shared/components/progress-bar/progressbar.utils";
import useUser from "./use-user";

type useUserProgressType = {
  levelImage: string;
  levelName: string;
  progressBar: {
    totalXp: number;
    progress: number;
    curXp: number;
  };
};

export function useUserProgress(): useUserProgressType {
  const { user } = useUser({ enabled: false });

  const backUpImage =
    "https://resources.sosafe.de/v1/resource/77938ba614d32fe6/img/levels/level6.png";

  if (!user?.game?.progress)
    return {
      levelName: "Level 1",
      levelImage: backUpImage,
      progressBar: {
        totalXp: 0,
        progress: 0,
        curXp: 0,
      },
    };

  const { xp: userXp, level } = user.game.progress;

  const {
    image: levelImage,
    name: levelName,
    xp_max: nextXp,
    xp,
  } = level || {};

  const { percentageXp, currentLevelXp, maxLevelXp } = calculateProgress({
    minXp: xp,
    maxXp: nextXp,
    currentXp: userXp,
  });

  const progressBar = {
    totalXp: maxLevelXp,
    progress: percentageXp,
    curXp: currentLevelXp,
  };

  return {
    levelImage: levelImage || backUpImage,
    levelName,
    progressBar,
  };
}
