import { checkIsActivePresenter } from 'elearning/helpers';

type ShowModuleFeedbackArgs = {
  moduleData: {
    moduleCompleted: boolean,
    group: number,
    scorm_entry_point: string
  },
  moduleFeedback: string,
  moduleFeedbackGroups: Array<number>

};

/**
 * Helper function to check if a the feedback popup can be shown when a module is completed
 * @returns boolean
 */
export default function checkShowModuleFeedback({ moduleData, moduleFeedback: userShowFeedback, moduleFeedbackGroups: userSentFeedbacks }: ShowModuleFeedbackArgs) {
  const { moduleCompleted, group, scorm_entry_point } = moduleData;

  const isActivePresenter = checkIsActivePresenter(scorm_entry_point, { checkOnlyForDefaultModules: true });

  if (isActivePresenter && moduleCompleted && userShowFeedback && !userSentFeedbacks?.includes(group)) {
    return true;
  }

  return false;
}
