import { MODULE_STATUS } from "flamingo-e-learning-platform/training/types/module-status.type";

export const LEARNING_PATH_DISPLAY_BUTTON_BY_STATUS = new Map([
  [MODULE_STATUS.CONTINUE, { continue: true, retry: true, start: false }],
  [MODULE_STATUS.RESTART, { continue: false, retry: true, start: false }],
  [
    `${MODULE_STATUS.RESTART}complete`,
    { continue: false, retry: true, start: false },
  ],
  [MODULE_STATUS.START, { continue: false, retry: false, start: true }],
  [
    `${MODULE_STATUS.CONTINUE}complete`,
    { continue: false, retry: true, start: false },
  ],
  [MODULE_STATUS.AWARENESS_ASSESSMENT, { continue: false, retry: false, start: false }],
]);


export const LIBRARY_DISPLAY_BUTTON_BY_STATUS = new Map([
  [MODULE_STATUS.CONTINUE, { continue: true, retry: true, start: false }],
  [MODULE_STATUS.RESTART, { continue: false, retry: true, start: false }],
  [
    `${MODULE_STATUS.RESTART}complete`,
    { continue: false, retry: true, start: false },
  ],
  [MODULE_STATUS.START, { continue: false, retry: false, start: true }],
  [
    `${MODULE_STATUS.CONTINUE}complete`,
    { continue: false, retry: true, start: false },
  ],
  [MODULE_STATUS.AWARENESS_ASSESSMENT, { continue: false, retry: false, start: true }],
]);
