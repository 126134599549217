import { AxiosRequestConfig } from 'axios';
import { checkIsIE } from 'shared/helpers';

/** appends the randomIEget string to an existing requestConfig, if we're running in IE11, as IE, for whatever weird reason caches GET-answers based on the request... */
export default function addRandomIEGetParameters(requestConfig?: AxiosRequestConfig): AxiosRequestConfig {
  if (checkIsIE()) {
    const requestConfigParams = {
      ...requestConfig?.params,
      randomForIEGet: Math.floor(Math.random() * 100000),
    };
    return {
      ...requestConfig,
      params: requestConfigParams,
    };
  }
  return requestConfig || {};
}
