import { useState } from "react";
import useUser from "shared/hooks/use-user";
import { parseCodeLanguageToISO6391 } from "shared/utilities/languages";
import { AuthProps } from "types";

interface AccountState {
  gender: string;
  firstname: string;
  lastname: string;
  email: string;
  language: string;
}

type FieldKey = keyof AccountState;
type ErrorState = Record<FieldKey, string | null>;

const emptyAccountState: AccountState = {
  gender: "",
  firstname: "",
  lastname: "",
  email: "",
  language: "en",
}

const initialErrorState: ErrorState = {
  gender: null,
  firstname: null,
  lastname: null,
  email: null,
  language: null,
}

const getInitialAccountState = (user: AuthProps["user"] | undefined) => {
  if (!user) return emptyAccountState;

  return {
    gender: user?.gender?.toString() || "",
    firstname: user?.firstname || "",
    lastname: user?.lastname || "",
    email: user?.email || "",
    language: parseCodeLanguageToISO6391(user?.language || "en"),
  }
}

interface Props {
  validator: (key: FieldKey, value: string) => string | null;
}

export const useAccountState = ({ validator }: Props) => {
  const { user, loading } = useUser({});
  const [state, setState] = useState<AccountState>(getInitialAccountState(user));
  const [error, setError] = useState<Record<FieldKey, string | null>>(initialErrorState);

  const updateField = (key: FieldKey, value: string) => {
    setState((prev) => ({
      ...prev,
      [key]: value,
    }));

  }

  const validate = (key: FieldKey) => {
    const fieldError = validator(key, state[key]);

    setError((prev) => ({
      ...prev,
      [key]: fieldError,
    }));

    return fieldError;
  }

  const isValid = Object.values(error).every((err) => err === null);

  const validateAllFields = () => {
    const isAllFieldsValid = Object.keys(state).some((key) => {
      return !validate(key as FieldKey);
    });
    return isAllFieldsValid;
  }

  return {
    updateField,
    loading: loading || user === undefined,
    validate,
    validateAllFields,
    isValid,
    error,
    state
  }
}
