import { TRACKED_EVENTS } from "elearning/helpers";
import { useEffect } from "react";
import { translateAmplitudeEventToChameleon } from "shared/modules/chameleon/chameleon-middleware";
import { ProductAnalytics } from "shared/modules/product-analytics";
import { UserType } from "types";
import useChameleon from "./use-chameleon";

export default function useProductAnalytics(user: UserType | undefined) {
  const userId = user?.address_uuid ?? user?.uuid;
  const chameleon = useChameleon(user);

  useEffect(() => {
    const mandantUuid = user?.customer?.mandant?.uuid;
    const customerUuid = user?.customer?.uuid;
    const campaignUuid = user?.campaign?.uuid;

    if (userId && mandantUuid && customerUuid && campaignUuid) {
      try {
        ProductAnalytics.getInstance({ campaignUuid }).login({
          userId,
          mandantId: mandantUuid,
          customerId: customerUuid,
        });
        ProductAnalytics.getInstance().setInitialUserProperties({
          isElearningCode: !!user?.elearning_code,
        });

        ProductAnalytics.getInstance().addTrackMiddleware((eventName, eventData) => {
          const chameleonEventName = translateAmplitudeEventToChameleon(
            eventName as TRACKED_EVENTS,
            eventData as Record<string, string>
          );

          if (chameleonEventName) {
            const isPersonalizedLearning = user?.campaign.isPersonalizedLearning;
            const suffix = isPersonalizedLearning ? "_PL" : "";
            chameleon.track(`${chameleonEventName}${suffix}`);
          }
        });
      } catch (e) {
        console.error(e);
      }
    }
  }, [
    userId,
    user?.customer?.mandant?.uuid,
    user?.customer?.uuid,
    user?.campaign?.uuid,
    user?.elearning_code,
    user?.campaign.isPersonalizedLearning,
  ]);
}
