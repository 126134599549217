interface RuntimeConfig {
  [key: string]: string | boolean | number;
}

declare global {
  interface Window {
    elearningRuntimeConfig: RuntimeConfig;
    BUILD_DATE: string;
  }
}

// Fetch the runtime config with several attempts
function getRuntimeConfig(attempt = 1): Promise<RuntimeConfig> {
  const maxAttempts = 3;
  const runtimeConfigUrl = "/config.json";

  return fetch(runtimeConfigUrl).then((res) => {
    if (res.ok) {
      return res.json();
    }

    if (attempt < maxAttempts) {
      console.warn("Failed to fetch runtime config, running another attempt...");
      return getRuntimeConfig(attempt + 1);
    }

    console.error("Failed to fetch runtimme configuration!");
    console.error(res.statusText);
    return {};
  });
}

// Alternatively fetch the runtime config from process.env
function getRuntimeConfigFromEnv() {
  return Promise.resolve({
    API_URL: process.env.API_URL,
    RESOURCE_URL: process.env.RESOURCE_URL,
    LMS_URL: process.env.LMS_URL,
    ELEARNING_HOSTS: process.env.ELEARNING_HOSTS,
    DEPLOY_ENVIRONMENT: process.env.DEPLOY_ENVIRONMENT,
    SURVEY_HUB_API_URL: process.env.SURVEY_HUB_API_URL,
    CYPRESS_ENV: process.env.CYPRESS_ENV,
    // Amplitude tracking environment variables
    AMPLITUDE_ACTIVATED: process.env.AMPLITUDE_ACTIVATED,
    AMPLITUDE_VERBOSE: process.env.AMPLITUDE_VERBOSE,
    AMPLITUDE_API_KEY: process.env.AMPLITUDE_API_KEY,
    // Sumologic environment variables
    SUMOLOGIC_RUM_KEY: process.env.SUMOLOGIC_RUM_KEY,
    // Chameleon environment variables
    CHAMELEON_ACTIVE: process.env.CHAMELEON_ACTIVE,
    CHAMELEON_URL: process.env.CHAMELEON_URL,
    CHAMELEON_API_KEY: process.env.CHAMELEON_API_KEY,
    CHAMELEON_TOUR: process.env.CHAMELEON_TOUR,
    CHAMELEON_EVENT_ENABLED: process.env.CHAMELEON_EVENT_ENABLED,
    CHAMELEON_BLACKLIST: process.env.CHAMELEON_BLACKLIST as string,
    // Sentry
    SENTRY_DSN: process.env.SENTRY_DSN,
  });
}

// Fetch configuration
const runtimeConfigPromise =
  process.env.USE_RUNTIME_CONFIG === "true" ? getRuntimeConfig() : getRuntimeConfigFromEnv();

// Store runtime config before starting the app
runtimeConfigPromise.then((runtimeConfig) => {
  // Save the runtime config as an inmutable global object
  window.elearningRuntimeConfig = Object.freeze(runtimeConfig);

  // Bootstrap the app once we got the runtime config
  import(/* webpackMode: "eager" */ "./bootstrap");
});

export {};
