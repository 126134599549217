import React from 'react';
import { DownloadDataType } from 'downloads/downloads.types';
import ResponsivePicture from 'shared/components/responsive-picture';
import './download-preview.component.css';

interface DownloadPreviewProps {
  /** material to render the preview image of */
  download: DownloadDataType;
}

/** renders a contained image preview */
export default function DownloadPreview(props: DownloadPreviewProps) {
  const { download: download } = props;
  return (
        <div className='sosafe-DownloadPreview'>
            <ResponsivePicture
                className='sosafe-DownloadPreview-image'
                src={download.image || ''}
                alt={download.title || ''}
            />
        </div>
  );
}
