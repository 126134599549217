import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGetBadge } from "shared/hooks/use-get-badge";
import { Card } from "react-bootstrap";
import Badge from "elearning/components/badge";
import { BADGE_VIEWS } from "elearning/helpers";
import AchievementCardHeader from "../achievement-card-header";
import { filterBadgesCard } from "./utils";
import { BadgeSection } from "./badge-sections";
import "./badges-card.scss";

interface BadgesCardProps {
  className: string;
}

export const BadgesCard = ({ className }: BadgesCardProps) => {
  const { data: badgeResponse, isLoading, error } = useGetBadge();
  const { receivedAmount, sections } = useMemo(
    () => filterBadgesCard(badgeResponse?.data.result || []),
    [badgeResponse]
  );
  const { t } = useTranslation("translations");

  if (isLoading) return null;
  if (error) return <p>Could not load badge</p>;

  return (
    <Card className={`shadow badge-card ${className}`}>
      <Card.Body>
        <AchievementCardHeader
          titleJsx={
            <div className="d-flex align-items-baseline">
              <h4 className="h3 mr-3">{t("Badges")}</h4>
              <span className="badge-list-text-primary h4">{`${receivedAmount} / ${badgeResponse?.data.result
                  ? badgeResponse?.data.result.length
                  : 0
                }`}</span>
            </div>
          }
          info={t("achievement badges tooltip")}
        />

        <div className="d-flex flex-column px-0 px-lg-4 badge-list-max-width mx-auto">
          {sections.map((badges, index) => (
            <BadgeSection
              key={index}
              row={badges[0]?.row ?? 0}
              isLast={index === badges.length - 1}
            >
              {badges.map((badge) => (
                <Badge
                  key={badge.id}
                  badge={badge}
                  fromView={BADGE_VIEWS.ACHIEVEMENTS_PAGE.BADGE_PANEL}
                  height={100}
                />
              ))}
            </BadgeSection>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};
