import { useMutation } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import {
  queryClient, queryKey, SendSurveyProps, useSoSafeConnect,
} from 'shared/modules/sosafe-connect';

interface UseSurveyProps {
  survey: number,
  onSuccess(): void
  onError(): void
}

export const useSurvey = ({ survey, onSuccess, onError }: UseSurveyProps) => {
  const { endPoints } = useSoSafeConnect();

  const surveyRef = useRef<number>(survey);
  useEffect(() => {
    surveyRef.current = survey;
  }, [survey]);

  const mutation = useMutation(
    (result: SendSurveyProps['result']) => endPoints.post.sendSurvey({ survey: surveyRef.current, result }),
    {
      onSuccess: () => {
        queryClient.setQueryData([queryKey.SURVEY], (prevData: any) => ({
          ...prevData,
          result: [],
        }));

        onSuccess();
      },
      onError: () => {
        onError();
      },
    },
  );

  return {
    ...mutation,
    saveSurvey: mutation.mutate,
  };
};
