/**
 * takes a date constructor value and returns a formatted string in the format DD.MM.YYYY
 *
 * @param {number | string} [value] optional date constructor value - if undefined, will default to now.
 * @returns {string} DD.MM.YYYY string
 *
 * @category shared
 * @subcategory helpers
 * @exports dateToDDMMYYYY
 */

export default function dateToDDMMYYYY(value?: number | string): string {
  const date = value !== undefined ? new Date(value) : new Date();
  const dateString = date.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
  return dateString;
}
