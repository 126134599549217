import { CategoryProps } from "types";

/**
  * @desc Group categories by group id { [group_id]: [categories] }
*/
export const groupCategoryByGroupId = (categories: CategoryProps[]): Record<number, CategoryProps[]> =>
  categories.reduce((acc, category) => {
    if (!acc[category.group]) return { ...acc, [category.group]: [category] };
    return { ...acc, [category.group]: [...acc[category.group], category] }
  }, {});

/**
  * @desc Calculate category progress by group id
  example will take in count the progression of each category in the same group and return the percentage of completion of all categories in the group in the default language category object
  * @return - Array of categories with the progress of the group
  * NOTE: the progress is returned as decimal number, if you want to get the percentage multiply by
  * 100
  */
export const calculateCategoryProgressByGroupId = (categories: Array<CategoryProps[]>, defaultLanguage: string) => categories.reduce((acc: CategoryProps[], group: CategoryProps[]) => {
  const userLanguageCategory = group.find(category => category.language === defaultLanguage);
  if (!userLanguageCategory) return acc;
  const progress = group.reduce((accProgress, category) => accProgress + Math.floor(category.progress), 0) / (group.length * 100);
  return [...acc, { ...userLanguageCategory, progress }];
}, []);



