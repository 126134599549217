/**
 * Set up a global context for in cookies which can be used by other services (Mainly LMS)
 * Read more https://sosafegmbh.atlassian.net/browse/BP-124?focusedCommentId=216475
 * Main reason is for LMS to be able to access the user/customer information without having
 * to call be-app-api service for every file it serves in file server
 * Currently it includes customer id only it can be extended to have other information
 *
 * contextToken may be undefined if to be backward compatible with older versions of be-app-api
 */

const WEEK = 1000 * 60 * 60 * 24 * 7;
export const COOKIE_NAME = 'sosafe-context';

export function getWhitelistedDomain(url: string): string {
  const match = url.match(/(?:https?:\/\/)(?:[^@\n]+@)?(?:www\.)?([^:/\n]+)(?::\d+)?/);
  if (!match?.length) {
    throw new Error(`Invalid URL: ${url}`);
  }
  const parts = match[1].split('.');
  const domain = parts.slice(-2).join('.');
  return `.${domain}`;
}

export function setCookie(cname, cvalue, expiry, domain) {
  const d = new Date();
  d.setTime(d.getTime() + expiry);
  const expires = `expires= ${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/;domain=${domain}`;
}

export default async function setContextInCookie(contextToken?: string): Promise<void> {
  if (!contextToken) return;
  try {
    const domain = getWhitelistedDomain(window.elearningRuntimeConfig.API_URL as string);
    setCookie(COOKIE_NAME, contextToken, WEEK, domain);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn('Error setting context in cookies', err);
  }
}
