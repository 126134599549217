/**
 * @deprecated since survey-hub implementation
 */
import { useContext } from 'react';
import { SurveyContext } from './survey-context';

export const useSurveyContext = () => {
  const context = useContext(SurveyContext);
  if (!context) throw 'useSurveyContext needs to be wrapped by <SurveyProvider/>';
  return context;
};
