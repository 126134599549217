import { Fragment } from 'react';
import { Button, clsx } from '@sosafe-platform-engineering/fe-lib-ui-react';

import './policies-stepper.component.css';

export type PoliciesStep = {
  active: boolean;
  visited: boolean;
  error: boolean;
  onClick?: (stepNumber: number) => void;
};

interface PoliciesStepperProps {
  steps: PoliciesStep[];
  className?: string;
}

export default function PoliciesStepper(props: PoliciesStepperProps) {
  const {
    steps,
    className,
  } = props;
  return (
        <div className={clsx(className, 'u-flex u-flexAlignItemsCenter')}>
            {steps.length > 1 && steps.map((step, idx) => (
                <Fragment key={`policyStepper_${idx}`}>
                    {idx > 0 && <hr className='sosafe-PoliciesStepper-spacer' />}
                    <Button
                        ariaLabel={`${idx + 1}`}
                        className={clsx(
                          {
                            'is-visited': step.visited,
                            'is-error': step.error,
                            'is-active': step.active,
                          },
                          'sosafe-PoliciesStepper-step',
                        )}
                        onClick={() => step.onClick?.(idx)}
                    >
                        {`${idx + 1}`}
                    </Button>
                </Fragment>
            ))}
        </div>
  );
}
