import { useHistory } from 'react-router';
import { useSoSafeMutation } from 'shared/hooks/use-sosafe-mutation';
import { UseGlobalSettingsContext } from 'shared/modules/global-settings/state/global-settings-context.provider';
import { queryKey, useSoSafeConnect } from 'shared/modules/sosafe-connect';
import ActionTypes from 'shared/utilities/action-types';

export const useSendRegisterAccountRequest = () => {
  const { endPoints, setApiKey, setShowLoadingScreen } = useSoSafeConnect();
  const history = useHistory();

  const { setLocalToken } = UseGlobalSettingsContext();

  return useSoSafeMutation({
    key: queryKey.REGISTER_USER,
    requestMethod: endPoints.post.registerUser,
    requestType: ActionTypes.AUTH_REGISTER_REQUEST,
    successType: ActionTypes.AUTH_REGISTER_SUCCESS,
    failedType: ActionTypes.AUTH_REGISTER_FAILED,
    onSuccess: (response) => {
      if (response.data.result) {
        setApiKey(response.data.result.apikey);
      }
      if (response?.data?.result?.customer?.token) {
        setLocalToken(response.data.result.customer.token);
      }
      history.replace('/');
      setShowLoadingScreen(false);
    },
    onError: () => {
      setShowLoadingScreen(false);
    },
    onBeforeMutate: () => {
      setShowLoadingScreen(true);
    }
  });
};
