import React from "react";
import { Divider } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { IconByType, ModalAlertConfig } from "./shared";
import styles from "./modal-body.module.css";

export const ModalBody = ({ content, title, footer, type }: ModalAlertConfig) => (
  <div>
    <div className={styles.container}>
      {IconByType[type]}
      {title !== undefined && <div className={styles.centerText}>{title}</div>}
      <div className={styles.centerText} >
        {content}
      </div>
    </div>
    <Divider aria-hidden="true"/>
    <div className={styles.footer}>
      {footer}
    </div>
  </div>
);
