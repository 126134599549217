import { useSoSafeMutation } from 'shared/hooks/use-sosafe-mutation';
import { queryKey, useSoSafeConnect } from 'shared/modules/sosafe-connect';
import ActionTypes from 'shared/utilities/action-types';

export const useActivateAccount = () => {
  const { endPoints } = useSoSafeConnect();

  return useSoSafeMutation({
    key: queryKey.ACTIVATE_ACCOUNT,
    requestMethod: endPoints.post.sendAccountActivation,
    requestType: ActionTypes.AUTH_SEND_ACTIVATE,
    successType: ActionTypes.AUTH_SEND_ACTIVATE_SUCCESS,
    failedType: ActionTypes.AUTH_SEND_ACTIVATE_FAILED,
  });
};
