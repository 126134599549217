import ContentPage from "flamingo-e-learning-platform/content/content.page";
import { GlobalConfig } from "index-config";
import { ModalContextProvider } from "modal-context/modal-context";
import React from "react";
import { Route, Switch } from "react-router";
import Loading from "shared/components/loading";
import { Router404 } from "./router-404";
import { IRouterConfig } from "./router-type";
import styles from "./router.module.css";
import { getAllowedRouteControllers } from "./utils";

interface Props {
  routerConfigs: Array<IRouterConfig>;
}

function RouterSwitchComponent({ routerConfigs }: Props) {
  const allowedRoutes = getAllowedRouteControllers(routerConfigs);

  if (allowedRoutes.loading || allowedRoutes.routeControllers.length === 0)
    return (
      <div className={styles.LoadingPage}>
        <ContentPage>
          <div className={styles.LoadingContainer}>
            <Loading />
          </div>
        </ContentPage>
      </div>
    );

  /* from the list of allowed routers, always show the first one of the array
   * the first router has the highest priority.
   */
  const currentRoute = allowedRoutes.routeControllers[0];

  const routesJsx = currentRoute.routes.map((route) => (
    <Route key={route.path} exact path={route.path} render={route.render} />
  ));

  const { Wrapper } = currentRoute;

  return (
    <Switch>
      <ModalContextProvider>
        <Wrapper>
          <React.Fragment>
            <GlobalConfig />
            {routesJsx}
            <Router404 currentRouterController={currentRoute} />
          </React.Fragment>
        </Wrapper>
      </ModalContextProvider>
    </Switch>
  );
}

export const RouterSwitch = React.memo(RouterSwitchComponent);
