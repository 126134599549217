import { useSoSafeMutation } from 'shared/hooks/use-sosafe-mutation';
import { UseGlobalSettingsContext } from 'shared/modules/global-settings/state/global-settings-context.provider';
import { queryKey, useSoSafeConnect } from 'shared/modules/sosafe-connect';
import ActionTypes from 'shared/utilities/action-types';
import { UseReferenceHandler } from 'shared/utilities/use-reference-handler';

export const useRegistrationRequest = () => {
  const { endPoints, setApiKey, setShowLoadingScreen } = useSoSafeConnect();
  const { setLocalToken } = UseGlobalSettingsContext();
  const checkReference = UseReferenceHandler();

  return useSoSafeMutation({
    key: queryKey.REGISTER_USER,
    requestMethod: endPoints.post.registerUser,
    requestType: ActionTypes.AUTH_REGISTER_REQUEST,
    successType: ActionTypes.AUTH_REGISTER_SUCCESS,
    failedType: ActionTypes.AUTH_REGISTER_FAILED,
    onBeforeMutate: () => {
      setShowLoadingScreen(true);
    },
    onSuccess: (response) => {
      setShowLoadingScreen(false);
      if (response.data.result) {
        setApiKey(response.data.result.apikey);

        // customer only returned in the request when registering with google sso,
        // when registering with code, it's undefined
        if (response.data.result.customer) {
          setLocalToken(response.data.result.customer.token);
        }
      }
      checkReference({ reference: response.data.reference });
    },
    onError: () => {
      setShowLoadingScreen(false);
    }
  });
};
