export async function copyString(content, onCopyStateChange) {
  let success = false;

  // use modern method first:
  if (navigator.clipboard) {
    navigator.clipboard.writeText(content)
      .then(() => onCopyStateChange(1))
      .catch(() => onCopyStateChange(2));
    return true;
  }
  // fallback to old Method
  const el = document.createElement('textarea');
  el.value = content;
  el.setAttribute('readonly', '');
  el.style.position = 'fixed';
  el.style.opacity = '0';
  document.body.appendChild(el);
  el.select();
  try {
    success = document.execCommand('copy'); // Security exception may be thrown by some browsers.
  } catch (ex) {
    success = false;
  } finally {
    document.body.removeChild(el);
  }
  return onCopyStateChange(success ? 1 : 2);
}
