import React, {
  ReactNode,
  createContext,
  useContext,
  useRef,
  MutableRefObject,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { Notification } from './types';
import debounce from 'lodash/debounce';

type NotificationSystemContextProps = {
  notifications?: MutableRefObject<Notification[]>;
  setNotifications: (notification: Notification | Notification[]) => void;
  isVisible: boolean;
  setNotificationVisible: Dispatch<SetStateAction<boolean>>;
  clearNotificationQueue: () => void;
  /**
     * It is useful to perform any action when all the modals are closed
     * ie. this is used on the PlayerPage to show NextModulePopup
     */
  setNotificationCallback: (callback: () => void) => void;
  notificationCallback?: MutableRefObject<(() => void) | undefined>;
};

type NotificationSystemProviderProps = {
  children: ReactNode;
};

export const NotificationSystemContext = createContext<NotificationSystemContextProps>({
  setNotifications: () => undefined,
  isVisible: false,
  setNotificationVisible: () => undefined,
  clearNotificationQueue: () => undefined,
  setNotificationCallback: (callback: () => void) => callback(),
});

const NotificationSystemProvider = (props: NotificationSystemProviderProps) => {
  const notifications = useRef<Notification[]>([]);
  const notificationCallback = useRef<() => void>();

  const [isVisible, setIsVisible] = useState(false);

  const setNotifications = (notification: Notification | Notification[]) => {
    if (Array.isArray(notification)) {
      notifications.current.push(...notification);
    } else {
      notifications.current.push(notification);
    }

    // Debounce function waits all pushNotificationToQueue calls for FeedbackModal and Rewards Modals
    const callNotification = debounce(() => {
      setIsVisible(true);
    }, 300);

    callNotification();
  };

  const clearNotificationQueue = () => {
    notifications.current = [];
    notificationCallback.current = undefined;
  };

  const setNotificationCallback = (callback: () => void) => {
    notificationCallback.current = callback;

    setTimeout(() => {
      if (!notifications?.current?.length) {
        callback();
      }
    }, 300);
  };

  return (
        <NotificationSystemContext.Provider
            value={{
              notifications,
              setNotifications,
              isVisible,
              setNotificationVisible: setIsVisible,
              clearNotificationQueue,
              setNotificationCallback,
              notificationCallback,
            }}
        >
            {props.children}
        </NotificationSystemContext.Provider>
  );
};

export const useNotificationSystemContext = () => {
  return useContext(NotificationSystemContext);
};

export default NotificationSystemProvider;
