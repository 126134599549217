export enum NotificationType {
  FEEDBACK = 'feedbackModal',
  XP = 'xpModal',
  LEVEL = 'levelModal',
  BADGE = 'badgeModal',
}

export type Feedback = {
  moduleGroup?: number;
};
export type Rewards = {
  xp?: number;
  level?: any;
  badges?: Array<any>;
};

export type Notification = {
  type: NotificationType;
  displayOrder: number;
} & Feedback &
  Rewards;
