import { useQuery } from "@tanstack/react-query";
import { queryKey, useSoSafeConnect } from "shared/modules/sosafe-connect";

interface UseGetIsRecalculationLPNeededProps {
  userId?: number;
  /* Set this to `false` to disable automatic refetching when the query mounts or changes query keys.
  To refetch the query, use the `refetch` method returned from the `useQuery` instance.
  Defaults to `true`. */
  enabled: boolean;
}

/**
 * Fetch the survey result of survey the user has answered
 */
export const useIsRecalculationLPNeeded = ({
  userId,
  enabled,
}: UseGetIsRecalculationLPNeededProps) => {
  const { endPoints, apiKey } = useSoSafeConnect();
  const { data, isLoading } = useQuery(
    [queryKey.LEARNING_PATH_RECALCULATION_NEEDED],
    () =>
      endPoints.learningPathApi.fetch.getShouldRecalculateLearningPath({
        userId: userId || 0,
      }),
    {
      enabled: enabled && !!apiKey && !!userId,
      retry: false,
      // no need to fetch from be after the first time
      staleTime: Infinity,
    }
  );

  return {
    isRecalculationNeeded: data?.data,
    isRecalculationNeededLoading: isLoading,
  };
};
