import React from "react";
import { useTranslation } from "react-i18next";
import NoResultsSVG from "../../svgs/noresults.svg";
import style from "./no-results.module.css";

export const NoResults = () => {
  const { t } = useTranslation("flamingo");
  return (
    <div className={style.container}>
      <div>
        <NoResultsSVG />
      </div>
      <p>{t("no-results")}</p>
    </div>
  );
};
