import { useSoSafeMutation } from 'shared/hooks/use-sosafe-mutation';
import { queryKey, useSoSafeConnect } from 'shared/modules/sosafe-connect';
import ActionTypes from 'shared/utilities/action-types';

export const useItWasntMeRequest = () => {
  const { endPoints } = useSoSafeConnect();

  return useSoSafeMutation({
    key: queryKey.ACTIVATE_ACCOUNT,
    requestMethod: endPoints.delete.itWasntme,
    requestType: ActionTypes.AUTH_ITWASNTME_REQUEST,
    successType: ActionTypes.AUTH_ITWASNTME_SUCCESS,
    failedType: ActionTypes.AUTH_ITWASNTME_FAILED,
  });
};
