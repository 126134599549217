import { useClearLoginData } from "authentication/hooks/use-clear-login-data";
import { queryKey, useSoSafeConnect } from "shared/modules/sosafe-connect";
import { useSoSafeQuery } from "shared/hooks/use-sosafe-query";
import { ReferenceAction } from "shared/modules/sosafe-connect/handle-reference";
import ActionTypes from "shared/utilities/action-types";

/** fetch categories and cache it */
export const useCategoriesRequest = (fetchOnMount?: boolean) => {
  const { endPoints, apiKey } = useSoSafeConnect();
  const clearLoginData = useClearLoginData();

  return useSoSafeQuery({
    enabled: !!apiKey && !!fetchOnMount,
    requestMethod: endPoints.fetch.getElearningApi,
    key: queryKey.ELEARNING,
    requestType: ActionTypes.CATEGORIES_FETCH_REQUEST,
    successType: ActionTypes.CATEGORIES_FETCH_SUCCESS,
    failedType: ActionTypes.CATEGORIES_FETCH_FAILED,
    onError: (error: any) => {
      if (
        error?.response?.data?.reference &&
        ReferenceAction.shouldLogout(error.response.data.reference)
      ) {
        clearLoginData(true);
      }
    },
    retry: (failureCount, error) => {
      if (
        error?.response?.data?.reference &&
        ReferenceAction.shouldLogout(error.response.data.reference)
      )
        return false;
      return failureCount < 3;
    },
  });
};
