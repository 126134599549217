import { useState, useEffect } from "react";
import { UserType } from "types";

let chameleonInitialised = false;
const chameleonToken = window.elearningRuntimeConfig.CHAMELEON_API_KEY;
const CHAMELEON_BLACKLIST =
  (window.elearningRuntimeConfig.CHAMELEON_BLACKLIST as string)?.split(",").map((i) => i.trim()) ||
  [];

const useChameleon = (user?: UserType | undefined) => {
  const [isChameleonActive, setIsChameleonActive] = useState<boolean>(
    window.elearningRuntimeConfig.CHAMELEON_ACTIVE === "true"
  );

  useEffect(() => {
    if (!user || chameleonInitialised) {
      return;
    }

    const isBlacklisted = user?.customer?.uuid && CHAMELEON_BLACKLIST.includes(user.customer.uuid);
    const isSupportedLanguage = user?.language && ["de", "en"].includes(user.language);

    if (isBlacklisted || !isSupportedLanguage) {
      setIsChameleonActive(false);
      return;
    }

    if (isChameleonActive) {
      import("@chamaeleonidae/chmln")
        .then((module) => {
          if (!chameleonInitialised) {
            module.init(chameleonToken, { fastUrl: window.elearningRuntimeConfig.CHAMELEON_URL });
            chameleonInitialised = true;

            module.identify(user?.address_uuid ?? user?.uuid, {
              language: user?.language ?? window.navigator.language,
              role: user?.email?.endsWith("sosafe.de") ? "employee" : "customer",
              company: {
                uid: user?.customer?.uuid,
              },
            });
          }
        })
        .catch((error) => {
          console.error("Error loading Chameleon module:", error);
        });
    }
  }, [isChameleonActive, user]);

  // Define methods that conditionally call the Chameleon functions
  const identify = (...args: any[]) => {
    if (chameleonInitialised) {
      window.chmln.identify(...args);
    }
  };

  const track = (...args: any[]) => {
    if (chameleonInitialised) {
      window.chmln.track(...args);
    }
  };

  const clear = (...args: any[]) => {
    if (chameleonInitialised) {
      window.chmln.clear(...args);
    }
  };

  return {
    isSupportedLanguage: (language: string) => ["en", "de"].includes(language),
    identify,
    track,
    clear,
  };
};

export default useChameleon;
