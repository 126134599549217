import React, { useEffect } from "react";
import { useSoSafeConnect } from "shared/modules/sosafe-connect";
import useUser from "shared/hooks/use-user";
import { useLocation } from "react-router-dom";
import showHeader from "shared/helpers/personalizedLearning/show-header";
import QaPage from "flamingo-e-learning-platform/utilities/qa.page";
import ContentPage from "flamingo-e-learning-platform/content/content.page";
import useAuthentication from "authentication/hooks/use-authentication";
import FlamingoHeader from "../../../flamingo-e-learning-platform/header/header";

export const name = "QaPageRouteController";

export const routes = [
  {
    path: "/qa-page",
    render: () => <QaPage />,
  },
];

export const defaultRoute = "/";

export const Wrapper = ({ children }: { children: JSX.Element }) => {
  const { signOut } = useAuthentication();
  const { pathname } = useLocation();
  const enableHeader = showHeader(pathname);

  useEffect(() => {
    import("../../../theme/flamingo");
  }, []);

  return (
    <React.Fragment>
      {enableHeader && (
        <FlamingoHeader handleSignOutClick={signOut} minimizedView={false} />
      )}
      <ContentPage>{children}</ContentPage>
    </React.Fragment>
  );
};

// List of allowed qa-agent emails for automated testing
const allowedEmails = [
  "e2e.elearning.mantine@catchall.sosafe-shared.de",
  "component.test@catchall.sosafe-shared.de",
];

export const UseIsPathAllowed = () => {
  const { apiKey } = useSoSafeConnect();
  const { user, loading } = useUser({});
  const loadingAuthorization = apiKey ? loading : false;
  const isQaAgentEmail = allowedEmails.includes(user?.email || "");

  return {
    allowed: !!apiKey && isQaAgentEmail,
    loading: loadingAuthorization,
  };
};
