import React from 'react';
import { useTranslation } from 'react-i18next';
import { clsx } from '@sosafe-platform-engineering/fe-lib-ui-react';
import './feedback.css';

interface RatingSelectionProps {
  setRecommendation(n: number): void,
  recommendation: number,
}

/**
 * Stateless component showing a star rating selection
 */
export const RecommendationSelection = ({ recommendation, setRecommendation }: RatingSelectionProps) => {
  const { t } = useTranslation('moduleFeedbackTranslations');

  return (
        <div
            data-testid='recommendation-section'
            className='d-flex flex-wrap justify-content-between align-items-center my-4 ModuleFeedback-recommendationSelect'
        >
            <small className='ModuleFeedback-recommendationLegend'><span className='d-md-none'>0 = </span>{t('recommendation_low')}</small>
            <div className='ModuleFeedback-recommendations'>
                {[...Array(11)].map((x, i) => (
                    <React.Fragment key={`rating_${i}`}>
                        {(i === 6) && (<div className='d-none d-sm-block ModuleFeedback-breakFlex' />)}
                        {(i > 0 && i % 4 === 0) && (<div className='d-block d-sm-none ModuleFeedback-breakFlex' />)}
                        <div
                            className={ clsx({ 'is-selected': recommendation >= i }, 'ModuleFeedback-recommendationNumberWrapper')}
                            role='button'
                            onClick={() => setRecommendation(i)}
                            aria-label={`${i} ${t('x_from_y')} 10`}
                        >
                            <div className='ModuleFeedback-recommendationNumber' >
                                <span>{i}</span>
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </div>
            <small className='ModuleFeedback-recommendationLegend'><span className='d-md-none'>10 = </span>{t('recommendation_high')}</small>
        </div>
  );
};
