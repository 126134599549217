import React from "react";
import { MODULE_STATUS } from "flamingo-e-learning-platform/training/types/module-status.type";
import { PersonalizedModule } from "flamingo-e-learning-platform/training/types/module.type";
import { Trans } from "react-i18next";

/*
 * Enum for the keys of the messages to be displayed
 */
enum OVER_DUE_MESSAGES_KEY {
  VERY_BEHIND = "veryBehind",
  BEHIND = "behind",
}

/*
 * Map describing which component to render for each case
 * Add here more cases if needed
 */
const overDueMessageComponentMap = {
  [OVER_DUE_MESSAGES_KEY.VERY_BEHIND]: (nModules: number) => (
    <p data-testid="due-date-message-behind">
      <Trans i18nKey={"flamingo:behind"} values={{ nModules }} />
    </p>
  ),
  [OVER_DUE_MESSAGES_KEY.BEHIND]: () => (
    <Trans i18nKey={"flamingo:veryBehind"}>
      <p data-testid="due-date-message-very-behind">
        You’re behind schedule – but don’t worry, catching up is fast and easy!
      </p>
    </Trans>
  ),
};

/**
 * Returns the modules that are overdue that have not been complete
 */
const getOverDueModules = (modules: PersonalizedModule[], date: string) => {
  const currentDate = new Date(date).getTime();
  return modules.filter((module) => {
    if (
      module.finished_by === null ||
      module.status === MODULE_STATUS.RESTART
    ) {
      return false;
    }
    const moduleFinishByDate = new Date(module.finished_by).getTime();
    return moduleFinishByDate < currentDate;
  });
};

/**
 * Returns the key for the message to be displayed based on the number of overdue modules
 * if no overdue modules, the key is null
 */
const getOverDueMessageKey = (nOverDueModules: number) => {
  if (nOverDueModules === 0) return null;

  if (nOverDueModules <= 4) {
    return OVER_DUE_MESSAGES_KEY.VERY_BEHIND;
  }

  return OVER_DUE_MESSAGES_KEY.BEHIND;
};

/**
 * Returns a function that return a react component with the appropriate message to be displayed based on the number of overdue modules
 * if user has no overdue dates return null
 */
export const getOverDueMessage = (
  modules: PersonalizedModule[],
  date: string
) => {
  const overDueModules = getOverDueModules(modules, date);
  const key = getOverDueMessageKey(overDueModules.length);
  if (!key) return null;
  return () => overDueMessageComponentMap[key](overDueModules.length);
};
