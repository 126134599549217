import React from "react";
import { useTranslation } from "react-i18next";
import { SoSafeLogo } from "shared/svgs";
import { getBackgroundSVG } from "shared/svgs/helper/svg-helper";
import { GlobalSettings } from "shared/modules/global-settings/hooks/use-global-settings.hooks";
import { useHistory } from "react-router";
import ResponsivePicture from "../responsive-picture";
import "./logo-header.css";

interface Props {
  theme: GlobalSettings
  className?: string
}

const Logo = ({ theme, className }: Props) => {
  const { logo: src } = theme;
  const { t } = useTranslation('translations');
  const history = useHistory();
  const fallbackImage = getBackgroundSVG(SoSafeLogo);

  const onClickBrand = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    history.push('/');
  };

  if (!src) {
    return (
      <button
        className={`background-position-left-center background-repeat-no-repeat background-size-contain header-logo fallback-image ${className}`}
        aria-label={t("footerLogo")}
        onClick={onClickBrand}
        style={{
          backgroundImage: fallbackImage,
        }}
      />
    );
  }

  return <button
    aria-label={t("footerLogo")}
    className={className}
    onClick={onClickBrand}
  >
    <ResponsivePicture
      src={src}
      className='header-logo'
      position='left center'
      fit='contain'
      alt={t("footerLogo")}
    />
  </button>
}

export default Logo;
