import Loading from "shared/components/loading";
import './loading-query.screen.css';
import React from 'react';
import { useTranslation } from "react-i18next";

export const LoadingQueryScreen = () => {
  const { t } = useTranslation('translations');

  return <div className="loading-query-screen_content">
    <div className='row justify-content-center w-100'>
      <div className='col-12 col-sm-9 col-md-7 col-lg-5 col-xl-4'>
        <div className='card shadow w-100'>
          <div className='d-flex py-4 flex-column'>
            <p className='mx-auto text-center'>{t('Loading ...')}</p>
            <div className='mx-auto fs-color-primary text-center'>
              <Loading className='mx-auto' />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

}
