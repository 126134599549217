import { useEffect } from 'react';
import i18n from 'lang/i18n';
import { checkIsIE } from 'shared/helpers';
import { UseLoginRequest } from './use-login-request';
import { LOGIN_METHOD } from 'types';
import { useRegisterUser } from './use-register-user';

// this is our google app Client ID, search for 'Google' in 1PW
const CLIENT_ID = '629842949877-aknkg3ojtc55s2u3qrbfhivcr76mpdcm.apps.googleusercontent.com';

const loadScript = (domDocument, jsSrc, id) => new Promise((resolve, reject) => {
  if (domDocument?.head?.findElementById?.(id).length) {
    resolve();
  }
  const script = domDocument.createElement('script');
  script.id = id;
  script.src = jsSrc;
  script.onerror = reject;
  script.onload = resolve;
  domDocument?.head?.appendChild?.(script);
});

export default function useGoogleIdentityServices() {
  const { login } = UseLoginRequest();
  const { register } = useRegisterUser();

  const init = async (requestType) => {
    await loadScript(document, 'https://accounts.google.com/gsi/client', 'google-identity-services');
    window.google.accounts.id.initialize({
      client_id: CLIENT_ID,
      callback: (result) => {
        if (requestType === 'REGISTER') {
          register({ sso: result.credential, method: 'GOOGLE' });
        } else {
          login({ sso: result.credential, method: LOGIN_METHOD.GOOGLE });
        }
      },
      ux_mode: 'popup',
    });
    window.google.accounts.id.renderButton(
      document.getElementById('g_id_signin'),
      {
        theme: 'outline',
        size: 'large',
        logo_alignment: 'left',
        locale: i18n.language,
      },
    );
  };

  useEffect(() => () => {
    window.google?.accounts?.id?.cancel?.();
    const googleIdentity = document.getElementById('google-identity-services');
    if (checkIsIE()) {
      googleIdentity?.parentElement.removeChild(googleIdentity);
    } else {
      googleIdentity?.remove();
    }
  }, []);

  return {
    init,
  };
}
