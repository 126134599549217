function getLuminance(rgb) {
  const [r, g, b] = rgb.map((v) => {
    const channel = v / 255;
    return channel <= 0.03928
      ? channel / 12.92
      : ((channel + 0.055) / 1.055) ** 2.4;
  });

  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

function getContrastRatio(luminance1, luminance2) {
  const brightest = Math.max(luminance1, luminance2);
  const darkest = Math.min(luminance1, luminance2);
  return (brightest + 0.05) / (darkest + 0.05);
}

export const getContrastColor = (backgroundColor) => {
  const rgb = backgroundColor
    .slice(1) // Remove the '#' from the hex code
    .match(/.{2}/g) // Match each pair of hex digits
    .map((hex) => parseInt(hex, 16)); // Convert each pair to an integer

  const backgroundLuminance = getLuminance(rgb);
  const blackLuminance = getLuminance([0, 0, 0]);

  // Calculate the contrast ratio between the background and black
  const contrastWithBlack = getContrastRatio(backgroundLuminance, blackLuminance);

  // Return black or white based on which has a better contrast ratio (with a minimum of 4.5:1)
  return contrastWithBlack >= 4.5 ? '#000000' : '#FFFFFF';
};
