import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, clsx } from "@sosafe-platform-engineering/fe-lib-ui-react";
import Loading from "shared/components/loading";
import useInitTranslations from "lang/use-init-translations.hook";
import { UseReferenceHandler } from 'shared/utilities/use-reference-handler';
import ActionTypes from "shared/utilities/action-types";
import {
  queryClient,
  queryKey,
  SubmitPolicyAnswers,
  useSoSafeConnect,
} from "shared/modules/sosafe-connect";
import useUser from "shared/hooks/use-user";
import translations from "./lang";
import { PolicyType } from "./policies.types";
import PoliciesCard from "./components/policies-card/policies-card.component";
import PolicyView from "./components/policy-view/policy-view.component";
import PoliciesStepper, {
  PoliciesStep,
} from "./components/policies-stepper/policies-stepper.component";
import "./policies.page.css";

/**
 *
 * @category policy
 * @exports PoliciesPage
 * @component
 */
export default function PoliciesPage() {
  const { translationsReady } = useInitTranslations(translations, "policy");
  const { user } = useUser({});
  const policies: PolicyType[] = user?.game?.policies || [];
  const [loading, setLoading] = useState(false);
  const [policyState, setPolicyState] = useState(0);
  const [policyAnswers, setPolicyAnswers] = useState<SubmitPolicyAnswers[]>([]);
  const dispatch = useDispatch();
  const { t } = useTranslation(["policy", "translations"]);
  const { endPoints } = useSoSafeConnect();
  const checkReference = UseReferenceHandler();

  const submitAnswers = () => {
    setLoading(true);
    dispatch({ type: ActionTypes.SEND_POLICIES_ANSWERS_REQUEST });

    endPoints.post
      .submitPolicyAnswers(policyAnswers)
      .then((response) => {
        dispatch({
          payload: checkReference(response.data),
          type: ActionTypes.SEND_POLICIES_ANSWERS_SUCCESS,
        });
        checkReference(response.data);
        queryClient.invalidateQueries([queryKey.AUTH]);
      })
      .catch((error) => {
        if (error.response) {
          checkReference(error.response.data);
        } else {
          checkReference({ status: error.statusCode });
        }
      })
      .finally(() => setLoading(false));
  };

  const acceptedAllMandatoryPolicies = policies.every((policy) => {
    if (!policy.mandatory) return true;
    const answer = policyAnswers.find(
      (policyAnswer) => policyAnswer.group === policy.group
    );
    return answer?.accepted;
  });

  const policiesSteps: PoliciesStep[] = policies.map((policy, idx) => {
    const thisPolicyAnswer = policyAnswers.find(
      (policyAnswer) => policyAnswer.group === policy.group
    );
    const visited = thisPolicyAnswer?.touched ?? false;
    const error = visited && policy.mandatory && !thisPolicyAnswer?.accepted;
    return {
      active: idx === policyState,
      visited,
      error,
      onClick: setPolicyState,
    };
  });

  if (!translationsReady || !policies.length) {
    return (
      <div className="sosafe-PoliciesPage-loading">
        <Loading />
      </div>
    );
  }
  if (policies.length === 0 || !policies[policyState]) return null;

  return (
    <section className="sosafe-PoliciesPage">
      <h2 className="h1 sosafe-PoliciesPage-title">{t("policy:title")}</h2>
      <h3 className="sosafe-PoliciesPage-subtitle">{t("policy:subtitle")}</h3>
      <PoliciesCard>
        <PoliciesStepper
          steps={policiesSteps}
          className={clsx(
            { "is-hidden": policiesSteps.length <= 1 },
            "sosafe-PoliciesPage-stepper"
          )}
        />
        <PolicyView
          policy={policies[policyState]}
          policyAnswers={policyAnswers}
          setPolicyAnswers={setPolicyAnswers}
        />
        <div className="sosafe-PoliciesPage-buttons">
          {policyState !== 0 && (
            <Button.Outline
              className="sosafe-PoliciesPage-button"
              onClick={() => setPolicyState(policyState - 1)}
              disabled={loading}
              ariaLabel={t("translations:Back")}
            >
              {t("translations:Back")}
            </Button.Outline>
          )}
          {policyState + 1 < policies.length && (
            <Button
              className="sosafe-PoliciesPage-button"
              onClick={() => setPolicyState(policyState + 1)}
              disabled={loading}
              ariaLabel={t("translations:Next")}
            >
              {t("translations:Next")}
            </Button>
          )}
          {policyState + 1 === policies.length && (
            <Button
              className="sosafe-PoliciesPage-button"
              onClick={submitAnswers}
              disabled={loading || !acceptedAllMandatoryPolicies}
              ariaLabel={t("policy:submit")}
            >
              {t("policy:submit")}
            </Button>
          )}
        </div>
      </PoliciesCard>
    </section>
  );
}
