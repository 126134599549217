import { TRACKED_EVENTS } from 'elearning/helpers';

/** Translate amplitude events to chameleon events,
 * since we can't send data object to chameleon (only event name), we have added more
 * TRACKED_EVENT only for chameleon, and use the data to parse the event name.
 */
export const translateAmplitudeEventToChameleon = (
  event: TRACKED_EVENTS,
  data: Record<string, boolean | string | number>
) => {
  switch (event) {
    case TRACKED_EVENTS.MODULE_COMPLETION_STATUS: {
      // translate to chameleon if the user has passed the module
      if ('passed' in data) {
        return data.passed
          ? TRACKED_EVENTS.MODULE_COMPLETION_PASSED
          : TRACKED_EVENTS.MODULE_COMPLETION_FAILED;
      }
      return null;
    }

    default:
      return event;
  }
};
