import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { clsx, ChevronDownIcon, ChevronUpIcon } from '@sosafe-platform-engineering/fe-lib-ui-react';

interface CollapseProps {
  className?: string,
  Title: ()=> React.ReactElement,
  children: React.ReactElement
  initialCollapsed?: boolean,
}

export default function Collapse({
  initialCollapsed = false, className, Title, children,
}: CollapseProps) {
  const { t } = useTranslation('translations');
  const [collapsed, setCollapsed] = useState(initialCollapsed);

  return (
        <React.Fragment>
            <div
                className={clsx('u-flex u-flexJustifyBetween u-flexAlignContentCenter', className)}
                onClick={() => setCollapsed(!collapsed)}
                aria-label={t(collapsed ? 'Expand' : 'Collapse')}
                role='button'
            >
                <Title />
                {collapsed ? <ChevronDownIcon /> : <ChevronUpIcon />}
            </div>
            {!collapsed && <div>{ children }</div>}
        </React.Fragment>
  );
}
