/**
 * @deprecated since survey-hub implementation
 */
import React from "react";
import { AxiosResponse } from "axios";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  getSurveyReturnProps,
  queryClient,
  queryKey,
} from "shared/modules/sosafe-connect";

export const SurveyFailed = () => {
  const { t } = useTranslation(["translations", "messageTranslations"]);

  const onClose = () => {
    queryClient.setQueryData<AxiosResponse<getSurveyReturnProps>>(
      [queryKey.SURVEY],
      (currentResponse) => {
        if (!currentResponse) return currentResponse;
        return {
          ...currentResponse,
          data: {
            reference: currentResponse.data.reference,
            result: [],
          },
        };
      }
    );
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row">
        <h3 className="mb-2 h2">{t("translations:Error")}</h3>
      </div>

      <div className="d-flex flex-column">
        <p>{t("messageTranslations:An error has occured")}</p>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <Button className="ml-auto py-1" onClick={onClose}>
          {t("translations:Close")}
        </Button>
      </div>
    </div>
  );
};
