import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { checkIsIE } from "shared/helpers";
import {
  URL_ACCOUNT_API,
  URL_ACTIVATE_API,
  URL_ANALYTICS_API,
  URL_AUTH_API,
  URL_DOWNLOADS_API,
  URL_ELEARNING_API,
  URL_FEEDBACK_API,
  URL_GAME_API,
  URL_HINT_API,
  URL_MODULEFEEDBACK_API,
  URL_NOTIFICATION_API,
  URL_OPTIN_API,
  URL_SURVEY_API,
} from "shared/helpers/url/url";
import { surveyHubApi } from "survey-hub/api/survey-hub.api";
import { learningPathApi } from "personalized-learning/api/learning-path.api";
import { AuthProps, LOGIN_METHOD, CategoryProps } from "types";
import { getBadgesResponse } from "./response-type";

/** *************** START FETCH *************** */

export type FetchAuthorizationReturnType = Pick<AuthProps, "reference"> & {
  result: AuthProps["user"];
};
interface AuthorizationProps {
  manuallyApiKey?: string;
}

interface SoSafeResponse<T> {
  reference: string;
  result: T;
}

/** fetch data from /authorization endpoint */
const fetchAuthorization = (axiosInstance: AxiosInstance, props: AuthorizationProps) => {
  const paramData = {
    params: { area: 1 },
    ...(props.manuallyApiKey ? { headers: { Authorization: props.manuallyApiKey } } : {}),
  };
  return axiosInstance.get<FetchAuthorizationReturnType>(
    `${URL_AUTH_API()}/authorization`,
    paramData
  );
};

export type LogoutReturnType = { reference: string; result: null };

/* Logout user from platform */
const logout = (axiosInstance: AxiosInstance) =>
  axiosInstance.get<LogoutReturnType>(`${URL_AUTH_API()}/logout`, {});

type ReportProps = { code: string; target: string };

/** report to backend? */
const report = (axiosInstance: AxiosInstance, props: ReportProps) =>
  axiosInstance.get<any>(`${window.elearningRuntimeConfig.API_URL}report`, {
    params: {
      type: 2,
      ...props,
    },
  });

export type RegisterUserProps = {
  subdomain: string;
  email?: string;
  sso?: string;
  method?: string;
  code?: string;
  key?: string;
};

/** register a new user */
const getRegisterUser = (axiosInstance: AxiosInstance, props: RegisterUserProps) =>
  axiosInstance.get<any>(`${URL_AUTH_API()}/register`, { params: { ...props, area: 1 } });

const fetchUserIp = (axiosInstance: AxiosInstance) =>
  axiosInstance.get<any>(`${window.elearningRuntimeConfig.API_URL}user/ip`);

type GetCertificateProps = {
  customerId: string;
};

// https://dev.sosafe.de/v1/elearning/1997/certificate
// Content-Type: application/pdf
//
const getCertificate = (
  axiosInstance: AxiosInstance,
  { customerId }: GetCertificateProps,
  config: AxiosRequestConfig
) => axiosInstance.get<any>(`${URL_ELEARNING_API()}/${customerId}/certificate`, config);

const getNotifications = (axiosInstance: AxiosInstance) => {
  const data = checkIsIE()
    ? { params: { randomForIEGet: Math.floor(Math.random() * 100000) } }
    : {};
  return axiosInstance.get<any>(URL_NOTIFICATION_API(), data);
};

const getHints = (axiosInstance: AxiosInstance) => axiosInstance.get<any>(URL_HINT_API());

const getElearningApi = (axiosInstance: AxiosInstance) =>
  axiosInstance.get<SoSafeResponse<CategoryProps[]>>(URL_ELEARNING_API(), { params: { area: 1 } });

interface ActivateAccountProps {
  code: string;
}

const activateAccount = (axiosInstance: AxiosInstance, { code }: ActivateAccountProps) =>
  axiosInstance.get<any>(`${URL_AUTH_API()}/activate`, { params: { code } });

const getBadges = (axiosInstance: AxiosInstance) =>
  axiosInstance.get<getBadgesResponse>(`${URL_GAME_API()}/badges`, { params: { area: 1 } });

const getActivities = (axiosInstance: AxiosInstance) =>
  axiosInstance.get<any>(`${URL_GAME_API()}/activities`, { params: { area: 1 } });

export interface SurveyProps {
  created: string;
  customer_id: number;
  description: string;
  enabled: boolean;
  form: string;
  from: string;
  id: number;
  language: string;
  mandatory: boolean;
  name: string;
  to: string;
  tracking: boolean;
  ui: string;
}
export interface getSurveyReturnProps {
  reference: string;
  result: Array<SurveyProps>;
}

const getSurvey = (axiosInstance: AxiosInstance) =>
  axiosInstance.get<getSurveyReturnProps>(URL_SURVEY_API());

/** *************** END FETCH *************** */

/** *************** START POST *************** */
export interface LoginProps {
  password: string;
  code: string;
  email: string;
  sso: string;
  method?: LOGIN_METHOD;
}

/* Login user into platform with right credentials */
const login = (axiosInstance: AxiosInstance, props: LoginProps) =>
  axiosInstance.post<FetchAuthorizationReturnType>(`${URL_AUTH_API()}/login`, {
    ...props,
    area: 1,
  });

type RequestPasswordResetProps = { email: string; subdomain: string };

const requestPasswordReset = (axiosInstance: AxiosInstance, props: RequestPasswordResetProps) =>
  axiosInstance.post<any>(`${URL_AUTH_API()}/password`, { area: 1, ...props });

export type DownloadProps = { id: number };

const download = (axiosInstance: AxiosInstance, props: DownloadProps, config: AxiosRequestConfig) =>
  axiosInstance.post<any>(`${URL_DOWNLOADS_API()}/download`, props, config);

export type SubmitPolicyAnswers = {
  touched?: true;
  group: number;
  accepted: boolean;
};

const submitPolicyAnswers = (axiosInstance: AxiosInstance, props: SubmitPolicyAnswers[]) =>
  axiosInstance.post<any>(`${URL_ELEARNING_API()}/policy`, props);

export enum LessonStatus {
  PASSED = "passed",
  COMPLETE = "complete",
  FAILED = "failed",
  PAUSED = "paused",
  INCOMPLETE = "incomplete",
  BROWSED = "browsed",
  NOT_ATTEMPTED = "not attempted",
}

export type UpdateElearning = {
  version: number;
  score: number;
  progress?: number;
  suspend_data: string;
  module: any;
  lesson_status: LessonStatus;
};

const updateElearning = (axiosInstance: AxiosInstance, props: UpdateElearning) =>
  axiosInstance.post<any>(URL_ELEARNING_API(), props);

export type OptInProps = {
  opt_in: boolean;
};

const optIn = (axiosInstance: AxiosInstance, props: OptInProps) =>
  axiosInstance.post<any>(URL_OPTIN_API(), { ...props, area: 1 });

export type SendFeedbackProps = {
  message: string;
  email: string;
  rating: number;
  module: string;
};

const sendFeedback = (axiosInstance: AxiosInstance, props: SendFeedbackProps) =>
  axiosInstance.post<any>(URL_FEEDBACK_API(), props);

export enum ANALYTICS_TYPE {
  CERT_DOWNLOADED = 6,
  HINT_VIEWED = 7,
  PAGE_VIEWED = 8,
  QUICKSTART_USED = 11,
  TOUR_DONE = 15,
  TOUR_CANCELLED = 16,
}

type SendSoSafeAnalyticsProps = {
  site: number;
  type: ANALYTICS_TYPE;
};

const sendSoSafeAnalytics = (axiosInstance: AxiosInstance, props: SendSoSafeAnalyticsProps) =>
  axiosInstance.post<any>(URL_ANALYTICS_API(), props);

type SurveyAnswerText = string;
type SurveyAnswerOption = { values: string; isCorrect: boolean };
type SurveyAnswerTypes = SurveyAnswerText | SurveyAnswerOption;
export type SendSurveyProps = { survey: number; result: Record<string, SurveyAnswerTypes> };

const sendSurvey = (axiosInstance: AxiosInstance, props: SendSurveyProps) => {
  const stringifiedResult = JSON.stringify(props.result);
  return axiosInstance.post<any>(URL_SURVEY_API(), {
    survey: props.survey,
    result: stringifiedResult,
  });
};

type SendQuestionnaireAnswersProps = {
  /** question Group for this question. equal number between all languages */
  group: number;

  /** value of the selected answer for this question group */
  answer: boolean;
};
const sendQuestionnaireAnswers = (
  axiosInstance: AxiosInstance,
  props: SendQuestionnaireAnswersProps[]
) => axiosInstance.post<any>(`${URL_ELEARNING_API()}/answer`, props);

/** register a new user */
const registerUser = (axiosInstance: AxiosInstance, props: RegisterUserProps) => {
  const getSubdomain = () => {
    const subdomain = window.location.hostname.split(".");
    if (subdomain.length > 0) {
      return subdomain[0];
    }
    return window.location.hostname;
  };
  const subdomain = getSubdomain();
  return axiosInstance.post<any>(`${URL_AUTH_API()}/register`, { ...props, area: 1, subdomain });
};

interface SendAccountActivationProps {
  email: string;
  subdomain?: string;
}

const sendAccountActivation = (axiosInstance: AxiosInstance, props: SendAccountActivationProps) =>
  axiosInstance.post<any>(`${URL_AUTH_API()}/activate`, props);

export interface SendModuleFeedbackProps {
  message: string;
  module: string;
  rating?: number;
  recommendation?: number;
}

const sendModuleFeedback = (axiosInstance: AxiosInstance, props: SendModuleFeedbackProps) =>
  axiosInstance.post<any>(URL_MODULEFEEDBACK_API(), { ...props });

/** *************** END POST *************** */

/** *************** START PUT *************** */

type ResetPasswordProps = { password: string; code: string };

/** reset user password */
const resetPassword = (axiosInstance: AxiosInstance, props: ResetPasswordProps) =>
  axiosInstance.put<any>(`${URL_AUTH_API()}/password`, { ...props, area: 1 });

type UpdateNotificationProps = { id: string };

const updateNotification = (axiosInstance: AxiosInstance, props: UpdateNotificationProps) =>
  axiosInstance.put<any>(URL_NOTIFICATION_API(), props);

type UpdateUserDataProps = {
  firstname: string;
  lastname: string;
  language: string;
  email: string;
  gender: string;
};

const updateUserData = (axiosInstance: AxiosInstance, props: UpdateUserDataProps) =>
  axiosInstance.put<any>(URL_ACCOUNT_API(), props);

/** *************** END PUT *************** */

/** *************** START DELETE *************** */
interface ItWasntMeProps {
  code: string;
}

const itWasntme = (axiosInstance: AxiosInstance, props: ItWasntMeProps) =>
  axiosInstance.delete<any>(URL_ACTIVATE_API(), { params: props });

/** *************** END DELETE *************** */

/** all endpoints method to retrieve data from soSafe server/backend */
export const endPoints = (axiosInstance: AxiosInstance) => ({
  fetch: {
    authorization: (props: AuthorizationProps) => fetchAuthorization(axiosInstance, props),
    logout: () => logout(axiosInstance),
    report: (props: ReportProps) => report(axiosInstance, props),
    fetchUserIp: () => fetchUserIp(axiosInstance),
    getCertificate: (props: GetCertificateProps, config: AxiosRequestConfig) =>
      getCertificate(axiosInstance, props, config),
    getNotifications: () => getNotifications(axiosInstance),
    getHints: () => getHints(axiosInstance),
    getElearningApi: () => getElearningApi(axiosInstance),
    activateAccount: (props: ActivateAccountProps) => activateAccount(axios, props),
    getRegisterUser: (props: RegisterUserProps) => getRegisterUser(axiosInstance, props),
    getBadges: () => getBadges(axiosInstance),
    getActivities: () => getActivities(axiosInstance),
    getSurvey: () => getSurvey(axiosInstance),
  },
  post: {
    login: (props: LoginProps) => login(axiosInstance, props),
    requestPasswordReset: (props: RequestPasswordResetProps) =>
      requestPasswordReset(axiosInstance, props),
    download: (props: DownloadProps, config: AxiosRequestConfig) =>
      download(axiosInstance, props, config),
    submitPolicyAnswers: (props: SubmitPolicyAnswers[]) =>
      submitPolicyAnswers(axiosInstance, props),
    updateElearning: (props: UpdateElearning) => updateElearning(axiosInstance, props),
    optIn: (props: OptInProps) => optIn(axiosInstance, props),
    sendFeedback: (props: SendFeedbackProps) => sendFeedback(axiosInstance, props),
    sendSoSafeAnalytics: (props: SendSoSafeAnalyticsProps) =>
      sendSoSafeAnalytics(axiosInstance, props),
    sendSurvey: (props: SendSurveyProps) => sendSurvey(axiosInstance, props),
    sendQuestionnaireAnswers: (props: SendQuestionnaireAnswersProps[]) =>
      sendQuestionnaireAnswers(axiosInstance, props),
    registerUser: (props: RegisterUserProps) => registerUser(axiosInstance, props),
    sendAccountActivation: (props: SendAccountActivationProps) =>
      sendAccountActivation(axiosInstance, props),
    sendModuleFeedback: (props: SendModuleFeedbackProps) =>
      sendModuleFeedback(axiosInstance, props),
  },
  put: {
    resetPassword: (props: ResetPasswordProps) => resetPassword(axiosInstance, props),
    updateNotification: (props: UpdateNotificationProps) =>
      updateNotification(axiosInstance, props),
    updateUserData: (props: UpdateUserDataProps) => updateUserData(axiosInstance, props),
  },
  delete: {
    itWasntme: (props: ItWasntMeProps) => itWasntme(axiosInstance, props),
  },
});

export type EndpointsProps = ReturnType<typeof endPoints> & {
  surveyHubApi: ReturnType<typeof surveyHubApi>;
  learningPathApi: ReturnType<typeof learningPathApi>;
};
