import { CategoryProps } from 'types';

/**
 * Search for category by category_name and version, if version is nullsh
 * it will ignore the property version when searching
 * @param categories Array of categories to search
 * @param categoryKey string compared against prop category_name
 * @param version number compare against the category version
 * @returns Category Object
 */
const getCategoryByKeyAndVersion = (
  categories: CategoryProps[] | undefined,
  categoryKey: string,
  version?: number,
) => categories?.find(
  (c) => c.category_name === categoryKey
  && (version ? c.version === version : true),
);

export default getCategoryByKeyAndVersion;
