// Packages
import PulseLoader from 'react-spinners/PulseLoader';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import CustomButton from 'shared/components/custom-button';
import { FeedbackForm } from './feedback-form';
import { feedbackSwal } from './use-feedback';

import './feedback-modal.css';
import { queryClient, queryKey, useSoSafeConnect } from 'shared/modules/sosafe-connect';

export function FeedbackModal(props) {
  const { t } = useTranslation(['translations', 'messageTranslations']);
  const [stars, setStars] = useState(0);
  const [{ message, email }, setFromData] = useState({ message: '', email: '' });
  const { endPoints } = useSoSafeConnect();
  const [response, setResponse] = useState({});
  const [formState, setFormState] = useState('INIT');

  const { user } = props;

  const sendFeedback = () => {
    setFormState('LOADING');

    return endPoints.post.sendFeedback({
      message, email, rating: stars, module: '',
    })
      .then((resp) => {
        setResponse({ data: resp.data, status: resp.status });
        queryClient.invalidateQueries([queryKey.AUTH]);
      })
      .catch((error) => setResponse({ error }));
  };

  useEffect(() => {
    const {
      status,
      error,
    } = response;

    let timer = null;
    if ((status && status !== 200) || error) {
      timer = setTimeout(() => {
        setFormState('ERROR');
      }, 500);
    } else if ((status && status === 200)) {
      timer = setTimeout(() => {
        setFormState('SUCCESS');
      }, 500);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [response]);

  const FeedbackState = useCallback(() => {
    switch (formState) {
      case 'ERROR':
        return (
                    <React.Fragment>
                        <h2 className='mb-2 h1'>{t('Error')}</h2>
                        <div>
                            <p>{t('messageTranslations:An error has occured')}</p>
                            <CustomButton
                                variant='outline-secondary'
                                label={t('Close')}
                                onClick={() => feedbackSwal.close()}
                            />
                        </div>
                    </React.Fragment>
        );
      case 'SUCCESS':
        return (
                    <React.Fragment>
                        <h2 className='mb-2 h1'>{t('Thank you!')}</h2>
                        <div>
                            <p>{t('Your feedback has been sent successfully.')}</p>
                            <CustomButton
                                variant='outline-secondary'
                                label={t('Close')}
                                onClick={() => feedbackSwal.close()}
                            />
                        </div>
                    </React.Fragment>
        );
      case 'LOADING':
        return (
                    <div
                        data-testid='feedback-modal-error'
                        className='d-flex flex-column'
                    >
                        <span className='mb-4'>{t('Sending feedback')}...</span>
                        <PulseLoader
                            sizeUnit='rem'
                            size={10}
                            loading
                        />
                    </div>
        );

      case 'INIT':
        return null;

      default:
        return null;
    }
  }, [formState]);

  return formState === 'INIT' ? (
        <React.Fragment>
            <h2 className='mb-2 h1'>{t('Feedback')}</h2>
            <FeedbackForm
                language={user?.language}
                setStars={(rating) => setStars(rating)}
                stars={stars}
                onChange={(dat) => setFromData({ message, email, ...dat })}
                sendFeedback={sendFeedback}
            />
        </React.Fragment>
  ) : <FeedbackState/>;
}
