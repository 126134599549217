import { useSoSafeQuery } from 'shared/hooks/use-sosafe-query';
import { queryKey, useSoSafeConnect } from 'shared/modules/sosafe-connect';
import ActionTypes from 'shared/utilities/action-types';

export const useGetSurvey = (fetchOnMount?: boolean) => {
  const { endPoints } = useSoSafeConnect();

  return useSoSafeQuery({
    enabled: !!fetchOnMount,
    key: queryKey.SURVEY,
    requestMethod: endPoints.fetch.getSurvey,
    requestType: ActionTypes.SURVEY_FETCH_REQUEST,
    successType: ActionTypes.SURVEY_FETCH_SUCCESS,
    failedType: ActionTypes.SURVEY_FETCH_FAILED,
  });
};
