import { AxiosInstance } from "axios";
import { URL_PL_USERS_API } from "shared/helpers/url/url";

interface ShouldRecalculateLearningPathProps {
  userId: number;
}
const getShouldRecalculateLearningPath = async (
  axiosIstance: AxiosInstance,
  { userId }: ShouldRecalculateLearningPathProps
) => axiosIstance.get(`${URL_PL_USERS_API()}/${userId}/recalculate-learning-path`);

const putRecalculateLearningPath = async (
  axiosIstance: AxiosInstance,
  { userId }: ShouldRecalculateLearningPathProps
) => axiosIstance.put(`${URL_PL_USERS_API()}/${userId}/recalculate-learning-path`);

const isUserReadyToUsePL = (axiosInstance: AxiosInstance, loginId: number) => axiosInstance.get(`${URL_PL_USERS_API()}/${loginId}`)

export const learningPathApi = (axiosInstance: AxiosInstance) => ({
  fetch: {
    getShouldRecalculateLearningPath: (props: ShouldRecalculateLearningPathProps) =>
      getShouldRecalculateLearningPath(axiosInstance, props),
    isUserReadyToUsePL: (loginId: number) => isUserReadyToUsePL(axiosInstance, loginId),
  },
  put: {
    putRecalculateLearningPath: (props: ShouldRecalculateLearningPathProps) =>
      putRecalculateLearningPath(axiosInstance, props),
  },
});
