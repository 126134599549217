import { MODULE_STATUS } from 'flamingo-e-learning-platform/training/types/module-status.type';
import { PersonalizedModule } from 'flamingo-e-learning-platform/training/types/module.type';
import { MODULE_FILTER_OPTIONS } from '../../types/module-filter-options';

export const filterByModuleStatus = (
  module: PersonalizedModule,
  moduleStatusFilter: MODULE_FILTER_OPTIONS[]
) => {
  const filterRules = new Map([
    [
      MODULE_FILTER_OPTIONS.MANDATORY,
      () => moduleStatusFilter.includes(MODULE_FILTER_OPTIONS.MANDATORY) && module.mandatory,
    ],
    [
      MODULE_FILTER_OPTIONS.OVERDUE,
      () =>
        moduleStatusFilter.includes(MODULE_FILTER_OPTIONS.OVERDUE) &&
        module.finished_by &&
        module.status !== MODULE_STATUS.RESTART &&
        new Date(module.finished_by).getTime() < new Date().getTime(),
    ],
    [MODULE_FILTER_OPTIONS.ALL_LESSONS, () => true],
  ]);

  return moduleStatusFilter.some((filter) => filterRules.get(filter)?.());
};

export default function filterModules(
  personalizedModules: PersonalizedModule[],
  selectedCategory: string,
  moduleStatusFilter: MODULE_FILTER_OPTIONS
) {
  return personalizedModules.filter(
    (module: PersonalizedModule) =>
      (selectedCategory === "all" ||
      selectedCategory === String(module.category_id)) &&
      filterByModuleStatus(module, [moduleStatusFilter])
  );
}
