import {
  EventProperties,
  UserProperties,
  UserPropertyValue,
  LoginOptions,
  ProductAnalyticsProvider,
  ProductAnalyticsProviderOptions,
  ProductAnalyticsProviderInterface,
} from '@sosafe-platform-engineering/fe-lib-product-analytics';
import { GlobalEventProperties, QueueEvents } from './product-analytics.type';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import pkgJson from '../../../../package.json';

type TrackMiddleware = (eventName: string, eventProperties: Record<string, unknown>) => void;

export type ProductAnalyticsElearning = Omit<ProductAnalyticsProviderInterface, "trackEvent"> & {
  setInitialUserProperties: (args: UserProperties) => Promise<void>;
  executeQueuedEvents: () => void;
  addTrackMiddleware: (callback: TrackMiddleware) => void;
  trackEvent: (eventName: string, eventProperties?: EventProperties) => Promise<void>;
};

export class ProductAnalytics implements ProductAnalyticsElearning {
  private static instance: ProductAnalyticsElearning;

  private static globalEventProperties: GlobalEventProperties;

  protected analytics: ProductAnalyticsProviderInterface;

  private isLoggedIn = false;

  private queueEvents: QueueEvents[] = [];

  private isInitialUserPropertySet = false;

  /** Array off callbacks in whish is called when a amplitude track get's triggered */
  private trackMiddleware: Array<TrackMiddleware> = [];

  constructor() {
    const apiKey = window.elearningRuntimeConfig.AMPLITUDE_API_KEY as string | undefined;
    const activated = window.elearningRuntimeConfig.AMPLITUDE_ACTIVATED === 'true';
    const enableAnalytics =
      activated &&
      !!apiKey &&
      !!pkgJson.version;
    const verbose = window.elearningRuntimeConfig.AMPLITUDE_VERBOSE === 'true';

    const options: ProductAnalyticsProviderOptions = {
      apiKey: apiKey ?? 'missing api_key',
      appVersion: pkgJson.version ?? '0.0.0',
      active: !!enableAnalytics,
      verbose,
    };
    this.analytics = new ProductAnalyticsProvider(options);
  }

  static getInstance(globalEventProperties?: GlobalEventProperties) {
    if (!ProductAnalytics.instance) {
      ProductAnalytics.instance = new ProductAnalytics() as ProductAnalyticsElearning;
    }

    if (globalEventProperties) {
      ProductAnalytics.globalEventProperties = globalEventProperties;
    }

    return ProductAnalytics.instance;
  }

  login(args: LoginOptions) {
    return new Promise<void>((resolve, reject) => {
      this.analytics
        .login(args)
        .then(() => {
          this.isLoggedIn = true;
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  logout() {
    this.isLoggedIn = false;
    return this.analytics.logout();
  }

  trackEvent(eventName: string, eventProperties: EventProperties = {}) {
    return new Promise<void>((resolve) => {
      if (this.isLoggedIn && this.isInitialUserPropertySet) {
        this.analytics
          .trackEvent(eventName, { ...eventProperties, ...ProductAnalytics.globalEventProperties })
          .then((result) => {
            resolve();
            this.trackMiddleware.forEach((callback) => {
              callback(eventName, {
                ...eventProperties,
                ...ProductAnalytics.globalEventProperties,
              });
            });
            return result;
          })
          .catch((error) => {
            console.warn(error);
            // resolve even if the request fail because it should not matter for the end user
            resolve();
          });
      } else {
        this.queueEvents.push({ eventName, eventProperties });
        resolve();
      }
    });
  }

  setUserProperty(key: string, value: UserPropertyValue) {
    return this.analytics.setUserProperty(key, value);
  }

  setUserProperties(args: UserProperties) {
    return this.analytics.setUserProperties(args);
  }

  incrementUserProperty(key: string, incrementBy: number) {
    return this.analytics.incrementUserProperty(key, incrementBy);
  }

  async setInitialUserProperties(args: UserProperties) {
    try {
      this.analytics.setUserProperties(args);
      this.isInitialUserPropertySet = true;
      this.executeQueuedEvents();
    } catch (err) {
      console.warn('Error while trying to set initial user properties');
    }
  }

  executeQueuedEvents() {
    this.queueEvents.forEach(({ eventName, eventProperties }) =>
      this.trackEvent(eventName, eventProperties)
    );
    this.queueEvents = [];
  }

  addTrackMiddleware(callback: TrackMiddleware) {
    this.trackMiddleware.push(callback);
  }
}
