// Utilities
import ActionTypes from 'shared/utilities/action-types';
import { STEP_TYPES } from 'shared/utilities/tour-configs';
import { store } from 'state/store/store';
import { tourGetConfig, tourSeen, tourMobileCheck } from '../../helpers';
import { ANALYTICS_TYPE } from 'shared/modules/sosafe-connect';

function disableRoot(disable) {
  const rootElem = document.querySelector('#root');
  return disable ? rootElem?.setAttribute('aria-hidden', 'true') : rootElem?.removeAttribute('aria-hidden');
}

interface TourStartProps {
  id: string;
}

interface TourCancelProps {
  skipAnalytics: boolean;
}

interface tourNextStep {
  forceTour?: string;
  forceLastStep?: string;
}

export const useTour = (sendAnalytics: ({ type }: { type: ANALYTICS_TYPE }) => void) => {
  /** Adds first step id of specified tour to tour reducer. Also adds tour id to reducer */
  function tourStart({ id }: TourStartProps) {
    const newId = tourMobileCheck({ id });

    const config = tourGetConfig(newId);
    const { steps } = config;
    const firstStep = steps?.[0];
    const { type, id: stepId } = firstStep || {};
    // aria disable root-element
    disableRoot(true);
    if (type && ![STEP_TYPES.START, STEP_TYPES.CENTER, STEP_TYPES.FINISH].includes(type)) {
      const elem = document.getElementById(stepId);
      if (!elem) {
        return tourNextStep({ forceTour: newId, forceLastStep: stepId });
      }
    }

    return (dispatch) => {
      dispatch({ type: ActionTypes.TOUR_CHANGED, tour: newId, step: stepId });
    };
  }

  /** Removes all tour specific data from reducer */
  function tourCancel({ skipAnalytics }: TourCancelProps) {
    const { tour } = store.getState().tour;
    // aria reenable root-element
    disableRoot(false);

    if (!skipAnalytics && !tourSeen(tour)) {
      sendAnalytics({ type: ANALYTICS_TYPE.TOUR_CANCELLED });
    }
    return (dispatch) => {
      dispatch({ type: ActionTypes.TOUR_CHANGED, tour: null, step: null });
    };
  }

  function tourPrevStep() {
    const { tour, step: lastStepId } = store.getState().tour;

    const config = tourGetConfig(tour);
    const { steps } = config;
    const lastStepIndex = steps.findIndex((s) => s.id === lastStepId);

    if (lastStepIndex === 0) return () => {
      /** */
    };

    const nextIndex = lastStepIndex - 1;
    const nextStep = steps[nextIndex];
    const nextId = nextStep.id;

    return (dispatch) => {
      dispatch({ type: ActionTypes.TOUR_CHANGED, tour, step: nextId });
    };
  }

  function tourNextStep({ forceTour, forceLastStep }: tourNextStep) {
    const state = store.getState().tour;
    const tour = forceTour !== undefined && forceTour !== null ? forceTour : state.tour;
    const lastStepId = forceLastStep !== undefined && forceLastStep !== null ? forceLastStep : state.step;

    const config = tourGetConfig(tour);
    const { steps } = config;
    const lastStepIndex = steps?.findIndex((s) => s.id === lastStepId);
    const lastStep = steps?.[lastStepIndex];

    const { type } = lastStep || {};
    if (type === STEP_TYPES.FINISH) {
      sendAnalytics({ type: ANALYTICS_TYPE.TOUR_DONE });
      return tourCancel({ skipAnalytics: true });
    }

    const nextIndex = lastStepIndex + 1;
    const nextStep = steps?.[nextIndex] || steps?.[0];
    const { type: nextType, id: nextId } = nextStep || {};

    if (nextType && ![STEP_TYPES.START, STEP_TYPES.CENTER, STEP_TYPES.FINISH].includes(nextType)) {
      const elem = document.getElementById(nextId);
      if (!elem) {
        // get next valid step
        const newNextStepIdx = steps.findIndex((s, idx) => {
          if (idx <= nextIndex) return false;
          const nId = s.id;
          return (
            document.getElementById(nId)
                        || !s.type
                        || [STEP_TYPES.START, STEP_TYPES.CENTER, STEP_TYPES.FINISH].includes(s.type)
          );
        });
        if (newNextStepIdx === -1) return tourCancel({ skipAnalytics: true });
        return tourNextStep({ forceTour: tour, forceLastStep: newNextStepIdx });
      }
    }

    return (dispatch) => {
      dispatch({ type: ActionTypes.TOUR_CHANGED, tour, step: nextId });
    };
  }

  /**
     * Manual trigger tour done. Only used on mobile tour
     *
     * @returns {function} tourCancel(true) callback
     *
     * @category shared
     * @subcategory services
     * @exports tourDone
     */
  function tourDone() {
    sendAnalytics({ type: ANALYTICS_TYPE.TOUR_DONE });
    return tourCancel({ skipAnalytics: true });
  }

  return {
    tourStart,
    tourCancel,
    tourPrevStep,
    tourNextStep,
    tourDone,
  };
};
