import { BadgeProps } from 'shared/modules/sosafe-connect/response-type';

interface FilterBadgesCardReturnType {
  receivedAmount: number,
  sections: Array<BadgeProps[]>
}

export const filterBadgesCard = (badges: BadgeProps[]): FilterBadgesCardReturnType => badges?.reduce(
  (acc, badge: BadgeProps) => {
    const { row, received } = badge;
    const sections = acc.sections;

    if (sections[row - 1]?.length) {
      sections[row - 1].push(badge);
    } else {
      sections[row - 1] = [badge];
    }

    return {
      sections,
      receivedAmount: acc.receivedAmount + (received ? 1 : 0),
    };
  },

  { sections: [] as Array<BadgeProps[]>, receivedAmount: 0 },
);
