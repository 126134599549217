import {
  ANALYTICS_TYPE,
  useSoSafeConnect,
} from "shared/modules/sosafe-connect";

interface UseSoSafeAnalyticsProps {
  type: ANALYTICS_TYPE;
  onSuccess?: () => void;
}

const getComponent = () => {
  const url = window.location.pathname;
  switch (true) {
    case url.includes("achievements"):
      return "achievements";
    case url.includes("elearning") && url.split("/").length === 3:
      return "modules";
    case url === "/":
      return "categories";
    case url.includes("account"):
      return "profile";
    default:
      return "";
  }
};

const getPayload = (type: ANALYTICS_TYPE) => {
  const defaultPayload = { site: 1, type };

  switch (type) {
    case ANALYTICS_TYPE.HINT_VIEWED:
    case ANALYTICS_TYPE.PAGE_VIEWED:
    case ANALYTICS_TYPE.TOUR_CANCELLED:
    case ANALYTICS_TYPE.TOUR_DONE:
      return { ...defaultPayload, component: getComponent() };
    case ANALYTICS_TYPE.QUICKSTART_USED:
      return defaultPayload;
    default:
      return defaultPayload;
  }
};

export const useSoSafeAnalytics = () => {
  const { endPoints, apiKey } = useSoSafeConnect();

  const sendSoSafeAnalytics = async ({
    type,
    onSuccess,
  }: UseSoSafeAnalyticsProps) => {
    if (!apiKey) return;

    const payload = getPayload(type);

    try {
      await endPoints.post.sendSoSafeAnalytics(payload);
      onSuccess?.();
    } catch (error) {
      console.error("Send Analitics error", error);
    }
  };

  return {
    sendSoSafeAnalytics,
  };
};
