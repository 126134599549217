import { useQuery } from "@tanstack/react-query";
import { queryKey, useSoSafeConnect } from "shared/modules/sosafe-connect";

export default function useElearningApi(
  onSuccess: (result: any) => void,
  onError: (err: any) => void,
  enabled: boolean
) {
  const { endPoints } = useSoSafeConnect();
  return useQuery([queryKey.ELEARNING], () => endPoints.fetch.getElearningApi(), {
    enabled,
    onSuccess: (response) => {
      const { result } = response.data;
      onSuccess(result);
    },
    onError: (err: any) => onError(err),
    refetchOnWindowFocus: true,
    refetchOnReconnect: false,
  });
}
