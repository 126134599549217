import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FeedbackModal } from './feedback-modal';
import { QueryContextProvider, SoSafeAxiosProvider } from 'shared/modules/sosafe-connect';

export const feedbackSwal = withReactContent(Swal);

export function useFeedback() {
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const { i18n } = useTranslation(['translations', 'messageTranslations']);
  const showFeedback = () => {
    feedbackSwal.fire({
      html: (
                <QueryContextProvider>
                    <SoSafeAxiosProvider>
                        <FeedbackModal
                            user={user}
                        />
                    </SoSafeAxiosProvider>
                </QueryContextProvider>
      ),
      showCancelButton: false,
      showConfirmButton: false,
      showLoaderOnConfirm: false,
      customClass: {
        title: 'feedback-title',
        popup: `feedback-modal ${i18n.language === 'ar' ? 'arab' : ''}`,
      },
      focusConfirm: false,
      stopKeydownPropagation: false,
    });
  };

  return {
    showFeedback,
  };
}
