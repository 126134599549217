import { IAllowedRouteConfig, IRouterConfig } from "./router-type";

/*
* Returns an Array of allowed route controllers
*/
export const getAllowedRouteControllers = (routeControllers: Array<IRouterConfig>) => {
  const allowedRouteControllers = routeControllers.reduce<IAllowedRouteConfig>((acc, route) => {
    const { loading, allowed } = route.UseIsPathAllowed();
    if (acc.loading === true || loading)
      return { routeControllers: [], loading: true };

    if (allowed) {
      return {
        routeControllers: [...acc.routeControllers, route],
        loading,
      };
    }

    return acc;
  }, {
    routeControllers: [],
    loading: null,
  });

  return allowedRouteControllers;
}

