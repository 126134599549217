import { UserType } from "types";

/* Temporary setup to enable accessing the feature on production */
const personalizedLearningCampaigns: number[] = [6045, 17003];

export default function isPLuser(user: UserType | undefined) {
  return (
    user?.campaign?.isPersonalizedLearning ||
    personalizedLearningCampaigns.includes(user?.campaign?.id || 0)
  );
}
