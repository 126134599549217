import { useQuery } from "@tanstack/react-query";
import { queryKey, useSoSafeConnect } from "shared/modules/sosafe-connect";
import { useHistory } from "react-router";

interface UsePutToRecalculateLPProps {
  userId?: number;
  enabled: boolean;
}

/**
 * Fetch the survey result of survey the user has answered
 */
export const usePutToRecalculateLP = ({
  userId,
  enabled,
}: UsePutToRecalculateLPProps) => {
  const { endPoints, apiKey } = useSoSafeConnect();
  const history = useHistory();
  const { data } = useQuery(
    [queryKey.LEARNING_PATH_RECALCULATION_TRIGGERED],
    () =>
      endPoints.learningPathApi.put.putRecalculateLearningPath({
        userId: userId || 0,
      }),
    {
      enabled: enabled && !!apiKey && !!userId,
      retry: false,
      // no need to fetch from be after the first time
      staleTime: Infinity,
      onError: () => {
        history.push("/503");
      },
    }
  );

  return {
    isRecalulationTriggered: !!data?.data || data?.data === "",
  };
};
