export const SUCCESS_CODES = [
  // Login
  {
    id: 'LOGIN_ACTIVATE_SUCCESS',
    reference: '654A100A-1270-4249-8AB6-38E83C58BA74',
    message: 'messageTranslations:Your Account has been successfully activated.',
  },
  {
    id: 'REGISTRATION_SUCCESS_CODE',
    reference: '5E85F8E2-A216-49C9-AB37-7AF964A7E657',
    message: null,
  },
  {
    id: 'REGISTRATION_SUCCESS_KEY',
    reference: '42FC74FF-4DB6-4F3E-A531-49D32B8D0381',
    message: null,
  },
  // Password
  {
    id: 'PASSWORD_RECOVERY_SEND',
    reference: '72257E12-CE87-4FFB-8A9D-CBA5D3B07A20',
    message: 'messageTranslations:Password recovery is on its way.',
    type: 'MODAL',
    title: 'messageTranslations:Success',
    callback: () => { },
  },
  {
    id: 'PASSWORD_ACCOUNT_NOT_EXISTS',
    reference: '3895C33B-CA57-474E-BB68-E755BBCCDA79',
    message: 'messageTranslations:Password recovery is on its way.',
    type: 'MODAL',
    title: 'messageTranslations:Success',
    callback: () => { },
  },
  // Registration
  {
    id: 'REGISTRATION_DATA_SUCCESS_EMAIL',
    reference: 'BF9C166E-006A-448A-BF2B-A199B702F19F',
    message: null,
  },
  {
    id: 'REGISTRATION_SSO_LOGIN',
    reference: 'B16A8086-A6C6-4DB7-9ADA-0D0DD824D4FE',
    message: null,
  },
  {
    id: 'REGISTRATION_DATA_SUCCESS_CODE',
    reference: '536385D7-8934-48C0-B891-AF14AD86CA8C',
    message: null,
  },
  {
    id: 'REGISTRATION_SUCCESS_EMAIL',
    reference: 'BC66B8FE-D7B9-4B19-83AF-0404FF6B5643',
    message:
      'messageTranslations:You have been registered for E-Learning. Please open the link we sent to your email address to activate your account.',
    type: 'MODAL',
    title: 'messageTranslations:Success',
    callback: () => {
      window.location.href = `${window.elearningRuntimeConfig.ELEARNING_HOSTS}/`;
    },
  },
  {
    id: 'REGISTRATION_SUCCESS_CODE',
    reference: '5E85F8E2-A216-49C9-AB37-7AF964A7E657',
    message: null,
  },
  {
    id: 'REGISTRATION_SUCCESS_KEY',
    reference: '42FC74FF-4DB6-4F3E-A531-49D32B8D0381',
    message: null,
  },
  // Password
  {
    id: 'PASSWORD_RECOVERY_SEND',
    reference: '72257E12-CE87-4FFB-8A9D-CBA5D3B07A20',
    message: 'messageTranslations:Password recovery is on its way.',
  },
  {
    id: 'PASSWORD_ACCOUNT_NOT_EXISTS',
    reference: '3895C33B-CA57-474E-BB68-E755BBCCDA79',
    message: 'messageTranslations:Password recovery is on its way.',
  },
  {
    id: 'PASSWORD_SET_SUCCESS',
    reference: '1E087605-0B4F-4BA0-8C86-BB90E52D50E8',
    message: 'messageTranslations:Password set successfully.',
    type: 'MODAL',
    title: 'messageTranslations:Success',
    callback: () => {
      window.location.href = `${window.elearningRuntimeConfig.ELEARNING_HOSTS}/`;
    },
  },
  // Logout
  {
    id: 'LOGOUT_SUCCESS',
    reference: '1ADC25FE-0C59-451B-B4FA-EA500EBFA567',
    message: 'messageTranslations:Logged out',
  },
  // Validation
  { id: 'VALIDATION_SUCCESS', reference: '0629A282-2432-4177-B54D-8D0CF2FC55AC', message: null },
  // ItWasntMe
  {
    id: 'ITWASNTME_SUCCESS',
    reference: '80DEB4F3-862C-402D-A3FB-B619496C29BF',
    message: 'messageTranslations:The account has been removed',
  },
  // Activation
  {
    id: 'SEND_ACTIVATION_SUCCESS',
    reference: '7A6470D4-C31F-460F-920B-2087904915F3',
    message: 'messageTranslations:New email has been sent.',
  },
  // Change Account
  {
    id: 'CHANGE_ACCOUNT_SUCCESS',
    reference: 'F650EA00-E627-4903-89D3-87B94C37B9BD',
    message: 'Saved',
  },
  {
    id: 'CHANGE_PASSWORD_SUCCESS',
    reference: 'E5A62854-821F-445C-A996-3501B1133ABA',
    message: 'Saved',
  },
  // Modules
  {
    id: 'CATEGORIES_FETCH_SUCCESS_1',
    reference: '562B2200-1537-420D-99B3-C37D435F5D6',
    message: null,
  },
  {
    id: 'CATEGORIES_FETCH_SUCCESS_2',
    reference: 'ACE28682-A34A-4648-B599-CABD36CB78C2',
    message: null,
  },
  // Didyouknow hint
  {
    id: 'DIDYOUKNOWHINT_SUCCESS',
    reference: '9421516F-CF4B-43FE-8DB8-7D3E7A46F34F',
    message: null,
  },
  // Player
  {
    id: 'MODULE_UPDATE_PROGRESS_SUCCESS',
    reference: 'B446ABEB-59DE-42DA-8778-0E57706DA3D9',
    message: null,
  },
  // Survey
  { id: 'SURVEY_FETCH_SUCCESS', reference: '565A545E-2EAD-4C6B-BCB6-49AE33B86961', message: null },
  { id: 'SURVEY_SEND_SUCCESS', reference: '5F46A425-DA79-41BB-BFE0-A7B1396FA8ED', message: null },
  { id: 'OPTIN_SEND_SUCCESS', reference: 'B3127FD7-25C4-4011-9DD4-EC94E2F3E713', message: null },
  // Notification
  {
    id: 'NOTIFICATION_GET_SUCCESS',
    reference: '22F11EC8-129E-412D-A0D1-FA68753B1730',
    message: null,
  },
  {
    id: 'NOTIFICATION_PUT_SUCCESS',
    reference: '14985CBC-5F46-4A2A-8F62-D573321F1D45',
    message: null,
  },
  // Game Layer
  { id: 'GET_BADGES_SUCCESS', reference: '2C972131-9652-4F06-8419-080F2FCBC320', message: null },
  {
    id: 'GET_ACTIVITIES_SUCCESS',
    reference: '304894FA-AEE7-46C5-8424-FA478C6E560A',
    message: null,
  },
  // Branding
  { id: 'BRANDING_GET_SUCCESS', reference: '511EEADF-B32B-49C7-A422-FB4B3789D740', message: null },
  // Module feedback
  {
    id: 'SEND_MODULE_FEEDBACK_SUCCESS',
    reference: '69974FC2-16E4-4B3F-B6C8-D9350B346C70',
    message: 'messageTranslations:Success',
  },
  // Wizard
  {
    id: 'SEND_WIZARD_ANSWERS_SUCCESS',
    reference: '1B939210-41FB-4FA3-B8BB-E4DD436F5BFC',
    message: null,
  },
  // POLICY
  {
    id: 'SEND_POLICIES_ANSWERS_SUCCESS',
    reference: 'E4DF3FD7-F6DD-4806-A534-E928D0F31505',
    message: 'messageTranslations:Success',
  },
];

