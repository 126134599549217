interface CalculateProgressXpProps {
  minXp: number
  maxXp: number
  currentXp: number,
  receivedXp?: number
  isLastLevel?: boolean
}

/**
 * Returns the calculated user experience data, used to render user progression bar and test: eg 10/50
 * @param {Number} props.maxXp experience the user needs to be in the level x
 * @param {Number} minXp Maximum experience the user needs to be in the level x
 * @param {Number} currentXp Current overall user xp
 * @param {Number} receivedXp Current overall user xp
 * @param {boolean} isLastLevel automatically detected, but you can force by passing true
 * notice this changes the return value to the last level taking in count currentXp
 */
export const calculateProgress = ({
  minXp, maxXp, currentXp, receivedXp = 0, isLastLevel = minXp === maxXp,
}: CalculateProgressXpProps) => {
  const normalizedLevelXp = maxXp - minXp;
  const normalizedCurrentLevelXp = currentXp - minXp;
  const levelXp = normalizedCurrentLevelXp / normalizedLevelXp;

  // if normalized is negative the user has level up 👏
  const normalizedCurrentReceivedXp = normalizedCurrentLevelXp - receivedXp;

  if (isLastLevel) {
    return {
      percentageXp: 100,
      currentLevelXp: currentXp,
      currentStartLevelXp: normalizedCurrentReceivedXp < 0 ? 0 : normalizedCurrentReceivedXp,
      maxLevelXp: currentXp,
    };
  }

  return ({
    percentageXp: Math.round(levelXp * 100),
    currentLevelXp: normalizedCurrentLevelXp,
    currentStartLevelXp: normalizedCurrentReceivedXp < 0 ? 0 : normalizedCurrentReceivedXp,
    maxLevelXp: normalizedLevelXp,
  });
};
