import { Button, Card, TextInput } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import Validators from "shared/utilities/validators";
import { useTranslation } from "react-i18next";
import { useAccount } from "account/hooks/use-account";
import { PasswordState, usePasswordState } from "./use-password-state";
import style from "./account.module.css";

export const ChangePassword = () => {
  const { t } = useTranslation("flamingo");
  const { changePassword } = useAccount();

  const validator = (key: string, value: string, state: PasswordState) => {
    const errors = Validators.password(value);
    const { valid } = errors

    if (valid) {
      if (key === "newpasswordRepeat") {
        if (value !== state.newpassword) {
          return "password_rules_equal";
        }
      }
      return null;
    }

    return `password_rules_${errors.data[0]}`;
  }

  const { state, error, updateField, validate, isValid, validateAllFields } = usePasswordState({ validator });

  const savePassword = () => {
    const isAllFieldsValid = validateAllFields();
    if (!isAllFieldsValid) return;

    changePassword({
      oldpassword: state.oldpassword,
      newpassword: state.newpassword,
    });
  }

  return (
    <Card withBorder radius={"lg"} className={style.passwordCard}>
      <TextInput
        label={t("old_password")}
        className={style.formPasswordInput}
        placeholder={t("old_password")}
        type="password"
        value={state.oldpassword}
        error={
          error.oldpassword ?
            // @ts-expect-error -  Dynamic key
            `${t("password_rules_intro")} ${t(error.oldpassword)}`
            : null
        }
        onChange={(e) => updateField("oldpassword", e.currentTarget.value)}
        onBlur={() => validate("oldpassword")}
        autoComplete="current-password"
      />
      <TextInput
        label={t("password")}
        className={style.formPasswordInput}
        placeholder={t("password")}
        type="password"
        value={state.newpassword}
        error={
          error.newpassword ?
            // @ts-expect-error -  Dynamic key
            `${t("password_rules_intro")} ${t(error.newpassword)}`
            : null
        }
        onChange={(e) => updateField("newpassword", e.currentTarget.value)}
        onBlur={() => validate("newpassword")}
        autoComplete="new-password"
      />
      <TextInput
        label={t("repeat_password")}
        className={style.formPasswordInput}
        placeholder={t("repeat_password")}
        type="password"
        value={state.newpasswordRepeat}
        error={
          error.newpasswordRepeat ?
            // @ts-expect-error -  Dynamic key
            `${t("password_rules_intro")} ${t(error.newpasswordRepeat)}`
            : null
        }
        onChange={(e) => updateField("newpasswordRepeat", e.currentTarget.value)}
        onBlur={() => validate("newpasswordRepeat")}
        autoComplete="new-password"
      />
      <div className={style.saveContainer}>
        <Button
          aria-label={t("change_password")}
          type="submit"
          className={style.save}
          onClick={savePassword}
          disabled={!isValid || !state.oldpassword || !state.newpassword || !state.newpasswordRepeat}
        >
          {t("change_password")}
        </Button>
      </div>
    </Card>
  )

}
