import { useClearLoginData } from "authentication/hooks/use-clear-login-data";
import { useSoSafeQuery } from "shared/hooks/use-sosafe-query";
import { queryKey, useSoSafeConnect } from "shared/modules/sosafe-connect";
import { ReferenceAction } from "shared/modules/sosafe-connect/handle-reference";
import ActionTypes from "shared/utilities/action-types";

export const useGetActivities = () => {
  const { endPoints } = useSoSafeConnect();
  const clearLoginData = useClearLoginData();

  return useSoSafeQuery({
    enabled: true,
    key: queryKey.ACTIVITIES,
    requestMethod: endPoints.fetch.getActivities,
    requestType: ActionTypes.GET_ACTIVITIES_REQUEST,
    successType: ActionTypes.GET_ACTIVITIES_SUCCESS,
    failedType: ActionTypes.GET_ACTIVITIES_FAILED,
    onError: (error: any) => {
      if (
        error?.response?.data?.reference &&
        ReferenceAction.shouldLogout(error.response.data.reference)
      ) {
        clearLoginData(true);
      }
    },
    retry: (failureCount, error) => {
      if (
        error?.response?.data?.reference &&
        ReferenceAction.shouldLogout(error.response.data.reference)
      )
        return false;
      return failureCount < 3;
    },
  });
};
