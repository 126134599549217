import { useEffect } from "react";
import { initialize } from "@sumologic/opentelemetry-rum";

export default function useSumologic(){
    useEffect(() => {
        const sumologicRumKey = window.elearningRuntimeConfig.SUMOLOGIC_RUM_KEY;
        if (sumologicRumKey) {
          initialize({
            collectionSourceUrl: `https://rum-collectors.de.sumologic.com/receiver/v1/rum/${sumologicRumKey}`,
            serviceName: "elearning",
            applicationName: "dev-elearning",
            samplingProbability: 1,
            collectErrors: true,
          });
        }
      }, []);
}
