import { ErrorView } from "@sosafe-aws/fe-lib-maintenance-pages";
import { TextLink } from "@sosafe-platform-engineering/fe-lib-ui-react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import useMetaLinks from "shared/hooks/use-meta-links";

import { Flex } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";

export type Props = {
  errorCode?: number;
};

export default function ErrorPage(props: Props) {
  const { errorCode } = props;

  const { t } = useTranslation("translations");
  const { support } = useMetaLinks();

  const translations = {
    headline: t("service_temporarily_unavailable"),
    textwithLink: t("try_again_later_or_check_for_updates"),
    textHome: t("home"),
    textReload: t("Reload"),
    textContactSupport: t("Contact support"),
  };

  const handleReload = () => {
    window.location.reload();
  };

  const handleGoToHome = () => {
    window.location.href = "/";
  };

  return (
    <Flex justify={"center"}>
      <ErrorView
        bodyContent={
          <p>
            <Trans
              i18nKey={"try_again_later_or_check_for_updates"}
              components={{
                textLink: <TextLink href={"https://status.sosafe.de"} target="_blank"/>,
              }}
              values={{ link: "status.sosafe.de" }}
            />
          </p>
        }
        heading={translations.headline}
        errorCode={errorCode}
        buttons={{
          home: { label: translations.textHome, onClick: handleGoToHome },
          reload: { label: translations.textReload, onClick: handleReload },
          contactSupport: {
            label: translations.textContactSupport,
            onClick: () => support && window.open(support, "_blank"),
          },
        }}
      />
    </Flex>
  );
}
