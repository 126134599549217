import { useDispatch } from "react-redux";
import { useSoSafeConnect } from "shared/modules/sosafe-connect";
import ActionTypes from "shared/utilities/action-types";
import { UseReferenceHandler } from "shared/utilities/use-reference-handler";
import { useClearLoginData } from "./use-clear-login-data";

export const useLogoutRequest = () => {
  const clearLoginData = useClearLoginData();
  const { endPoints } = useSoSafeConnect();
  const dispatch = useDispatch();
  const checkReference = UseReferenceHandler();

  const logout = async () => {
    dispatch({ type: ActionTypes.AUTH_LOGOUT_REQUEST });
    try {
      const response = await endPoints.fetch.logout();
      setTimeout(() => {
        dispatch({
          payload: checkReference(response.data, dispatch),
          type: ActionTypes.AUTH_LOGOUT_SUCCESS,
        });
        clearLoginData();
      }, 300);
    } catch (error: any) {
      setTimeout(() => {
        clearLoginData(true);
      }, 300);
    }
  };

  return {
    logout,
  };
};
