
type RequiredProps = { mandatory: boolean, id: number };

export const sortMandatoryToFirst = <T extends RequiredProps>(modules: T[]) =>
  modules.sort((modA, modB) => {
    if (modA.mandatory && modB.mandatory) return 0;
    if (modA.mandatory && !modB.mandatory) return -1;
    return 1;
  });




