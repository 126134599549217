import ActionTypes from 'shared/utilities/action-types';
import { isReferenceIdMatch, getIdByReference, getReferenceById } from 'shared/utilities/reference-handling';
import { showToast, ALERT_TYPE } from 'shared/utilities/modal-alert';
import i18n from 'lang/i18n';

const INITIAL_STATE = {};

class AuthReducer {
  static reduce(state = INITIAL_STATE, action) {
    let reference;
    let result;
    const { payload } = action;
    if (payload) {
      reference = payload.reference;
      result = payload.result;
    }

    switch (action.type) {
      case ActionTypes.CLEAR_REFERENCE:
        return {
          ...state,
          reference: null,
        };

      case ActionTypes.AUTH_REGISTER_DATA_REQUEST:
        return {
          ...state,
          reference: null,
          register_data: null,
          loading: true,
        };

      case ActionTypes.AUTH_REGISTER_DATA_SUCCESS:
        if (getIdByReference(reference) === 'REGISTRATION_SSO_LOGIN') {
          return {
            ...state,
            reference,
            user: result,
            loading: false,
            register_data: null,
          };
        }
        return {
          ...state,
          reference,
          redirectToRegister: true,
          register_data: result,
          loading: false,
          login_data: null,
        };

      case ActionTypes.AUTH_REGISTER_DATA_FAILED: {
        return {
          ...state,
          reference,
          register_data: null,
          loading: false,
          login_data: null,
        };
      }

      case ActionTypes.AUTH_REGISTER_SUCCESS: {
        if (reference === '4C4D7457-73CE-4EFB-B5C9-10FDDD4FBD30') {
          return {
            ...state,
            user: result,
            reference,
            activateMail: null,
            loading: false,
            login_data: null,
          };
        }
        const login_data = {
          ...result,
        };
        return {
          ...state,
          reference,
          // register_data: null,
          login_data,
          loading: false,
        };
      }

      case ActionTypes.AUTH_REGISTER_FAILED:
        return {
          ...state,
          reference,
          register_data: null,
          loading: false,
          login_data: null,
        };

      case ActionTypes.AUTH_ACTIVATE_SUCCESS: {
        return {
          ...state, reference, loading: false, activateMail: result.email, activationSuccess: true,
        };
      }
      case ActionTypes.CLEAN_UP_ACTIVATION_CODE:
        return {
          ...state,
          activationSuccess: null,
        };

      case ActionTypes.AUTH_LOGIN_REQUEST:
        return {
          ...state,
          reference: null,
          loading: true,
          redirectToRegister: false,
          activateMail: null,
        };

      case ActionTypes.REMOVE_REDIRECT_REGISTER:
        return {
          ...state,
          redirectToRegister: false,
        };

      case ActionTypes.AUTH_LOGIN_SUCCESS: {
        if (isReferenceIdMatch(reference, 'REGISTRATION_DATA_SUCCESS_EMAIL')) {
          return {
            ...state,
            reference,
            register_data: result,
            redirectToRegister: true,
            loading: false,
          };
        }
        return {
          ...state,
          user: result,
          reference,
          activateMail: null,
          loading: false,
        };
      }

      case ActionTypes.AUTH_LOGIN_SAML_SUCCESS: {
        return {
          ...state,
          user: result,
          reference: getReferenceById('LOGIN_SUCCESS'),
          activateMail: null,
          loading: false,
        };
      }

      case ActionTypes.AUTH_LOGIN_FAILED:
        return {
          ...state,
          reference,
          loading: false,
          activateMail: null,
        };

      case ActionTypes.AUTH_VALIDATE_SUCCESS:
        return {
          ...state,
          user: result,
          reference,
          loading: false,
        };

      case ActionTypes.AUTH_VALIDATE_FAILED: {
        if (reference && reference !== '8CCA1873-7195-4EB7-8BF4-32CCF473B353') {
          return {
            ...state,
            user: null,
            reference,
            loading: false,
          };
        }
        if (reference && reference === '8CCA1873-7195-4EB7-8BF4-32CCF473B353') {
          return {
            ...state,
            reference,
            loading: false,
          };
        }
        return {
          ...state,
          loading: false,
        };
      }

      case ActionTypes.ACCOUNT_UPDATE_SUCCESS:
        return {
          ...state,
          user: {
            ...state.user,
            ...result,
          },
          reference,
          loading: false,
        };

      case ActionTypes.SEND_POLICY_ANSWERS_REQUEST:
      case ActionTypes.SEND_WIZARD_ANSWERS_REQUEST:
        return {
          ...state,
          reference: null,
          loading: true,
        };

      case ActionTypes.SEND_POLICIES_ANSWERS_SUCCESS:
      case ActionTypes.SEND_WIZARD_ANSWERS_SUCCESS:
        return {
          ...state,
          user: {
            ...state.user,
            game: result,
          },
          reference,
          loading: false,
        };

      case ActionTypes.CLEAR_AUTH_REF:
        return {
          ...state,
          reference: null,
        };

      case ActionTypes.ACCOUNT_UPDATE_REQUEST:
      case ActionTypes.AUTH_ACTIVATE_REQUEST:
      case ActionTypes.AUTH_ITWASNTME_REQUEST:
      case ActionTypes.AUTH_LOGOUT_REQUEST:
      case ActionTypes.AUTH_REGISTER_REQUEST:
      case ActionTypes.AUTH_SEND_ACTIVATE:
      case ActionTypes.AUTH_VALIDATE_REQUEST:
      case ActionTypes.PASSWORD_UPDATE_REQUEST:
        return {
          ...state,
          loading: true,
          reference: null,
          login_data: null,
        };

      case ActionTypes.ACCOUNT_UPDATE_FAILED:
      case ActionTypes.AUTH_ACTIVATE_FAILED:
      case ActionTypes.AUTH_ITWASNTME_FAILED:
      case ActionTypes.AUTH_ITWASNTME_SUCCESS:
      case ActionTypes.AUTH_SEND_ACTIVATE_FAILED:
      case ActionTypes.AUTH_SEND_ACTIVATE_SUCCESS:
      case ActionTypes.PASSWORD_UPDATE_FAILED:
      case ActionTypes.PASSWORD_UPDATE_SUCCESS:
        return {
          ...state,
          reference,
          loading: false,
        };

      case ActionTypes.OPTIN_SEND_SUCCESS: {
        return {
          ...state,
          user: {
            ...state.user,
            ...action.payload,
          },
          loading: false,
        };
      }

      case ActionTypes.AUTH_REMOVE_REG_DATA: {
        return {
          ...state,
          register_data: null,
          reference: null,
        };
      }

      case ActionTypes.AUTH_CUSTOMER_ACCESS:
      case ActionTypes.AUTH_ELEARNING_NOT_ACTIVE:
      case ActionTypes.AUTH_ROLE_ACCESS:
      case ActionTypes.AUTH_TEMPORARY_INVALID:
      case ActionTypes.AUTH_TOKEN_INVALID:
      case ActionTypes.AUTH_TOKEN_MISSING:
        return {
          ...state,
          reference,
        };

      case ActionTypes.AUTH_LOGOUT_FAILED:
      case ActionTypes.AUTH_LOGOUT_SUCCESS:
      case ActionTypes.CLEAR_STORE:
        return {};

      default:
        return state;
    }
  }

  // Use new game.camelCase notation, if it exists. Fallback to old snake_case.
  // This part and the extra `getReducerResponse` function can be removed, once JIRA task CROC-790 is Done
  // AND respective Commit/Merge: https://git0.sosafe.de/backend/api/-/merge_requests/685 is on Production.
  static tempReduce(state = INITIAL_STATE, action) {
    const reducerResult = AuthReducer.reduce(state, action);

    // early return, if no game object exists, to be modified
    if (!reducerResult.user?.game?.activities || !reducerResult.user?.game?.progress) return reducerResult;

    const { user: { game } } = reducerResult;
    const { activities, progress } = game;

    const returnValue = {
      ...reducerResult,
      user: {
        ...reducerResult.user,
        game: {
          moduleFeedback: game.module_feedback,
          moduleFeedbacks: game.module_feedbacks,
          ...game, // prefer the response values, if they're there
          // do the exact same thing for activities and progress
          activities: {
            lastBadge: activities.last_badge,
            lastLogin: activities.last_login,
            lastBadges: activities.last_badges,
            lastModule: activities.last_module,
            lastCompletedModule: activities.last_completed_module, // not used
            lastActivities: activities.last_activities, // not used
            loginsSinceBadge: activities.logins_since_badge,
            loginsSinceModule: activities.logins_since_module,
            ...activities, // prefer the response values, if they're there
          },
          progress: {
            totalAllUnlocked: progress.total_all_unlocked,
            nextModuleRelease: progress.next_module_release,
            lastLevel: progress.last_level, // not used
            nextLevel: progress.next_level,
            essentialsDone: progress.essentials_done,
            extendedDone: progress.extended_done, // not used
            expertDone: progress.expert_done, // not used
            ...progress, // prefer the response values, if they're there
          },
        },
      },
    };

    return returnValue;
  }
}

export default AuthReducer.tempReduce;
