import translations from './translations.json';
import faqTranslations from './faq.translations.json';
import tourTranslations from './tour.translations.json';
import welcomeCardTranslations from './welcome-card.translations.json';
import languagesTranslations from './languages.translations.json';
import optInTranslations from './optin.translations.json';
import messageTranslations from './message.translations.json';
import helmetTranslations from './helmet.translations.json';
import placeholderTranslations from './placeholder.translations.json';
import moduleFeedbackTranslations from './module-feedback.translations.json';

export default {
  translations,
  faqTranslations,
  tourTranslations,
  welcomeCardTranslations,
  languagesTranslations,
  optInTranslations,
  messageTranslations,
  helmetTranslations,
  placeholderTranslations,
  moduleFeedbackTranslations,
};
