import { GameProgressLevelProps, GameProgressProps, LessonStatus } from '../../../types';
import { ScormType } from './types';

interface GetLessonStatusByProps {
  lessonStatus: LessonStatus,
}

/**
 * SoSafe LMS does not recognize complete as lesson status,
 * therefore it's converted to passed
 */
export const getLessonStatusBy = ({ lessonStatus } : GetLessonStatusByProps) => (lessonStatus === LessonStatus.COMPLETE ? LessonStatus.PASSED : lessonStatus);

interface GetMasterScoreByScormTypeProps {
  isCustomModule: boolean,
  isActivePresenter: boolean,
}

/**
 *
 * @param param0
 * @returns Master score depending on the scorm type
 * if scorm type is Custom Module the master score is undefined relying only in the lesson status
 * if scorm type is not Custom Module and is Active presenter, the master score is always 100
 * if scorm type is not Custom Module and not Active presenter, the master score is always 75
 */
export const getMasterScoreByScormType = ({ isCustomModule, isActivePresenter }: GetMasterScoreByScormTypeProps) => {
  const getScormType = () => {
    if (isCustomModule) {
      return ScormType.CUSTOM_MODULE;
    }

    if (isActivePresenter) {
      return ScormType.ACTIVE_PRESENTER;
    }

    return ScormType.DEFAULT_MODULE;
  };

  switch (getScormType()) {
    case ScormType.CUSTOM_MODULE:
      return undefined;

    case ScormType.ACTIVE_PRESENTER:
      return 100;

    default:
      return 75;
  }
};

interface CreateNewProgressFromModuleFinishedProps {
  progress: GameProgressProps,
  newLevel?: GameProgressLevelProps,
  nextLevel: GameProgressLevelProps,
  newXp: number,
}

/**
 * Create new game progress based on the current data,
 * to prevent having to refetch authorization endpoint often
 */
export const createNewProgressFromModuleFinished = ({
  progress,
  newLevel,
  nextLevel,
  newXp,
}: CreateNewProgressFromModuleFinishedProps) => ({
  ...progress,
  level: newLevel || progress.level,
  lastLevel: newLevel ? progress.level : progress.lastLevel,
  nextLevel,
  xp: progress.xp + newXp,
  completed: progress.completed + 1,
});
