import { saveAs } from 'file-saver';
import { AxiosRequestConfig } from 'axios';
import { useSoSafeConnect, DownloadProps } from 'shared/modules/sosafe-connect';
import { UseReferenceHandler } from 'shared/utilities/use-reference-handler';
import { useMutation } from '@tanstack/react-query';

export const useDownload = (filename: string) => {
  const { endPoints } = useSoSafeConnect();
  const checkReference = UseReferenceHandler();

  const { mutate: downloadFile, isLoading } = useMutation(
    (props: DownloadProps) => {
      const requestConfig: AxiosRequestConfig = {
        responseType: 'blob',
      };

      return endPoints.post.download(props, requestConfig);
    },
    {
      onSuccess: (response: any) => {
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        saveAs(blob, filename);
      },
      onError: (error: any) => {
        if (error.response) {
          checkReference(error.response.data);
        }
      },
    },
  );

  return {
    downloadFile,
    isLoading,
  };
};
