/**
 * @deprecated since survey-hub implementation
 */
import { Button } from "@sosafe-platform-engineering/fe-lib-ui-react";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "react-jsonschema-form";
import CustomSelectWidget from "shared/components/onboarding/survey-select-widget";
import { surveyTransformErrors } from "shared/helpers";
import { useSurvey } from "shared/hooks/use-survey";
import { ParsedSurvey } from "../types";
import { emptySurveyQueryResponse } from "../utils";

interface SurveyContentModalProps {
  survey: ParsedSurvey;
  onSubmitSuccess(): void;
  onSubmitError(): void;
}

export const SurveyContentModal = ({
  survey,
  onSubmitSuccess: onSubmitAnswer,
  onSubmitError,
}: SurveyContentModalProps) => {
  const { t } = useTranslation("translations");
  const uiOrder: Array<string> = survey.ui["ui:order"];
  const [answers, saveAnswers] = useState<Record<string, any>>({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const currentQuestionId = uiOrder[currentQuestionIndex];
  const currentQuestion = survey.form.properties[currentQuestionId];
  const { saveSurvey } = useSurvey({
    survey: survey.id,
    onSuccess: () => onSubmitAnswer(),
    onError: () => onSubmitError(),
  });

  const widgets = {
    SelectWidget: CustomSelectWidget,
  };

  const form = {
    properties: {
      [uiOrder[currentQuestionIndex]]: currentQuestion,
    },
    required: [uiOrder[currentQuestionIndex]],
  };

  const onSaveAnswer = useCallback((props: Record<string, any>) => {
    saveAnswers((savedAnswers: Record<string, any>) => ({
      ...savedAnswers,
      ...props.formData,
    }));
    setCurrentQuestionIndex((old) => old + 1);
  }, []);

  const onSubmitAnswers = useCallback(
    (props) => {
      saveSurvey({ ...answers, ...props.formData });
    },
    [answers]
  );

  const onCancel = () => {
    saveSurvey({});
    emptySurveyQueryResponse();
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row">
        <h3 className="mb-2 h2">{survey.name}</h3>
      </div>

      <Form
        className="survey-form"
        schema={form}
        uiSchema={survey.ui}
        widgets={widgets}
        onSubmit={
          currentQuestionIndex < uiOrder.length - 1
            ? onSaveAnswer
            : onSubmitAnswers
        }
        transformErrors={(errors) => surveyTransformErrors(errors, t)}
        showErrorList={false}
      >
        {survey.mandatory && (
          <React.Fragment>
            <hr className="Survey-hr" />
            <p className="u-textMedium ml-2 mr-2">
              {t("Please fill the form completely in one go")}
            </p>
            <hr className="Survey-hr" />
          </React.Fragment>
        )}

        <div className="d-flex flex-row justify-content-between n-mt-2">
          <p>
            {currentQuestionIndex + 1} / {uiOrder.length}
          </p>

          <div className="d-flex flex-row n-mt-2">
            {!survey.mandatory && (
              <Button.Outline
                className="survey-footer-button"
                ariaLabel={t("Cancel")}
                onClick={onCancel}
              >
                {t("Cancel")}
              </Button.Outline>
            )}
            <div />

            {currentQuestionIndex < uiOrder.length - 1 && (
              <Button
                className="survey-footer-button"
                type="submit"
                ariaLabel={t("Next")}
              >
                {t("Next")}
              </Button>
            )}

            {currentQuestionIndex === uiOrder.length - 1 && (
              <Button
                className="survey-footer-button"
                type="submit"
                ariaLabel={t("Send")}
              >
                {t("Send")}
              </Button>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};
