import EN from './en.json';
import DE from './de.json';
import AR from './ar.json';
import BG from './bg.json';
import CH from './ch.json';
import CS from './cs.json';
import DA from './da.json';
import EL from './el.json';
import ES from './es.json';
import ET from './et.json';
import FI from './fi.json';
import FR from './fr.json';
import HI from './hi.json';
import HR from './hr.json';
import HU from './hu.json';
import IT from './it.json';
import JP from './jp.json';
import KO from './ko.json';
import NL from './nl.json';
import NO from './no.json';
import PL from './pl.json';
import PT from './pt.json';
import RO from './ro.json';
import RU from './ru.json';
import SE from './se.json';
import SV from './sv.json';
import SK from './sk.json';
import SL from './sl.json';
import TR from './tr.json';
import TH from './th.json';
import UK from './uk.json';
import VI from './vi.json';
import ZH from './zh.json';

export default {
  DE,
  EN,
  AR,
  BG,
  CH,
  CS,
  DA,
  EL,
  ES,
  ET,
  FI,
  FR,
  HI,
  HR,
  HU,
  IT,
  JP,
  KO,
  NL,
  NO,
  PL,
  PT,
  RO,
  RU,
  SE,
  SV,
  SK,
  SL,
  TH,
  TR,
  UK,
  VI,
  ZH,
};

declare module 'react-i18next' {
  interface Resources {
    survey_hub: typeof EN;
  }
}
