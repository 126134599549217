import { DownloadsDataType } from 'downloads/downloads.types';
import { URL_DOWNLOADS_API } from 'shared/helpers/url/url';
import { checkReference } from 'shared/utilities/reference-handling';
import soSafeAxios from 'state/axios';
import addRandomIEGetParameters from './helpers/add-random-ie-get-parameters.helper';

interface SendGetDownloadsArgs {
  /** useState setter for the loading variable */
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  /** useState setter for the data variable */
  setData: React.Dispatch<React.SetStateAction<DownloadsDataType>>;
  /** users apikey */
  apikey: string;
}

/** sends a get request and returns the materials, if there are any
 * falls back on default error handling, there are no error routes atm
 */
export default function sendGetDownloads({ setLoading, setData, apikey }: SendGetDownloadsArgs): void {
  setLoading(true);
  const requestConfig = addRandomIEGetParameters({ headers: { Authorization: apikey } });
  soSafeAxios
    .get(
      URL_DOWNLOADS_API(),
      requestConfig,
    )
    .then((response) => {
      setLoading(false);
      const newData: DownloadsDataType = response.data?.result || [];
      setData(newData);
    })
    .catch((error) => {
      setLoading(false);
      setData([]);
      console.error(error);
      if (error.response) {
        checkReference(error.response.data);
      } else {
        checkReference({ title: error.name, status: error.statusCode, reference: '??' });
      }
    });
}
