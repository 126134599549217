import {
  Button, clsx, DownloadIcon, Modal,
} from '@sosafe-platform-engineering/fe-lib-ui-react';
import { DownloadDataType } from 'downloads/downloads.types';
import { useDownload } from 'downloads/hooks/use-download';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ResponsivePicture from 'shared/components/responsive-picture';
import sizeFormat from '../../helpers/size-format.helper';
import DownloadPreview from '../download-preview/download-preview.component';
import './download-card.component.css';

const checkIfLocked = (/* conditions */) => {
  // TODO this is for round two of the MVP!
  return false;
};

interface DownloadCardProps {
  /** the material to be displayed */
  download: DownloadDataType;
}

/** renders a small card of a given material, with a thumbnail and some meta information and fires the preview-modal, on click of "preview" */
export default function DownloadCard(props: DownloadCardProps) {
  const {
    id,
    description,
    extension,
    filename,
    filesize,
    thumbnail,
    title,
  } = props.download;

  const { t } = useTranslation();
  const isLocked = checkIfLocked();
  const [previewModalVisible, setPreviewModalVisible] = useState(false);
  const { downloadFile, isLoading } = useDownload(props.download.filename ?? props.download.created);

  return (
        <div className='u-sizeFull u-md-size1of2 u-lg-size1of3 u-xl-size1of4 u-xxl-size1of5'>
            <div className={clsx({ 'is-locked': isLocked }, 'u-flex u-flexCol sosafe-DownloadCard')}>
                <h4 className='sosafe-DownloadCard-title u-textMedium'>{title}</h4>
                <div className='u-flexOrderFirst u-flex u-flexJustifyCenter sosafe-DownloadCard-thumbnailContainer'>
                    <ResponsivePicture
                        className='sosafe-DownloadCard-thumbnail'
                        src={thumbnail || ''}
                        alt={title || ''}
                        fit='cover'
                    />
                </div>
                <span className='sosafe-DownloadCard-description u-textMedium'>{description}</span>
                <div className='sosafe-DownloadCard-content'>
                    <span className='u-textSmall sosafe-DownloadCard-contentFilesize'>{`${filename}.${extension} - ${sizeFormat(filesize || 0)}`}</span>
                    <Button.Outline
                        ariaLabel={t('downloads:show_preview')}
                        onClick={() => setPreviewModalVisible(true)}
                        disabled={isLocked}
                    >
                        <Trans i18nKey={'downloads:show_preview'} />
                    </Button.Outline>
                    <Button
                        ariaLabel={t('downloads:download')}
                        disabled={isLocked || isLoading}
                        onClick={() => downloadFile({ id })}
                    >
                        <Trans i18nKey={'downloads:download'} />
                    </Button>
                </div>
            </div>
            <Modal
                className='sosafe-DownloadCard-modal'
                isOpen={previewModalVisible}
                onClose={() => setPreviewModalVisible(false)}
                closeOnConfirm={false}
                onConfirm={() => downloadFile({ id })}
            >
                <Modal.Header>
                    {t('downloads:preview')}
                </Modal.Header>
                <Modal.Body>
                    <DownloadPreview download={props.download} />
                </Modal.Body>
                <Modal.Footer>
                    <Modal.DeclineButton>
                        {t('Close')}
                    </Modal.DeclineButton>
                    <Modal.ConfirmButton>
                        <React.Fragment>
                            {t('download')}
                            <DownloadIcon
                                size='large'
                                className='sosafe-DownloadCard-modalDownloadIcon'
                            />
                            {t('downloads:download')}
                        </React.Fragment>
                    </Modal.ConfirmButton>
                    add buttons here?
                </Modal.Footer>
            </Modal>
        </div>
  );
}
