import { Card } from "@sosafe-platform-engineering/fe-lib-ui-react";
import React, { CSSProperties, useMemo, useRef, useState } from "react";
import {
  arrowStyle,
  calculateTogglePanelPosition,
  defaultDimension,
  defaultStyle,
} from "./helper";

interface PanelToggleProps {
  showContent: boolean;
  content: React.ReactElement;
  style?: CSSProperties;
  children: React.ReactNode;
}

/**
 * Panel Toggle renders the content inside a box with a up arrow and export the
 * ability to turn on and out from a outside component
 */
export const PanelToggle: React.FunctionComponent<
  PanelToggleProps & JSX.Element
> = ({ children, content, showContent, style, ...rest }) => {
  const [contentDev, setContentDev] = useState<HTMLElement | null>(null);
  const childrenRef = useRef<HTMLDivElement>(null);

  const position = useMemo(() => {
    if (contentDev && childrenRef.current && showContent) {
      return calculateTogglePanelPosition(
        {
          width: childrenRef.current.clientWidth,
          height: childrenRef.current.clientHeight,
        },
        { width: contentDev.clientWidth, height: contentDev.clientHeight }
      );
    }
    return defaultDimension;
  }, [contentDev, childrenRef.current, showContent]);

  return (
    <React.Fragment>
      <div ref={childrenRef}>{children}</div>
      <div
        {...rest}
        style={{
          ...style,
          ...defaultStyle,
          top: position.content.y,
          left: position.content.x,
        }}
      >
        {showContent && (
          <div ref={setContentDev}>
            <div
              style={{
                ...arrowStyle,
                top: position.arrow.y,
                left: position.arrow.x,
              }}
            />
            <Card className="p-3">
              <Card.Body>{content}</Card.Body>
            </Card>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
