import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Checkbox, clsx } from '@sosafe-platform-engineering/fe-lib-ui-react';

import { PolicyType } from 'policies/policies.types';

import { checkIsIE, checkIsSafari } from 'shared/helpers';

import './policy-view.component.css';
import { SubmitPolicyAnswers } from 'shared/modules/sosafe-connect';

interface PolicyViewProps {
  policy: PolicyType;
  policyAnswers: SubmitPolicyAnswers[];
  setPolicyAnswers: React.Dispatch<React.SetStateAction<SubmitPolicyAnswers[]>>;
  className?: string;
}

export default function PolicyView(props: PolicyViewProps): JSX.Element {
  const {
    className, policy, policyAnswers, setPolicyAnswers,
  } = props;

  const [accepted, setAccepted] = useState(false);

  const { t, i18n } = useTranslation();

  const LR = `${i18n.language === 'ar' ? 'right' : 'left'}`;

  useEffect(() => {
    const currentPolicyAnswer = policyAnswers.find(
      (policyAnswer) => policyAnswer.group === policy.group,
    );
    if (currentPolicyAnswer) {
      setAccepted(currentPolicyAnswer.accepted);
    } else {
      setAccepted(false);
      setPolicyAnswers([
        ...policyAnswers,
        {
          touched: true,
          accepted: false,
          group: policy.group,
        },
      ]);
    }
  }, [policy]);

  const toggleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.currentTarget;
    setAccepted(checked);
    const newPolicyAnswers = policyAnswers.map((policyAnswer) => {
      if (policyAnswer.group === policy.group) {
        return {
          ...policyAnswer,
          accepted: checked,
        };
      }
      return policyAnswer;
    });
    setPolicyAnswers(newPolicyAnswers);
  };

  const PolicyComponent = useCallback(() => {
    if (checkIsIE()) {
      return (
                <div className='sosafe-PolicyView-pdfViewerFallbackContainer'>
                    <span className='sosafe-PolicyView-pdfViewerFallbackWarning'>
                        <Trans i18nKey='policy:no_pdf_ie11' />
                    </span>
                    <a
                        href={policy.link}
                        target='_blank'
                        rel='noreferrer'
                        className='sosafe-Button is-outline'
                    >
                        <Trans i18nKey='translations:Download now' />
                    </a>
                </div>
      );
    }

    if (checkIsSafari()) {
      return (
                <iframe
                    title='policy-view'
                    className='sosafe-PolicyView-pdfViewerObject'
                    src={policy.link}
                />
      );
    }

    return (
      <object
        className='sosafe-PolicyView-pdfViewerObject'
        data={policy.link}
        type='application/pdf'
      />
    );
  }, [policy?.link]);

  return (
        <div className={clsx(`sosafe-PolicyView-pdfWrapper p${LR}`, className)}>
            <h2 className='sosafe-PolicyView-policyName'>{policy.name}</h2>
            <p className='sosafe-PolicyView-policyDescription'>{policy.description}</p>
            <PolicyComponent />
            <Checkbox
                className='sosafe-PolicyView-checkbox'
                label={t('policy:checkbox_label')}
                name={`policy_${policy.group}`}
                required={policy.mandatory}
                value={accepted}
                onChange={toggleCheckbox}
            />
            <small className={clsx({ 'u-invisible': !policy.mandatory }, `text-md-${LR}`)}>
                *<Trans i18nKey='policy:required' />
            </small>
        </div>
  );
}
