import React, { useEffect } from 'react';
import PoliciesPage from "policies/policies.page";
import ContentPage from 'flamingo-e-learning-platform/content/content.page';
import { useSoSafeConnect } from 'shared/modules/sosafe-connect';
import useUser from 'shared/hooks/use-user';
import AccountPage from 'flamingo-e-learning-platform/account/account';
import FaqPage from 'support/pages/faq-page';
import { isUserAllowedOnPL } from 'shared/hooks/use-is-user-allowed-pl';
import useAuthentication from 'authentication/hooks/use-authentication';
import FlamingoHeader from "../../../flamingo-e-learning-platform/header/header";

export const name = "PoliciesRouteController";

export const routes = [
  {
    path: "/policies",
    render: () => <PoliciesPage />,
  },
  {
    path: "/account",
    render: () => <AccountPage />,
  },
  {
    path: "/faq",
    render: () => <FaqPage />,
  },
]

export const defaultRoute = "/policies";

export const Wrapper = ({ children }: { children: JSX.Element }) => {
  const { signOut } = useAuthentication();

  useEffect(() => {
    import("../../../theme/flamingo");
  }, []);

  return <React.Fragment>
    <FlamingoHeader
      handleSignOutClick={signOut}
      minimizedView={true}
    />
    <ContentPage>
      {children}
    </ContentPage>
  </React.Fragment>
}

export const UseIsPathAllowed = () => {
  const { apiKey } = useSoSafeConnect();
  const { user, loading } = useUser({});
  const { isAllowed, isLoading: isLoadingUserAllowed } = isUserAllowedOnPL();
  const isPersonalizedLearning = user?.campaign.isPersonalizedLearning ?? false;

  const showPolicies = !!user?.game?.policies?.length;
  const loadingAuthorization = apiKey ? loading : false;

  return {
    allowed: !!apiKey && showPolicies && isPersonalizedLearning && isAllowed,
    loading: !!apiKey && loadingAuthorization && isLoadingUserAllowed,
  }
}

