import React, { Fragment, useMemo } from "react";
import { createPortal } from "react-dom";

import { URL_RESOURCE_API } from "shared/helpers";
import { UseGlobalSettingsContext } from "shared/modules/global-settings/state/global-settings-context.provider";

const BrandedLinkElement = () => {
  const { globalSettings } = UseGlobalSettingsContext();

  const brandingVersion = useMemo(
    () =>
      globalSettings.branding_version_id
        ? `&brandingToken=${globalSettings.branding_version_id}`
        : "",
    [globalSettings.branding_version_id]
  );

  if (!globalSettings.customerToken) {
    return null;
  }

  return createPortal(
    <Fragment>
      <link
        rel="stylesheet"
        href={`${URL_RESOURCE_API()}/${globalSettings.customerToken
          }/branding.css?area=1${brandingVersion}`}
      />
    </Fragment>,
    document.head
  );
};

export default BrandedLinkElement;
