import { MODULE_STATUS } from "flamingo-e-learning-platform/training/types/module-status.type";
import { PersonalizedModule } from "flamingo-e-learning-platform/training/types/module.type";

const filteredLearningPath = (modules: PersonalizedModule[], limit: number) => {
    const today = new Date();
    const limitedDaysFromNow = new Date(today);
    limitedDaysFromNow.setDate(today.getDate() + limit);
  
    return modules.filter(lesson => {
      if (!lesson.finished_by) return false;
      const finishedByDate = new Date(lesson.finished_by);

      // Check if the lesson is overdue and not completed yet
      const isOverdue = finishedByDate < today && lesson.status !== MODULE_STATUS.RESTART;
      // Include lessons within the specified limit range or overdue lessons
      return (finishedByDate >= today && finishedByDate <= limitedDaysFromNow) || isOverdue;
    });
  };

export default filteredLearningPath;
