import { useSoSafeQuery } from 'shared/hooks/use-sosafe-query';
import { queryKey, useSoSafeConnect } from 'shared/modules/sosafe-connect';
import ActionTypes from 'shared/utilities/action-types';

interface DidYouKnowHintRequestProps {
  /** Set this to false to disable automatic refetching when the query mounts or changes query keys. To refetch the query, use the refetch method returned from the useQuery instance. Defaults to true. */
  enabled: boolean
}

/** Fetches Hints from backend */
export const useDidYouKnowHintRequest = ({ enabled }: DidYouKnowHintRequestProps) => {
  const { endPoints } = useSoSafeConnect();
  return useSoSafeQuery({
    enabled,
    requestMethod: endPoints.fetch.getHints,
    key: queryKey.HINTS,
    requestType: ActionTypes.DIDYOUKNOWHINT_REQUEST,
    successType: ActionTypes.DIDYOUKNOWHINT_SUCCESS,
    failedType: ActionTypes.DIDYOUKNOWHINT_FAILED,
  });
};
