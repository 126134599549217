import React from 'react'; // Ensure React is in scope
import { Button, Title } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";
import { useTranslation } from "react-i18next";
import { URL_RESOURCE_API } from "shared/helpers";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "state/reducers";
import useCertificate from "shared/hooks/use-certificate";
import { ANALYTICS_TYPE } from "shared/modules/sosafe-connect";
import { useSoSafeAnalytics } from "shared/hooks/use-sosafe-analytics";
import ResponsivePicture from "shared/components/responsive-picture";
import { UseShowDecisionAlert } from 'flamingo-e-learning-platform/utilities/modal-alert/use-show-decision-alert';
import { useModalContext } from 'modal-context/modal-context';
import { ALERT_TYPE } from 'flamingo-e-learning-platform/utilities/modal-alert/shared';
import style from '../../../flamingo-e-learning-platform/achievements/components/level-card/level-card.module.css'

export const  CertificateModal = () => {
  const { t } = useTranslation("translations");
  const { t:flamigoTranslation } = useTranslation("flamingo");
  const { loading, download, enabled: certificateEnabled} = useCertificate();
  const { sendSoSafeAnalytics } = useSoSafeAnalytics();
  const { user } = useSelector((state: RootState) => state.auth, shallowEqual);
  const customerToken = user && user?.customer ? user?.customer.token : "";
  const { show } = UseShowDecisionAlert()
  const { close } = useModalContext();

  const certDownload = () => {
    download();
    sendSoSafeAnalytics({ type: ANALYTICS_TYPE.CERT_DOWNLOADED });
    close();
  };

  const showCertification = () => {
    show({
      title: <Title size="h6">{t('Here you can download your highly deserved certificate!')}</Title>,
      content: (
        <div className={style.body}>
          <ResponsivePicture
            className="z-index-1 certmodal-badge-image"
            src={`${URL_RESOURCE_API()}/${customerToken}/img/assets/certificate.png`}
            alt={t("certificate_image")}
            fit="contain"
            defaultHeight={400}
          />
        </div>
      ),
      footer: (
        <Button loading={loading} aria-label={loading ? t('Loading ...') : t('Download now')} onClick={certDownload}>
          {loading ? t('Loading ...') : t('Download now')}
        </Button>
      ),
      type: ALERT_TYPE.NONE
    });
  };

  return (
    <Button
      variant="outline"
      data-testid="certificate-button"
      disabled={!certificateEnabled}
      size="large"
      aria-label={flamigoTranslation("certificate_issue")}
      onClick={showCertification}
      styles={{
        label: { whiteSpace: 'normal'},
      }}
    >
      {flamigoTranslation("certificate_issue")}
    </Button>
  );
}
