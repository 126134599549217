/**
 * Gets Category from array with key or id
 *
 * @param {array} categories array of categories
 * @param {string} categoryKey Category name or id
 * @returns {object} returns category or null
 *
 * @category elearning
 * @subcategory helpers
 * @exports getCategoryByCategoryKey
 */
export default function getCategoryByCategoryKey(categories, categoryKey, userLanguage) {
  if (!categories?.length || !categoryKey || !userLanguage) return undefined;
  // Find a category by category_name and language and user language because there
  // are cases where we have categories with the same name but different languages,
  // in this case, we give priority to the category of the same language of the user
  const result =
    categories.find(
      (category) => category.category_name === categoryKey && category.language === userLanguage
    ) ||
    // In case no category was found by name within the same language as the user,
    // we find the category with the fallback language ("en"),
    // fallback language is defined by the backend but not passed to the frontend, that's why is hardcoded.
    categories.find(
      (category) => category.category_name === categoryKey && category.language === "en"
    ) ||
    categories.find(
      (category) => category.id === category.categoryKey && category.language === userLanguage
    );
  return result;
}
