/**
 * The key types used for caching http response
 */
export enum queryKey {
  AUTH = "AUTH",
  NOTIFICATIONS = "NOTIFICATIONS",
  HINTS = "HINTS",
  ELEARNING = "ELEARNING",
  ACTIVATE_ACCOUNT = "ACTIVATE_ACCOUNT",
  REGISTER_USER = "REGISTER_USER",
  BADGES = "BADGES",
  ACTIVITIES = "ACTIVITIES",
  SURVEY = "SURVEY",
  MODULE_FEEDBACK = "MODULE_FEEDBACK",
  UPDATE_USER_DATA = "UPDATE_USER_DATA",
  SURVEY_HUB = "SURVEY_HUB",
  SURVEY_HUB_RESULT = "SURVEY_HUB_RESULT",
  SURVEY_HUB_SURVEY_ACTIVE = "SURVEY_HUB_SURVEY_ACTIVE",
  LEARNING_PATH_RECALCULATION_NEEDED = "LEARNING_PATH_RECALCULATION_NEEDED",
  LEARNING_PATH_RECALCULATION_TRIGGERED = "LEARNING_PATH_RECALCULATION_TRIGGERED",
  IS_USER_READY_TO_USE_PL = "IS_USER_READY_TO_USE_PL",
}
