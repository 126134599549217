/** get a parameter from url by name or return all the parameter if parameterName not passed
 * example:
 * http://example.domain/?productName=elearning
 * using parameter name equal to parameterName will return elearning
*/
const getUrlParameter = (url: string, paramName: string) => {
  if (!url || !paramName) return null;

  const paramString = url.substring(1);
  const paramsArray = paramString.split('&');
  const paramsMap: Record<string, string> = paramsArray.reduce((prev, curr) => {
    const splitter = curr.split('=');
    return {
      ...prev,
      [splitter[0]]: splitter[1],
    };
  }, {});
  return paramsMap[paramName];
};

export default getUrlParameter;
