import React from 'react';
import { ConfiguringSVG } from "shared/svgs";
import { useTranslation } from 'react-i18next';
import Helmet from 'shared/components/helmet';
import SurveyHubTemplate from './components/survey-hub.template';
import SurveyWelcome from './components/survey/survey-welcome';
import CultureSurveyCompleted from './components/survey/culture-survey-completed';
import { SURVEY_TYPE } from './constants';

const CultureSurveyWelcome = ({ onStart }: { onStart: () => void }) => {
  const { t } = useTranslation("survey_hub");

  return (
    <SurveyWelcome
      onStart={onStart}
      title={t("welcome_title")}
      description={t("welcome_description")}
      SVG={ConfiguringSVG}
      startButtonText={t("start")}
    />
  )
}

/**
 * HOC component which wraps a Culture Survey
 * @returns
 */
const CultureSurveyHubPage = () => {
  const { t } = useTranslation("helmetTranslations");

  return <React.Fragment>
    <main>
      <Helmet title={t("survey")} />
      <SurveyHubTemplate
        WelcomeComponent={CultureSurveyWelcome}
        surveyType={SURVEY_TYPE.CULTURE_AND_CONTEXT}
        CompletedComponent={CultureSurveyCompleted}
      />
    </main>
  </React.Fragment>

}

export default CultureSurveyHubPage;


