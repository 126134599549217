import { AuthContextProps, useAuthContext } from "auth-context/auth-context";
import React, { ReactElement } from "react";

interface Props {
  Component: (methods: AuthContextProps) => ReactElement
}

export const RouterWithAuthorizationMethod = ({ Component }: Props) => {
  const props = useAuthContext();
  return <Component {...props} />;
}
