import { AxiosResponse } from 'axios';
import { LOGIN_METHOD } from 'types';

export const addMethodToResponse = (response: AxiosResponse<any>, method: LOGIN_METHOD | undefined) => {
  const { data } = response;
  return {
    ...data,
    result: {
      ...data.result,
      method,
    },
  };
};
