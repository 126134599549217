import React from "react";
import { Button } from "@sosafe-platform-engineering/fe-lib-ui-react";
import { queryClient, queryKey } from "shared/modules/sosafe-connect";
import { FEATURE_TYPE, SURVEY_TYPE } from "survey-hub/constants";
import { useTranslation } from "react-i18next";
import styles from "./hrs-survey-completed.module.css";

export default function HRSSurveyCompleted() {
  const { t } = useTranslation("survey_hub");

  const onGoToTraining = () => {
    queryClient.invalidateQueries([
      queryKey.SURVEY_HUB_RESULT,
      SURVEY_TYPE.HRS_SELF_RATING,
      FEATURE_TYPE.HRS,
    ]);
  };

  return (
    <div className={styles.surveyCompleted}>
      <h2 className={styles.subHeading}>{t("hrs_completed_title")}</h2>
      <p className={styles.text}>{t("hrs_completed_description")}</p>
      <div className={styles.buttonSection}>
        <Button ariaLabel="go to training" onClick={onGoToTraining}>
          {t("hrs_completed_button")}
        </Button>
      </div>
    </div>
  );
}
