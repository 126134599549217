/**
 * Gets Object from array with key and value
 */
const getObjectByKey = <T>(objects: T[], key: string, value: string): T | undefined => {
  if (!objects?.length || !key || !value) return undefined;

  const result = objects.find((object) => {
    switch (typeof object[key]) {
      case 'string':
        return object[key] === value;
      case 'number':
        return object[key] === parseInt(value, 10);
      default:
        return false;
    }
  });
  return result;
};

export default getObjectByKey;
