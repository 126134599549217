import { Checkbox } from '@sosafe-platform-engineering/fe-lib-ui-react';
import React, { useState } from 'react';
import {
  ReactElementFactory,
  ReactSurveyElement,
} from 'survey-react-ui';

export const SOSAFE_CHECKBOX_ITEM = 'sosafe-checkbox-item';

class CheckboxItem extends ReactSurveyElement {

  constructor(props: any) {
    super(props);
    this.state = {
      isChecked: false
    }
  }

  renderElement() {
    const { question, item } = this.props;

    const handleOnChange = (itemSelected: string) => {
      const survey = question.surveyValue;
      // get first index because we don't show more than one question per page.
      const currentQuestion = survey.activePage.questions[0];
      const currentQuestionAnswers = survey.getValue(currentQuestion.name) || [];

      const isSelected = currentQuestionAnswers?.includes(itemSelected);
      this.setState({ isChecked: !isSelected })

      if (!isSelected) {
        survey.setValue(currentQuestion.name, [...currentQuestionAnswers, itemSelected]);
      } else {
        const newAnswer = currentQuestionAnswers.filter((value: string) => value !== itemSelected);
        survey.setValue(currentQuestion.name, newAnswer);
      }
      survey.onValueChanged.callbacks[0]();
    }

    return (
      <Checkbox
        data-testid={`${item.value}-checkbox`}
        label={item.text}
        name={item.value}
        value={this.state.isChecked}
        onChange={() => { handleOnChange(item.value) }}
      />
    );
  }
}

export const registerSoSafeCheckboxItem = () => {
  ReactElementFactory.Instance.registerElement(SOSAFE_CHECKBOX_ITEM, (props: any) => React.createElement(CheckboxItem, props));
}
