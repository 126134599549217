import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import {
  FetchAuthorizationReturnType,
  queryKey,
  useSoSafeConnect,
} from 'shared/modules/sosafe-connect';
import { ReferenceAction } from 'shared/modules/sosafe-connect/handle-reference';
import { STALE_TIME } from 'shared/modules/sosafe-connect/query';
import ActionTypes from 'shared/utilities/action-types';
import { UseReferenceHandler } from 'shared/utilities/use-reference-handler';
import { useClearLoginData } from 'authentication/hooks/use-clear-login-data';

interface useUserProps {
  /** Set this to false to disable automatic refetching when the query mounts or changes query keys. To refetch the query, use the refetch method returned from the useQuery instance. Defaults to true. */
  enabled?: boolean;
  onSuccess?: (props: AxiosResponse<FetchAuthorizationReturnType>) => void;
  onError?: () => void;
  id?: string;
}

/**
 * Calls /Authorization endpoint to fetch user information and update redux state
 * @param param0
 * @returns
 */
export default function useUser({ enabled = true, onSuccess, onError, id }: useUserProps) {
  const { endPoints, axiosInstance, setApiKey, apiKey } = useSoSafeConnect();
  const clearLoginData = useClearLoginData();
  const dispatch = useDispatch();
  const checkReference = UseReferenceHandler();

  const {
    data: result,
    isLoading,
    refetch,
  } = useQuery([queryKey.AUTH], () => endPoints.fetch.authorization({}), {
    enabled: enabled && !!apiKey,
    staleTime: STALE_TIME,
    retry: (failureCount, error) => {
      const localApiKey = localStorage.getItem('apikey');
      if (localApiKey) setApiKey(localApiKey);
      if (
        error?.response?.data?.reference &&
        ReferenceAction.shouldLogout(error.response.data.reference)
      )
        return false;
      return failureCount < 3;
    },
    onSuccess: (response) => {
      if (id) console.warn('success on', id);
      dispatch({
        payload: checkReference(response.data, dispatch),
        type: ActionTypes.AUTH_VALIDATE_SUCCESS,
      });
      onSuccess?.(response);
    },
    onError: (error: any) => {
      axiosInstance.defaults.headers.Authorization = localStorage.getItem('apikey');
      if (
        error?.response?.data?.reference &&
        ReferenceAction.shouldLogout(error.response.data.reference)
      ) {
        clearLoginData(true);
      }

      if (error.response) {
        dispatch({
          payload: checkReference(error.response.data, dispatch),
          type: ActionTypes.AUTH_VALIDATE_FAILED,
        });
      } else {
        dispatch({
          payload: checkReference(
            {
              title: error.name,
              status: error.statusCode,
              reference: '8CCA1873-7195-4EB7-8BF4-32CCF473B353',
            },
            dispatch
          ),
          type: ActionTypes.AUTH_VALIDATE_FAILED,
        });
      }
      onError?.();
    },
  });

  return {
    user: result?.data?.result,
    reference: result?.data?.reference,
    refreshUser: refetch,
    loading: isLoading,
  };
}
