import { useQuery } from "@tanstack/react-query";
import { queryKey, useSoSafeConnect } from "shared/modules/sosafe-connect";
import { SURVEY_TYPE } from "survey-hub/constants";

export interface UseSurveyActiveProps {
  isSurveyEnabled: boolean,
  surveyTypeId: SURVEY_TYPE
}

export const useSurveyActive = ({ isSurveyEnabled, surveyTypeId }: UseSurveyActiveProps) => {
  const { endPoints, apiKey } = useSoSafeConnect();

  return useQuery(
    [queryKey.SURVEY_HUB_SURVEY_ACTIVE, surveyTypeId],
    () => endPoints.surveyHubApi.fetch.getActiveSurvey(surveyTypeId),
    {
      enabled: !!apiKey && isSurveyEnabled,
      retry: false,
      staleTime: Infinity,
    }
  );
}
