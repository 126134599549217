import { AccountChangePasswordProps, AccountProps } from 'account/types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { URL_ACCOUNT_API } from 'shared/helpers';
import { queryClient, queryKey, useSoSafeConnect } from 'shared/modules/sosafe-connect';
import useUser from 'shared/hooks/use-user';
import ActionTypes from 'shared/utilities/action-types';
import { SOSAFE_LANGUAGE_CODE_PARSER } from 'shared/utilities/languages';
import { UseReferenceHandler } from 'shared/utilities/use-reference-handler';

/**
 * Exports functions to edit user account
 */
export const useAccount = () => {
  const { axiosInstance } = useSoSafeConnect();
  const { user } = useUser({});
  const dispatch = useDispatch();
  const checkReference = UseReferenceHandler();

  const save = useCallback(async (data: AccountProps) => {
    const sosafeLanguage = SOSAFE_LANGUAGE_CODE_PARSER.get(data.language);
    try {
      const response = await axiosInstance.put(URL_ACCOUNT_API(), {
        ...data,
        language: sosafeLanguage ?? data.language
      });
      dispatch({ payload: checkReference(response.data, dispatch), type: ActionTypes.ACCOUNT_UPDATE_SUCCESS });
      queryClient.invalidateQueries([queryKey.AUTH]);
      queryClient.invalidateQueries([queryKey.ELEARNING]);
      queryClient.invalidateQueries([queryKey.HINTS]);
      queryClient.invalidateQueries([queryKey.ACTIVITIES]);
    } catch (error: any) {
      if (error.response) {
        dispatch({ payload: checkReference(error.response.data, dispatch), type: ActionTypes.ACCOUNT_UPDATE_FAILED });
      } else {
        dispatch({ payload: checkReference({ title: error.name, status: error.statusCode, reference: '1E20DCF3-7E73-4389-BCCB-857FE56BF1B6' }, dispatch), type: ActionTypes.ACCOUNT_UPDATE_FAILED });
      }
    }
  }, [user?.firstname, user?.lastname, user?.id]);

  const changePassword = useCallback(async (data: AccountChangePasswordProps) => {
    try {
      dispatch({ type: ActionTypes.PASSWORD_UPDATE_REQUEST });
      const response = await axiosInstance.put(URL_ACCOUNT_API(), data);
      queryClient.invalidateQueries([queryKey.AUTH]);

      dispatch({ payload: checkReference(response.data, dispatch), type: ActionTypes.PASSWORD_UPDATE_SUCCESS });
    } catch (error: any) {
      if (error?.response) {
        dispatch({ payload: checkReference(error.response.data, dispatch), type: ActionTypes.PASSWORD_UPDATE_FAILED });
      } else {
        dispatch({ payload: checkReference({ title: error.name, status: error.statusCode, reference: '1E20DCF3-7E73-4389-BCCB-857FE56BF1B6' }, dispatch), type: ActionTypes.PASSWORD_UPDATE_FAILED });
      }
    }
  }, [user?.firstname, user?.lastname, user?.id]);

  return {
    /** updates user data */
    save,
    /** updates user password */
    changePassword,
  };
};
