import { CSSProperties, ReactElement } from "react";

import {
  IconCircleCheck,
  IconAlertCircle,
  IconCircleX,
  IconInfoCircle,
  IconQuestionMark
} from "@sosafe-platform-engineering/fe-lib-ui-mantine-react/icons";
import { rem } from "@sosafe-platform-engineering/fe-lib-ui-mantine-react";

export enum ALERT_TYPE {
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
  WARNING = "warning",
  QUESTION = "question",
  /**
   * No icon will be displayed
   */
  NONE = "none"
}

const iconProps = {
  style: { width: rem(80), height: rem(80), marginBottom: rem(10) },
  stroke: 1.5,
  id: "modal-alert-icon"
}

export const IconByType = {
  [ALERT_TYPE.SUCCESS]: <IconCircleCheck
    {...iconProps}
    color="var(--mantine-color-green-filled)"
  />,
  [ALERT_TYPE.ERROR]: < IconCircleX
    {...iconProps}
    color="var(--mantine-color-red-filled)"
  />,
  [ALERT_TYPE.INFO]: <IconInfoCircle
    {...iconProps}
    color="var(--mantine-color-blue-filled)"
  />,
  [ALERT_TYPE.WARNING]: <IconAlertCircle
    {...iconProps}
    color="var(--mantine-color-yellow-filled)"
  />,
  [ALERT_TYPE.QUESTION]: <IconQuestionMark
    {...iconProps}
  />,
};

export interface ModalAlertConfig {
  title?: ReactElement;
  content: ReactElement;
  footer: ReactElement;
  type: ALERT_TYPE;
  onClose?: () => Promise<void>;
}
