import { useModalContext } from "modal-context/modal-context";
import { Dispatch } from "react";
import { checkReference } from "../reference-handling";

export const UseReferenceHandler = () => {
  const modal = useModalContext();

  const showPopupNotification = (
    data: unknown,
    dispath?: Dispatch<unknown>) => checkReference(data, dispath, modal)

  return showPopupNotification;
};
